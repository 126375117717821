@import "../../../../../node_modules/blaze/scss/mixins/components.typography";

.c-text {
  font-family: $text-font-family;
  font-weight: $text-font-weight;
  line-height: $text-line-height;
}

.c-text--mono {
  font-family: $text-font-family-mono;
}

.c-text--highlight {
  @include text--highlight;
}

mark.c-text--highlight__light {
  background-color: inherit;
}

.c-text--highlight__light {
  font-weight: bold;
}

.c-text--quiet {
  color: $color-quiet;
}

.c-text--loud {
  font-weight: $text-font-weight-heavy;
}

.c-text--help[title] {
  border-bottom: $help-border-bottom;
  cursor: help;
}

.c-pre {
  margin: 0;
}

.c-code {
  @include code;
}

.c-code--multiline {
  display: block;
  padding: $spacing-small $spacing-medium;
  border-radius: $border-radius;
  white-space: pre;
  overflow-x: auto;
}

.c-kbd {
  @include code($keyboard-color, $keyboard-background-color);
  border-bottom: $keyboard-border;
  border-radius: $keyboard-border-radius;
}

.c-blockquote {
  @include quotation--color;
  display: block;
  margin: $quotation-margin;
  padding: $quotation-padding;
  font-family: $quotation-font-family;
}

.c-blockquote--brand {
  @include quotation--color($quotation-brand-border-color);
}

.c-blockquote--info {
  @include quotation--color($quotation-info-border-color);
}

.c-blockquote--warning {
  @include quotation--color($quotation-warning-border-color);
}

.c-blockquote--success {
  @include quotation--color($quotation-success-border-color);
}

.c-blockquote--error {
  @include quotation--color($quotation-error-border-color);
}

.c-blockquote__body {
  @include paragraph;
  font-size: $quotation-font-size;
}

.c-blockquote__footer {
  @include paragraph;
  color: $quotation-footer-color;
  font-style: $quotation-footer-font-style;
}

.c-paragraph {
  @include paragraph;
}
