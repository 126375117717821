$currentDayBackground: rgba(212, 212, 212, 0.3);
$weekendBackground: darken($color-page, 5%);
$dragOverBackground: darken($currentDayBackground, 5%);
//
.c-calendar-row {
  display: flex;
  &__item {
    flex: 1 0;
    //padding:$spacing-tiny;
    //outline:1px solid $color-default;
    display: flex;
    align-items: stretch;
    justify-content: center;
    &--issue {
      flex: 1;
    }
  }
  &__short-item {
    flex: 1;
    text-align: center;
  }
}

.c-calendar-assign {
  margin-bottom: $spacing-small;
  &__label {
    background: $color-calendar-assign;
    //color:$color-white;
    //background:white;
    padding: $spacing-tiny;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    font-weight: bold;
    &--splitted {
      border: none;
      border-bottom: 1px solid $color-grey-darker;
      background-color: transparent;
      padding-left: $spacing-small;
      font-size: $text-font-size-small;
    }
  }
}

/* The animation code */
@keyframes issue-drag-over {
  from {
    background-color: #f2eeeb;
    opacity: 0;
    transform:translate(0,-$spacing-tiny);
  }
  to {
    background-color: darken($currentDayBackground, 45%);
    opacity: 1;
    transform:translate(0,0);
  }
}
.c-calendar-item {
  //background:white;
  flex: 1 0;
  position: relative;
  height: 100%;
  //overflow: auto;
  //overflow: hidden;  //Fix dropdown (z-index)
  transition: all .3s ease-out;
  &.drag-over{
    background: $dragOverBackground;
  }
  &.issue-drag-over {
    background: $dragOverBackground;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: $spacing-tiny;
      top: 0;
      left: 0;
      right: 0;
      animation-name: issue-drag-over;
      animation-duration: .4s;
      animation-fill-mode:forwards;
      animation-timing-function: ease-out;
    }
  }
}

.c-calendar-quick-entry {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all .3s ease-out;
  &__body {
    text-align: center;
  }
  &:hover {
    opacity: 1;
  }
  &--leave {
    opacity: 1;
    color: $color-red;
    font-size: $text-font-size-xsmall;
  }

}

.c-calendar-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  &__row {
    transition: all .5s ease-out;

  }
  &__item {
    border: 1px solid $border-color;
    border-left: none;
    min-height: $size-xxx-small;
    &--week-end {
      background: $weekendBackground;
    }
    &--today {
      background: rgba(212, 212, 212, 0.3);
    }
    &--empty {
      height: $size-xxx-small;
      &--splitted {
        height: $size-tiny;
      }
    }
    &--leave {
      height: $size-tiny;
      //border-top: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  &--empty {
    .c-calendar-table {
      &__item {

      }
    }
  }
  &--header {
    .c-calendar-table {
      &__item {
        min-height: $size-xxx-small;
        border: none;
        text-align: center;
        padding: $spacing-tiny 0;
        font-size: $text-font-size-medium;
        &.week-end {
          background: $weekendBackground;
        }
        &.today {
          background: $currentDayBackground;
          font-weight: $font-weight-semi-bold;
        }
      }
    }
  }
  .c-calendar-table__row:last-child td {
    border-bottom: none;
  }
  .c-issue {
    max-height: inherit;
  }
}
.c-calendar-table__row:hover .c-issue__state-dropdown {
  z-index: 350;
}

.c-calendar-table__row .c-issue__wrapper:hover .c-issue__state-dropdown {
  z-index: 1000;
}