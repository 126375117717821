.c-app-root{
  @media screen{
    //width:100%;
    //height:100%;
    overflow-y: hidden;
    height: 100vh;
    position: relative;
  }
}
.c-app{
  @media screen{
    display: flex;
    flex-direction: column;
    grid-template-rows: min-content 1fr;
    width:100%;
    height:100%;
  }
  //overflow-y: hidden;
  &__header{
    //grid-column:1;
    //grid-row: 1;
    //border-bottom: 1px solid darken(white,15%);
    border-bottom: #c1c1c1;
    box-shadow: 0px 1px 8px 0px #c4c4c4;
  }
  &__content{
    //grid-column:1;
    //grid-row: 2;

    overflow: hidden;
    @media print {
      overflow: visible;
    }
    position: relative;
    flex:1;
  }
}
.bs-release-next{
  margin: 0px;
  border: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 30px;
  z-index: 600;
  background-color: #fff;
}
.bs-iframe-blank{
  position: absolute;
  height: 0px;
  margin: 0px;
  border: 0px;
}
.bs-release-modal{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.bs-release-modal>iframe{
  background-color: white;
  width: 40vw;
  height: 90vh;
  margin: 0px;
  border: 0px;
}