.c-calendar-tools{
  &__filters{
    flex-shrink: 0;
    //flex-grow: 1;
    flex-basis: auto;
    min-width: 180px;
  }
  &__month{
    width: $size-x-small;
  }
  .c-issues-filter{
    position: relative;
    padding: 0 $spacing-tiny;
    margin:0;
    &__inputs{
      position: absolute;
      top:100%;
      width: $size-x-large;
      //right:-100%;
      left: $spacing-medium;
      z-index: $z-over-content;
      transition: all .3s ease-out;
      padding: $spacing-tiny $spacing-tiny $spacing-small $spacing-tiny;
      opacity: 0;
    }
    &.open{
      background: white;
      box-shadow: $box-shadow-high;
      .c-issues-filter__inputs{
        opacity: 1;
        background: white;
        box-shadow: $box-shadow-high;

      }
    }
  }
}