.panel-heading#map-canvas, #auto-complete-map-canvas {
  height: 410px; border:1px solid #ddd9d7;
}

#map-canvas {
  height: 650px;
}

.details-a #map-canvas {
  height: 200px;
}

.dashboard_item{width:214px;margin: 0 15px 13px 0;background-color: #fff;}.dashboard_item .issue_picture{border:1px solid #ccc;}
.dashboard_item_home{line-height: 13px ;font-size:11px; width:105px; min-height:200px; height:200px !important;margin:1px 20px 10px 0;float:left;padding:0.3em 1em 0;border:1px solid #ccc;-webkit-box-shadow:1px 2px 3px 1px #cccccc;box-shadow:1px 2px 3px 1px #cccccc;}.dashboard_item .issue_picture{border:1px solid #ccc;}
.dashboard_item_home img{margin: 6px 0; max-width:100%; width:100% !important;}
.dashboard_item .attributes {padding: 8px 8px 2px;border:1px solid #ccc;border-top: none;}
.dashboard_item .attributes .issue-details img {width: 100%; vertical-align: top; max-width: 11px; margin-right: 4px;}
.dashboard_item .attributes .location {font-size: 90%;}
.dashboard_item .attributes blockquote {border: none; padding-left: 8px; margin-left: 24px;}
.dashboard_item .attributes hr {margin: 8px 0 4px;}
.dashboard_item:hover {background-color: rgb(249, 249, 249);}

.angular-google-map-container { height: 800px; border:1px solid #ddd9d7;}

.radio-inline {
  display: inline;
  margin-right: 20px;
}

.only-tablet {
  display: none;
}

.tablet-and-mobile {
  display: none;
}

.tablet-portrait-undisplayed {
  display: none;
}

.radio-inline span {
  margin: 0 4px;
}

.panel {
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-heading {
  padding: 10px 15px;
  margin: -15px -15px 15px;
  font-size: 17.5px;
  font-weight: 500;      
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
}

.panel-heading h3 {
    font-size: 16px;
}

.panel-heading h3 .button-a {
  margin-top: -4px;
}

.panel-heading .table-search, .panel-heading .add-on {
  margin: -4px 18px 0 0;
  min-height: 27px;
  height: 27px;
}

.panel-heading .add-on {
  margin-right: -3px;
  padding: 3px 8px;
}

.input-prepend input {
  border-radius: 0;
}

.panel-footer {
  padding: 10px 15px;
  margin: 15px -15px -15px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
}

.panel-primary {
  border-color: #428bca;
}

.panel.table {
  padding: 0px;
}

.panel.table .panel-heading {
  margin: 0;
}

.panel-primary .panel-heading {
  color: #ffffff;
  background-color: #428bca;
  border-color: #428bca;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success .panel-heading {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-warning {
  border-color: #fbeed5;
}

.panel-warning .panel-heading {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #fbeed5;
}

.panel-danger {
  border-color: #eed3d7;
}

.panel-danger .panel-heading {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info .panel-heading {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.error {
	color: red;
}

.email-textarea {
	resize: none;
	height: 120px;
	width: 90%;
}

.only-mobile {
    display: none !important;
}

.only-inline-mobile {
  display: none !important;
}

.block-status-title {
  padding: 0 8px;
  border-bottom: 1px solid #ddd9d7;
}

.block-status-title h4 {
  color: #5e676c;
  font-size: 1.4em;
}

.block-status-title h4 a {
  margin-right: 5px;
  font-size: 12px;
}

/* .modal {
   position: absolute;
	background-color: #f9f7f5;
} */

.modal-header {
	border-bottom: 1px solid #cccccc;
}

.modal-body {
	display: block;
	max-height: 78%;
}
.c-pictures-modal img {
	/*background-color: white;*/
	padding: 4px;
	border: 1px solid #e5e5e5;
}
.modal-footer {
	border-top: 1px solid #cccccc;
  padding: 12px 15px 8px;
}

.modal.fade.in {
	top: 2%;
}

.email-preview-header {
	background: #fbfbfb;
}

.ui-autocomplete {
  max-height: 100px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  background: white;
  position: absolute;
  width: 200px;
  border: 1px solid #efefef;
  padding: 4px;
}

.ui-helper-hidden-accessible {
	display: none;
}

.filters-inputs-date {
	width: 100%;
	margin: auto;
}

.input-append input {
	width: 95%;
}


.filters-action.datepicker span {
	width:10%;
}

input.ico.calendar,
input[type=text].ico.calendar,
input[type=password].ico.calendar,
input[type=email].ico.calendar { background-image: url(../img/ico-calendar-b.png); background-position: 10px 8px; background-repeat: no-repeat; }

input[type=text].ico.home,
input[type=password].ico.home,
input[type=email].ico.home { background-image: url(../img/ico-home-a.png); background-position: 10px 8px; background-repeat: no-repeat; }

.error {
	font-size: 12px;
	font-style: italic;
	padding: 0;
	margin: 0;
	line-height: 16px;
  margin-top: 2px;
  font-weight: normal;
}

.log-to-print {
	display: none;
}


.requestor-to-print{
	margin-top: 14px;
  display: none;
}

.issue-details-date-to-print {
	display: none;
	margin: 12px 0;
}


.alert-no-picture {
	margin-top: 14px;
	display: none;
	font-size:10px;
	width: 100%;
}

.show-for-print {
	display: none;
}

.loader-button {
	width: 50px;
	background-repeat: no-repeat;
	background-position:center;
	cursor: default;
}

#loader {
	margin-top: 30px;
	text-align: center;
}


#receivers li {
	/*display: inline;*/
	padding: 0 6px;
}

#receivers ul li {
	width: 30%;
}

#receivers li a {
	cursor: pointer;
	color: #5e676c;
	font-size: 12px;
}

.ajax-validation {
	display: none;
	position: relative;
	left: 70%;
	top: 5px;
	font-size: 16px;
	color: #30C416;
	z-index: 10000;
}

.ajax-validation-absolute {
	display: none;
	position: absolute;
	left: 70%;
	top: 5px;
	font-size: 16px;
	color: #30C416;
	z-index: 10000;
}

.ajax-validation-select {
	display: none;
	/*position: relative;*/
	left: 60%;
	top: 5px;
	font-size: 16px;
	color: #30C416;
	z-index: 10000;
}

.action-validate {
  display: none;
  left: 60%;
  top: 5px;
  font-size: 16px;
  color: #30C416;
  z-index: 10000;
}

.action-validate.bottom-right {
  position: absolute;
  left: 70px;
  top: 20px;
}

.ajax-validation-inline {
	display: none;
	color: #30C416;
}

#issueMapModal {
  width: 900px;
  left: 40%;
}

.description-message {
  float: left; margin-top: 5px; width: 84%; font-size: 12px; overflow: hidden;
}

.order-message {
  float: left; margin-top: 5px; width: 84%; font-size: 12px; overflow: hidden;
}


.issue-filter-block {
	position: relative;
	margin-bottom: 10px;
	border: 1px solid #ddd9d7;
}

.issue-filter-block .description > .row-fluid:hover {
	cursor: pointer;
	background-color: #f9f7f5;
}

.issue-filter-block .description.to-print > .row-fluid:hover {
	cursor: default;
	background-color: #fff;
}

.issue-filter-block .content {
	border-left: 10px solid gray;
	margin-bottom: 0 !important;
}

.issue-filter-block.small .content {
  border-left: 5px solid gray;
  margin-bottom: 0 !important;
}

.issue-filter-block.calendar .content {
  border-left: 5px solid gray;
  margin-bottom: 0 !important;
}

.print-calendar-issues-link {
  cursor: pointer;
}

.calendar .main .edit-order {
  position: absolute;
  bottom: 0;
  padding: 5px 8px;
  right: 0;
  width: 24px;
  height: 24px;
}

.calendar .calendar-issue-order textarea {
  width: 100%;
  resize: none;
  height: 200px;
}

.calendar .calendar-issue-order h3 {
  margin: 0;
}

.calendar .calendar-issue-order i.icon-remove {
  font-size: 14px;
  color: #898989;
}

.calendar .calendar-issue-order .button-a {
  margin-top: 6px;
}

.issue-filter-block.calendar-small .content {
  border-left: 5px solid gray;
  margin-bottom: 0 !important;
}


.issue-filter-block .list-infos {
	text-align: right;
}

.issue-filter-block .details .expired {
  color: red;
}

@media only screen and (min-width: 760px) {
	.issue-filter-block .list-infos ul {
		padding: 35px 10px 0;
	}

	.issue-filter-block .list-infos li {
		height: 1.5em;
		margin: 4px 0;
	}

}

/* IE HACK */
.issue-filter-block .list-infos li {
	height: 1.5em\9;
	margin: 4px 0\9;
}

.issue-filter-block .list-infos ul {
	padding: 35px 10px 0\9;
}

/*IE*/

.issue-filter-block address {
	font-size: 12px;
	margin: 0;
}

.issue-filter-block h3 {
	margin: 10px 0 2px 0;
}

.issue-filter-block .comment {
	min-height: 3.2em;
	margin: 5px 0;
	font-style: italic;
	color: black;
  overflow: hidden;
}

.issue-filter-block.small {
  margin-bottom: 4px;
}

.issue-filter-block.small .comment {
  margin: 2px 0 0 0;
}

.issue-filter-block.small .picture {
  width: 70px;
}
.issue-filter-block.small h3 {
  font-size: 14px;
  margin: 6px 0 2px 0;
}

.issue-filter-block.small .comment {
  min-height: 0.2em;
}


.issue-filter-block.small .description {
  position: relative;
}
/*
.issue-filter-block.small.over-map .description, .issue-filter-block.small.over-map .description > .row-fluid {
  background-color: rgba(255, 255, 255, 0.6)
}

.issue-filter-block.small.over-map .content > .row-fluid {
background-color: rgba(255, 255, 255, 0.6);
}
*/
.issue-filter-block.small .dates  {
  position: absolute;
  top: 6px;
  right: 6px;
  text-align: right;
  font-size: 10px;
}

.issue-filter-block.small .details {
  margin-left: 4px;
  padding: 0 4px;
}

.issue-filter-block.small .list-infos ul {
  padding: 0;
  margin-bottom: 0;
  margin-top: 2px;
  margin-right: 4px;
}

.issue-filter-block.small .list-infos ul.inline {
  margin-top: 2px;
  margin-bottom: 6px;
}

.issue-filter-block.small .list-infos ul li.tag-bg {
  color: white;
  line-height: 16px;
  font-weight: bold;
}

.issue-filter-block.small .list-infos ul.list-issue-infos li {
  line-height: 24px;
}

.issue-filter-block .list-img-preview-content img {
	vertical-align: bottom;
}


.issue-filter-block .label-a {
	position: absolute;
	top: 0;
	right: -12px;
	margin: 10px 0 0 0;
}

.issue-filter-block.small .list-infos li {
    height: 1.3em;
    margin: 2px 0;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
}

.issue-filter-block.open .content {
	border-color: #f77448;
}

.issue-filter-block.transferred .content {
  border-color: #e78fa5;
}


.issue-filter-block.planned .content {
	border-color: #5dc9e6;
}

.issue-filter-block.resolved .content {
	border-color: #89cc1f;
}

.issue-filter-block.deleted .content {
	border-color: #a09696;
}

.issue-filter-block.in_progress .content {
	border-color: #6b98b5;
}

.issue-filter-block.planned .content {
	border-color: #5dc9e6;
}

.issue-filter-block.rejected .content {
	border-color: #aaaa5a;
}

.issue-filter-block .list-issue-infos {
	text-align: right;
}

.issue-filter-block .dates {
	margin: 2px 0 0;
}

.issue-filter-block.small .dates {
	margin: 0;
}

.issue-filter-block .dates img {
	padding-right: 4px;
}

.issue-filter-block .description {
	border-right: 1px solid #ddd9d7;
}

.issue-filter-block .details {
	padding: 0 10px;
}

.issue-filter-block .address, .issue-preview address {
	color: rgb(112, 145, 112);
}

.issue-preview address {
  font-size: 12px;
  margin-bottom: 4px;
}

.issue-preview-calendar, .issue-popover {
  font-size: 12px;
}

.popover-content .issue-description {
  display: block;
  overflow: hidden;
}

.issue-preview-calendar h4, .issue-popover h4 {
  font-size: 14px;
  margin: 8px 0;
}

.issue-preview-calendar .section-title, .issue-popover .section-title {
  font-weight: 600;
}

.issue-preview-calendar hr, .issue-popover hr {
  margin: 12px 0;
}

.issue-popover .owner-deadline {
  margin-bottom: 8px !important;
}

.issue-popover .label-a{
    position: absolute;
    right: -11px;
    top: 41px;
}

.issue-popover {
  overflow: hidden;
}

.popover {
  min-width: 300px !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  padding: 0;
  min-height: 90px;
}

.popover.middle {
  min-width: 600px !important;
  max-width: 100%;
}

.popover h3 {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.popover .popover-content {
  min-width: 300px !important;
}

.popover .popover-title .well {
  padding: 2px 4px;
  float: right;
  margin-top: -3px;
  color: white;
}

.issue-preview p {
  font-size: 12px;
}

.issue-filter-block .content > .row-fluid {
	background-color: #efefef;
  cursor: pointer;
}

.issue-filter-block .content > .row-fluid:hover {
  background-color: rgb(232, 232, 232);
}

.issue-filter-block .description > .row-fluid {
	background-color: #fff;
}

.issue-filter-block .country img {
    width: 14px;
    height: 14px;
    margin-top: -4px;
}

.issue-filter-block .ressource {
    font-size: 8px;
}

.issue-filter-block .description .active {
  background-color: rgb(249, 247, 245);
}

.issue-filter-block .main.active {
  background-color: rgb(232, 232, 232);
}

@media only screen and (max-width: 767px) {
  .picture-tools-bar .span4 {
    display: inline-block;
    width: 30%;
  }

  .picture-tools-bar .span8 {
    display: inline-block;
    width: 68%;
  }
    .only-mobile {
        display: block !important;
    }

    .only-inline-mobile {
      display: inline !important;
    }
  .issue-filter-block .list-img-preview-content img {
  	width: 100%;
  	margin: 0 1px 0 0;
  }
  
  .issue-filter-block .list-img-preview-content img.no-picture {
  	width: 20%;
  	margin: 0 1px 0 0;
  }

	.issue-filter-block .list-infos li {
		display: inline;
		padding-right: 8px;
	}

	.issue-filter-block .list-infos ul {
		padding: 0 10px 0;
	}

	.issue-filter-block .row-fluid [class*="span"] {
		margin: 0;
	}

  .issue-filter-block.small .picture, .issue-filter-block.small .details {
    display: inline-block;
  }

  .issue-filter-block.small .dates {
    position: initial;
  }

  .issue-filter-block.small .picture {
    width: 25%;
  }

  .issue-filter-block.small .details {
    width: 75%;
  }

	.issue-filter-block .comment {
		min-height: 0;
	}
	.issue-filter-block .dates {
		margin-bottom: 5px;
	}

	.issue-filter-block .list-infos {
		min-height: 0;
	}

	.issue-filter-block .list-infos ul {
		margin-bottom: 0;
	}

	.issue-filter-block .details h3 {
		font-size: 1.4em;
	}

	.mobile-undisplayed {
  	display: none !important;
  }


  .issues-list-a > header p.reset {
  	width: 5%;
  }

  .span4.block.checklist.issue-type {
  	width: 33%;
  	display: inline-block;
    margin-right: 1px;
  }
  .span4.block.checklist.issue-type.center {
  	width: 33%;
  	display: inline-block;
  	margin: 0 4px;
  }

  .filters-count {
  	font-size: 12px;
  }
  .modal {
  	left: 0%;
  }

  .modal .row-fluid [class*="span"] {
  	margin-bottom: 2px;
  }

  #map-edit-button .row-fluid [class*="span"], .block-status .row-fluid [class*="span"] {
  	margin-bottom: 2px;
  }

  #issueMapModal {
  	left: 0%;
  	width: 100%;
  }

  .description-message {
  	width: 100%
  }
  .bs-slider .naviguate {
    width: 10%;
    display: inline;
  }

  .bs-slider .naviguate a {
    margin-top: 0px !important;
  }

  .bs-slider-mobile {
    height: 100% !important;
  }

  .bs-slider .bs-slider-viewer {
    width: 100%;
  }

  .modal {
    width: 100% !important;
  }
}

@media only screen and (min-width: 468px) and (max-width: 767px) {
	.issue-filter-block .details h3 {
		font-size: 1.4em;
	}
	.issue-filter-block .picture {
		width: 25%;
		display: inline-block;
		vertical-align: top;
	}

	.issue-filter-block .details {
		width: 75%;
		display: inline-block;
		vertical-align: top;
	}

	.issue-filter-block .list-img-preview-content img.no-picture {
  	width: 100%;
  	margin: 0 1px 0 0;
  }

  .tablet-portrait-displayed {
    display: inherit !important;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.issue-filter-block .picture {
		width: 31%;
	}

	.issue-filter-block .details {
		width: 66%;
	}

	.tablet-undisplayed {
		display: none !important;
	}

  .only-tablet {
    display: inherit;
  }

  #nav ul li a .ico {
    margin-left: 0 !important;
  }

}

@media only screen  and (max-width: 1024px) {
  .tablet-and-mobile {
    display: inherit;
  }
}


.issue-log .date, .recurrence-preview .date {
	text-align: center;
	padding: 2px;
	font-weight: bold;
	font-size: 12px;  
  width: 39px;
  margin-left: 4px;
	content: "";
	float: left;
	background-color: #dad5d4;
	color: white;
	margin-right: 8px;
}


.issue-log .date span {
	height: 8px;
}

.issue-log .date .hours {
	background-color: white;
	margin: 2px 0 0 0;
	font-size: 8px;
	font-weight: normal;
	color: #666;
}

.details-a .activity .item p.month {
	font-size: 10px;
	line-height: 6px;
}


.issue-log h4 a {
	font-weight: normal;
}

.issue-log h4 a i {
	font-size: 10px;
}

.issue-log .no-state {
	color: rgb(116, 116, 116);
}

.details-a .activity .item p.day {
	font-size: 12px;
	line-height: 14px;
  margin-bottom: 0;
}


.issue-log .date.open, .tag-bg.open {
	background-color: #f77448;
}

.issue-log .date.planned, .tag-bg.planned {
	background-color: #5dc9e6;
}

.issue-log .date.resolved, .tag-bg.resolved {
	background-color: #89cc1f;
}

.issue-log .date.transferred, .tag-bg.transferred {
  background-color: #e78fa5;
}

.issue-log .date.deleted, .tag-bg.deleted {
	background-color: #a09696;
}

.issue-log .date.in_progress, .tag-bg.in_progress {
	background-color: #6b98b5;
}

.issue-log .date.planned, .tag-bg.planned {
	background-color: #5dc9e6;
}

.issue-log .date.rejected, .tag-bg.rejected {
	background-color: #aaaa5a;
}

.details-a .activity .item h4.sub-title {
	margin-top: 8px;
}

/*#save-filters-avert, #load-filters {
	display: inline-block; color: #5e676c; border-left: 1px solid #e9e8e6; border-right: 1px solid #e9e8e6; padding: 0 14px;
}*/

#load-filters {
	border: none;
}

#save-filter-ok {
	margin-left: 4px; display: none;
}

#refresh-filters {
	line-height: 40px; padding: 0 14px; cursor: pointer; color: #bbb; border-left: none;
}

#refresh-filters:hover {
	color: #5e666c;
}
/*
#filter-reset {
	border-right: 1px solid #e9e8e6;
	color: #5e676c;
}*/


/*
#save-filters-avert:hover, #refresh-filters:hover, #print-issues-link:hover, #filter-reset:hover, #load-filters:hover {
	background-color: rgb(240, 240, 240);
}

*/

.chart {
}

/*CSS loader*/

.css-loader-info {
    font-weight: bold;
    color: #FD881B;
}
.css-loader, .geolocLoader {
   height:32px;
   width:32px;
   margin:0 auto;
   position:relative;
   -webkit-animation: rotation 1.9s infinite linear;
   -moz-animation: rotation 1.9s infinite linear;
   -o-animation: rotation 1.9s infinite linear;
   animation: rotation 1.9s infinite linear;
   border:6px solid rgba(239, 143, 0, 0.15);
   border-radius:100%;
}


.css-loader.blue {
   height:32px;
   width:32px;
   margin:0 auto;
   position:relative;
   -webkit-animation: rotation 1.9s infinite linear;
   -moz-animation: rotation 1.9s infinite linear;
   -o-animation: rotation 1.9s infinite linear;
   animation: rotation 1.9s infinite linear;
        border: 6px solid rgba(59,89,152, 0.15);
   border-radius:100%;
}


.css-loader:before, .geolocLoader:before {
   content:"";
   display:block;
   position:absolute;
   left:-6px;
   top:-6px;
   height:100%;
   width:100%;
   border-top:6px solid rgba(239, 67, 0, 0.8);
   border-left:6px solid transparent;
   border-bottom:6px solid transparent;
   border-right:6px solid transparent;
   border-radius:100%;
}

.css-loader.blue:before {
    content:"";
    display:block;
    position:absolute;
    left:-6px;
    top:-6px;
    height:100%;
    width:100%;
    border: 6px solid rgba(59,89,152, 0.8);
    border-left:6px solid transparent;
    border-bottom:6px solid transparent;
    border-right:6px solid transparent;
    border-radius:100%;
}



@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}


#comments {
    position: relative;
}


#comments .head {
    border-bottom: none;
    margin-bottom: 12px;
}

#comments .head h3 {
    padding-left: 12px;
    padding-bottom: 0;
}

#comments .comment {
    padding: 10px 2px;
    position: relative;
    background-color: white;
    border: 1px solid #f2eeeb;
    margin: 12px 0;
}

#comments .comment .icon {
    text-align: center;
    margin-top: 2px;
    margin-right: 4px;
    margin-left: 4px;
}

#comments .comment .icon i {
    text-align: center;
    background-color: white;
    font-size: 20px;
}

#comments .comment .message p {
    width: 100%; color: #5e676c; padding: 0; margin: 0;
}


#comments .comment .message textarea {
    width: 100%;
    resize:none;
    height: 180px;
    margin-bottom: 8px;
}

#comments .add-comment {
    margin: 12px 0;
}

#comments .add-comment button {
  cursor: pointer;
  /*background-color: #B5B5B5;*/
}

#comments .add-comment button:hover {
    /*background-color: #948989;*/
}

#comments .new-comment {
    margin: 12px 0;
}

#comments #new-comment {
    resize: none;
    height: 180px;
    width: 100%;
}

#add-issue-form .info, #building-form .info {
    color: #fa7251;
}

.details-a .block-info .initial-comment {
    border-top: none;
    margin-top: -20px;
}

#contact-requestor-block {
    margin-top: 8px;
}
#contact-requestor-block button {
  cursor: pointer;
    /*background-color: #B5B5B5;*/
}

#contact-requestor-block button:hover {
    /*background-color: #948989;*/
}

.initial-comment blockquote, #comments blockquote {
    font-size: 12px;
    font-style: italic;
    padding-right: 20px;
    margin-bottom: 14px;
    margin-top: 4px;
    border-left: 3px solid #eee;
}

@media only screen and (max-width: 1024px) {
    .label-to-edit {
      width: 100% !important;
    }
    .initial-comment .message.comment {
        margin-bottom: 0;
    }

    .initial-comment .message.comment p {
        padding: 0;
    }

    #comments .span2.icon {
        width: 20%;
        display: inline-block;
        margin: 0;
    }

    #comments .message {
        width: 77%;
        display: inline-flex;
        margin-bottom: 0;
    }

    #comments .comment {
        width: 100%;
    }

    #comment-edit {
        width: 100%;
    }

    #comments-edit textarea {
        height: 80px;
    }

    #comments blockquote {
        width: 100%;
    }

    #comments .comment .message p {
        width: initial;
        float: right;
    }
    #comments .comment .icon {
        margin-right: 0;
    }
}

.admin-nav {
    margin: 0;
}

.admin-nav header {
    margin-bottom: 1px !important;
}

.table {
  margin-bottom: 0;
}

.table th, .table td {
    line-height: 30px;
}

.table th.button {
  text-align: center;
}

#location-hover {
  position: absolute !important;
  top: 20px;
  left: 120px;
  background-color: white !important;
  z-index: 10000;
  width: 40%;
}

label[for=location-hover]
{
    position: absolute !important;
    top: 50px;
    left: 90px;
    z-index: 10000;
    width: 40%;
}

.updated {
  color: #30C416;
}

#requestor-edit .wrap {
  padding: 0 8px;
}

#issue-state-comment-row, .issue-state-public-comment {
  margin: 8px 0;
  border: 1px solid rgb(157, 157, 157);
  padding: 8px 8px;
}

.upload-pictures {
    background-color: #f2eeeb;
    min-height: 72px;
    text-align: center;
}

#form-edit-pictures .preview-pictures {


}

#form-edit-pictures {
  border: none; background-color: inherit; margin: 0;
}

.margin-top-20 {
  margin-top: 20px;
}

.update-picture-actions {
  border: 1px solid #eae9e8;
  border-top: none;
  background: #faf9f8;
  text-align: right;
  margin-top: -21px;
}

.update-picture-actions button {
  margin-top: 4px;
  margin-right: 4px;
}

.update-picture-actions button:last-child {
  margin-right: 4px;
}

.label {
  font-weight: normal;
}

.upload-pictures .label {
  margin-top: 30px;
}

.upload-pictures .viewer {
  text-align: left;
  padding: 4px;
}
.upload-pictures .viewer img {
  width: 100%;
}

.addissue-a .block-issue .wrap li.image-upload-preview {
  position: relative;
  padding: 4px;
  margin: 0;
  margin-top: 4px;
  width: 25%;
}

.remove-upload-picture {
  font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -11px;
  top: -11px;
  font-size: 19px;
  color: rgb(224, 105, 53);
}

.button-a.add-issue-pictures, .photo-a .form-edit-pictures-cancel {
  position: absolute;
  right: 4px;
  bottom: 4px;
  font-size: 28px;
  padding: 8px;
  height: 40px;
  background-color: #B5B5B5;
}


.issue-pictures img {
    margin: 0 2px 1px 0 !important;
    cursor: pointer;
    display: inline-block !important;
}

.pdf-pictures img {
    width: 32% !important;
    margin: 0 2px 2px 0 !important;
    cursor: pointer;
    display: inline-block !important;
}

#filePhoto{
    position:absolute;
    width:300px;
    height:400px;
    top:-50px;
    left:0;
    z-index:2;
    opacity:0;
    cursor:pointer;
}

.filter-a .filter-field {
  cursor: pointer;
}

.filter-a .mono-filters, .filter-a .sub-filters {
  position: relative;
}



.filter-a .checklist .head.clickable:hover {
  background-color: rgb(250, 250, 250);
}

.filter-a .fields.sub-title {
  background-color: rgb(244, 244, 244);
}

.filter-a .sub-title label {
  font-weight: bold !important;
  cursor: default;
}

.filter-a .filter-field:hover {
  background-color: #EDEAE7;
}

.filter-a .block.checklist.issue-type {
  text-align: center; font-size: 12px;
}

.filter-select-all a {
  margin-left: 4px;
}

.clickable {
  cursor: pointer;
}

.photo-a .issue-cover {
  cursor: -Moz-Zoom-In;
}

.filter-a .checklist .fields p {
  padding: 6px 15px !important;
}

.stronger {
  font-weight: bold !important;
}

#print-export-menu {
  min-width: 30px; border-left: 1px solid #e9e8e6; border-right: 1px solid #e9e8e6;
}

.filtres-result-info .count {
  font-size: 14px;
}

.filtres-result-info .count.list {
  min-width: 90px;
  display: inline-block;
}

.filtres-result-info.calendar-count {
  font-size: 10px;
  line-height: 10px;
}

.filters-boxed {
    padding: 2px;
    background: rgb(160, 150, 150);
}

.checklist.empty {
  border: none !important;
  background: inherit !important; 
}

.filtres-result-info.calendar-count .count {
  font-size: 10px;
  margin-right: 4px;
  line-height: 10px;
}

.ie-input-file {
  padding: 0 4px;
  line-height: 10px;
  height: 50px;
}

.issue-filter-block.small.over-map {
  position: absolute;
  top: 50px;
  width: 99%;
  left: 5px;
}

.display-filters {
  width: 100%;
  margin: 6px 0;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.display-filters .filters-alert {
  position: absolute;
  right: 6px;
  top: 0px;
}

.map-c label.error {
  border: 1px solid #e9e8e6;
  background-color: white;
  padding: 4px 8px;
}

.close-button {
  font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -2px;
  top: -9px;
  font-size: 19px;
  color: rgb(224, 105, 53);
}

.issue-details-dropdown {border: none; padding: 0 20px 0 10px; font-size: 14px;}

.issue-details-dropdown span {font-size: 13px; padding-right: 8px;}

.issue-details-dropdown  i, #print-issue-link i {margin-right: 6px;}

.page-view.span12 {
  margin-left: 0;
}

#issue-description {
  font-size: 12px;
}

#monitor {
  position: fixed;
  bottom: 0;
  left: 0px;
  background-color: white;
  padding: 8px;
  width: 220px;
  border: 1px solid;
}

.widget iframe {
  border: none;
  min-height: 300px;
  border: 1px solid #ddd9d7;
}


.to-edit {
  text-decoration: none;
  font-style: italic;
  cursor: pointer;
  color: #cecece;
  font-size: 12px;
  text-decoration: underline;
}

.no-email {
  background: rgb(219, 219, 219);
  padding: 4px 8px;
  position: absolute;
  right: 13px;
  top: 11px;
  border: 1px solid rgb(211, 202, 202);
}

.no-email label {
  display: inline;
}

.no-email input {
  margin-top: -4px;
  margin-left: 4px;
}

#calendar .separator {
  background-color: rgb(237, 237, 237);
  border-bottom: 1px solid #ddd;
  padding: 0 8px;
}

#calendar .days .week-number {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 14px;
  font-weight: bold;
}

#calendar .day-issues {
  height: 540px;
  overflow-y: scroll;
}

#calendar .week-issues {
  height: 520px;
  overflow-y: scroll;
}

#calendar .days .week-number {
  top: 23px;
}
#calendar .days .week-number.a {
  top: 173px;
}
#calendar .days .week-number.b {
  top: 323px;
}
#calendar .days .week-number.c {
  top: 473px;
}
#calendar .days .week-number.d {
  top: 622px;
}

#calendar .issue-filter-block.calendar, #calendar .blank-issue {
  margin-bottom: 1px;
  height: 63px;
  border-right: none;
  border-left: none;
}

#calendar .issue-filter-block.calendar-small {
  margin-bottom: 1px;
  height: 29px;
  border-right: none;
  border-left: none;
  border: none;
}

#calendar .issue-filter-block.calendar-small.active {
  border: 1px solid #ddd9d7;
  border-right: none;
  border-left: none;
}

#calendar .issue-filter-block.calendar-small h5 {
  margin: 4px 0 0 0;
  line-height: 21px;
  font-size: 1em;
}

#calendar .issue-filter-block.calendar .comment {
  height: 30px;
}

#calendar .issue-filter-block.calendar .details {
  padding-left: 4px;
  padding-right: 4px;
}


#calendar .issue-filter-block .content {
  height: 100%;
}
#calendar .issue-filter-block .content .main {
  height: 100%;
}
#calendar .issue-filter-block .content .main .description {
  height: 100%;
}
#calendar .issue-filter-block .content .main .description .row-fluid{
  height: 100%;
}

#calendar .issue-filter-block .info {
  font-size: 10px; margin-bottom: 0; margin-top: 2px;
}

#calendar .issues-b .issue-filter-block.calendar.empty {
  background-color: transparent;
  border: none;
}

#calendar .issues-b .issue-filter-block.calendar.empty.quick-entry {
  height: 20px;
  border: 1px dashed #ddd;
}

#calendar .issues-b .issue-filter-block.calendar.empty.quick-entry .flex-container {
  cursor: pointer;
}

#calendar .issues-b .issue-filter-block.calendar.empty.quick-entry:hover {
  background: rgba(0, 0, 0, 0.03);
}

#calendar .issues-b .issue-filter-block.calendar.empty.quick-entry.past:hover {
  background: none;
}

#calendar .day-issues .issues-b .issue-filter-block.calendar.empty.quick-entry {
  height: 30px;
  font-size: 16px;
}


#calendar .first-empty {
  border: 1px dashed #ddd;
  height: 64px;
}

#calendar .calendar-month .first-empty {
  height: 27px;
}

#calendar .panel-body .assign-issues .span12 {
  min-height: 0;
}

#calendar .panel-body {
  background-color: rgb(249, 249, 249);
}

#calendar .panel-body .assign-issues {
  padding: 2px;
  height: 20px;
  background-color: rgb(249, 249, 249);
}

#calendar .panel-body .assign-name {
    background: #dcdcdc;
    padding: 2px 4px;
    font-size: 12px;
    white-space: nowrap;
    position: relative;
    font-weight: bold;
}


#calendar .panel-body .assign-name.empty {
  z-index: 0;
}


#calendar .panel-body .assign-name.title {
  z-index: 1;
  overflow-x: visible;
}

#calendar .panel-body .assign-name .print-calendar-issues-link {
  position: absolute;
  right: 3px;
  top: 5px;
}

#calendar .day-issues .assign-name {
  font-size: 16px;
}

#calendar .week-day {
  margin: 0; width: 19.6%; display: inline-block;
}

#calendar .full-week-day {
  margin: 0; width: 14%; display: inline-block;
}

#calendar .month-day {
  margin: 0; width: 14%; display: inline-block;
}

#calendar .month-day .panel-heading i {
  margin-top: 1px;
}

#calendar .week-day h3, #calendar .full-week-day h3, #calendar .month-day h3 {
  font-size: 12px;
}

#calendar h1 {
  font-size: 14px;
}

#calendar h5 {
  margin: 5px 0 0 0;
}

#calendar .panel-heading {
  cursor: pointer;
}

#calendar .issue-filter-block.length-1 {
  width: 203%;
}

#calendar .issue-filter-block.length-2 {
  width: 306%;
}

#calendar .issue-filter-block.length-3 {
  width: 409%;
}

#calendar .issue-filter-block.length-4 {
  width: 512%;
}

#calendar .month-table td {
  width: 3%;
  border: 1px solid;
  text-align: center;
}

#calendar .month-table .issue-filter-block {
  border: none;
}

.filter-a.overlay-filter {
  position: relative;
  z-index: 1;
  /*width: 290px;*/
  background-color: rgb(255, 255, 255);
  padding: 4px 4px;
  border: 1px solid #ddd;
}

#filters-result.issues-b.result.boxed {
  padding: 2px;
  border: 2px solid #A09696;
  overflow-y: hidden;
  overflow-x: hidden;
  /* margin-top: 6px; */
  background-color: rgb(255, 255, 255);
}

#filters-result.issues-b.result.boxed .issue-filter-block.small {
  margin-bottom: 2px;
}

.actionDrag { 
    opacity: 0.7;
    border-color: blue;
}

#calendar .week-day .heading-drop-over .panel-body {
  border: 1px dashed green;
  height: 300px;
}
/*
#calendar .issue-drop-over, #calendar .issue-drag-over {
  border-top: 3px solid green !important;
}
*/
#calendar .heading-drop-over {
  border-bottom: 3px solid green !important;

}

#calendar .issue-filter-block.in-drag {
  border: 1px solid red;
}

.calendar-filters .filter {
  width: 153px;
  display: inline-block;
  width: 80%;
}

.calendar-filters .filter .input-append {
  margin-bottom: 0;
}

.calendar-filters .filter .input-append input {
  width: 88%;
}

.calendar-filters .filter .input-append span.add-on {
  height: 27px;
  line-height: 17px;
}


#filters-result.issues-b.result.calendar-month {
  height: 125px;
  overflow-y: scroll;  background-color: rgb(249, 249, 249);
}

.popover {
  z-index: 1000000000000;
}

.issue-filter-block .main .scheduled, .issue-filter-block.small.scheduled {
  background-color: rgb(190, 224, 194) !important;
}

.issue-filter-block .main .scheduled, .issue-filter-block.small.scheduled .issue-bg {
  background-color: rgb(190, 224, 194) !important;
}

.nears-title {
  font-size: 14px; height: 16px; margin-top: 22px; margin-bottom: 8px;
}

.details-a .activity {
  background-color: #F3F0ED !important;
}

.issue-filter-block .content.invert > .description .row-fluid {
  background-color: #F0F0F0;
}

.details-a .activity.contact-owner {
  background-color: white !important;
}

#calendar .panel-heading {
  padding: 2px 4px;
}

#calendar .panel-heading h3 {
  margin: 2px 0;
}

#calendar .panel-heading h3 small {
  font-size: 11px;
}

#calendar .current-day, #calendar .panel-heading.current-day {
  background-color: rgb(255, 249, 200) !important;
}

#calendar .drag-over .current-day {
  background-color: inherit !important;
}

#calendar .day-label {
  display: inline-block;
  width: 14%;
  font-size: 12px;
  margin-bottom: 4px;
}

#calendar table.issues-table.seven-days td {
  width: 14%;
}

#calendar .day-label.five-days {
  width: 19.6%;
}

#calendar table {
    width: 100%;
    background-color: rgb(249, 249, 249);
}

#calendar table.issues-table {
  margin-bottom: 80px;
}

#calendar .calendar-header {
  position: relative;
  margin-bottom: 4px;
}

#calendar .calendar-header table {
  z-index: 1000;
}

#calendar .calendar-header table.fixed {
  position: fixed;
  top: 45px;
}

#calendar table.week thead tr td {
    width: 19.6%;
    font-size: 14px;
    margin-bottom: 4px;
    background-color: #ffffff;
    padding-left: 6px;
    border: 1px solid #dddddd;
}

#calendar table thead tr td.today {
  background-color: rgb(255, 249, 200) !important;
}

#calendar table.week tr td {
    width: 19.6%;
}

#calendar table.todayweek thead tr td {
    font-size: 14px;
    margin-bottom: 4px;
    background-color: #ffffff;
    padding-left: 2px;
    border: 1px solid #dddddd;
}

#calendar table.day thead tr td {
    width: 19.6%;
    font-size: 14px;
    margin-bottom: 4px;
    background-color: #ffffff;
    padding-left: 2px;
    border: 1px solid #dddddd;
}

#calendar table.seven-days thead tr td {
    width: 14%;
}

#calendar table tbody td {
    max-width: 30px;
}

#calendar table tbody tr td.empty {
    border: none;
    max-width: 30px;
    vertical-align: top;
}

#calendar table tbody tr td.empty .first-empty {
    height: 20px;
    border: 1px dashed #ddd;
    text-align: center;
}

#calendar table tbody tr td.empty .quick-entry {
    height: 63px;
    border: 1px dashed #ddd;
    text-align: center;
    cursor: pointer;
}

#calendar table tbody tr td.empty .quick-entry.small {
    height: 22px;
}

#calendar table tbody tr td.empty .quick-entry a {
  display: inline;
}
#calendar table.week tbody tr.multi-day-row td.empty .quick-entry {
  height: 63px;
}

#calendar table.week thead tr td .button-a.small {
  margin: 2px 2px 0 0;
}


#calendar table .assign-label {
    background: #808080;
    padding: 2px 4px;
    font-size: 14px;
    white-space: nowrap;
    position: relative;
    font-weight: bold;
    color: white;
}

#calendar table tr.assign-label td {
    padding: 2px 0 2px 4px;
    font-size: 12px;
    border: none !important;
}

#calendar table.month tr.assign-label td {
  padding: 0 0 0 4px;
}

#calendar table tr.assign-label td.drag-over {
    border-bottom: 3px solid #66b1c5 !important;
}
/*
#calendar table tbody tr td.empty .quick-entry.drag-over, #calendar table tbody tr td.empty.drag-over {
    box-shadow: inset 0 0 10px #8ca58c;
    border: 3px solid green !important;
    background: rgba(0, 128, 0, 0.1);
}
*/
#calendar table.month td {
    border: 1px solid #ddd9d7 !important;
}

#calendar table.month thead tr td {
    width: 3%;
    font-size: 12px;
    margin-bottom: 4px;
    text-align: center;
}

#calendar table.month tbody tr td {
    width: 3%;
}

#calendar table.month .issue-filter-block.calendar {
  height: inherit;
  border: none;
  margin-bottom: 0;
  border-left: 4px solid;
  padding-left: 0;
  background-color: #fffeea;
  cursor: pointer;
}

#calendar table.month .issue-filter-block.calendar .issue {
  margin-left: 2px;
  line-height: 20px;
}

#calendar table.month .issue-filter-block.calendar .issue {
  margin-left: 2px;
  line-height: 20px;
}


#calendar table.month td.week-end {
  background-color: #d2d0d0;
}

.calendar-week-issue {
    border: 1px solid gainsboro;
    background: white;
    padding-left: 4px;
    font-size: 12px;
    border-left: 5px solid gray;
    height: 20px;
    min-height: 20px;
    cursor: pointer;
}

.calendar-week-issue:hover {
  background-color: rgb(249, 247, 245);
}

.calendar-week-issue .description {
    line-height: 20px;
    height: 20px;
    min-height: 20px;
}

#calendar .week-issue h5 {
    font-size: 12px;
}

#calendar .week-issue.multi-day .actions-state ul.dropdown-menu {
    left: -144px;
    top: 14px;
}

#calendar .week-issue.multi-day h5 {
    margin-top: 2px;
    padding-right: 4px;
}


#calendar .week-issue address {
    font-size: 10px;
}

.calendar-week-issue.open, .month-issue.open {
  border-left-color: #f77448 !important;
}

.calendar-week-issue.transferred, .month-issue.transferred {
  border-left-color: #e78fa5 !important;
}

.calendar-week-issue.planned, .month-issue.planned {
  border-left-color: #5dc9e6 !important;
}

.calendar-week-issue.resolved, .month-issue.resolved {
  border-left-color: #89cc1f !important;
}

.calendar-week-issue.deleted, .month-issue.deleted {
  border-left-color: #a09696 !important;
}

.calendar-week-issue.in_progress, .month-issue.in_progress {
  border-left-color: #6b98b5 !important;
}

.calendar-week-issue.planned {
  border-left-color: #5dc9e6;
}

.calendar-week-issue.rejected {
  border-left-color: #aaaa5a;
}

#calendar .month-view .calendar-small .details {
  padding: 0 2px;
}
 
#calendar .month-view .calendar-small h5 small {
  margin-top: 6px;
}

.calendar-issues .assign {
  margin-bottom: 0;
  margin-top: 2px;
  font-size: 10px;
}


#calendar h3.no-current-month {
  color: rgb(176, 176, 176);
}

#calendar #filters-result.no-current-month {
  background-color: rgb(239, 239, 239);
}

.calendar-tools-spans .calendar-tool {
  display: inline-block;
}

.calendar-tools-spans .assign-filter {
  width: 25%;
}

.calendar-tools-spans .naviguation {
  width: 25%;
}

.calendar-tools-spans .calendars-title {
  width: 20%;
}

.calendars-title h3 {
  margin: 8px 10px 0 -32px;
}

.calendar-tools-spans .views-type {
  width: 30%;
}

.calendar-tools {
  margin-bottom: 20px;
}

.calendar-tools.fixed {
    position: fixed;
    top: 0;
    /* width: 100%; */
    z-index: 10000;
    background: #f2eeeb;
    padding: 8px 0;
}

.details-a > header .utils ul li.danger {
  background-color: rgb(218, 218, 218);
}

.details-a > header .utils ul li.danger a {
  color: white;
}

.filters-button {
  z-index: 10000000000000;
  width: 100%;
  text-align: left !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.filters-button i {
  margin-top: 8px;
}

.text-righted {
  text-align: right;
}

.button-green {
  background-color: #5bb75b !important;
}

.button-black {
  background-color: #777373 !important;
}

.button-calendar {
  height: 20px !important;
  font-size: 10px !important;
  line-height: 20px !important;
}

.calendar-list-filter-title {
  display: inline-block;
  width: 20%;
}

.month-view .row-fluid [class*="span"] {
  min-height: 25px !important;
}

.button-a.filters-button:hover {
  background-color: #A09696 !important;
}

.icon-remove.remove-scheduled-from {
  width: 20px !important; right: 1px !important; top: 8px !important; left: inherit !important; cursor: pointer;
}

.location.warning {
  font-size: 14px;
  line-height: 29px;
  margin-right: 8px;
  color: rgb(211, 137, 2);
}

.calendar-other-update {
  position: absolute;
  width: 100%;
  background-color: rgb(224, 196, 144);
  border: 1px solid rgb(255, 231, 188);
  color: white;
  text-align: right;
  left: 0px;
  padding: 0 4px;
    overflow: hidden;
  height: 20px;
}

.tools-header {
    position: relative;
    z-index: 100;
    min-height: 40px;
    margin: 0 0 15px;
}

.tools-header:before {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -100%;
    right: -100%;
    z-index: -1;
    border-bottom: 1px solid #cecbc8;
    background: #f9f7f5;
    content: ' '
}

.tools-header:after {
    display: block;
    overflow: hidden;
    clear: both;
    content: "";
    width: 100%;
    height: 0;
}

.block-status .content {
  padding: 4px 8px;
}

.address-over-map {
    position: absolute;
    top: 0;
    text-align: right;
    color: rgb(112, 145, 112);
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    padding-right: 8px;
}

.block-status {
  font-size: 12px;
}


/*SWITCH*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #f77448;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f77448;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.r-ss-wrap.r-ss-expanded {
  min-width: 180px;
  z-index: 1000;
}

.bs-selectTenant {
  display: flex;
  align-items: center;
  width: 200px;
}

.bs-selectTenant>div:first-child {
  width: 200px;
}

.bs-selectTenant-custom {
  padding: 12px 0px;
}

@media print{
  .c-app__header,
  .c-issues__main,
  .c-issues__filters{
    display: none;
  }
}