.c-navbar{
  //background:$color-grey-pampas-light;
  //border-bottom: 1px solid $border-color;
  color:$color-default;
  &__wrapper{
    padding: 0 $spacing-medium;
    margin: 0;
    list-style: none;
    text-align: right;
  }
  &__item{
    display: inline-block;
    cursor:pointer;
    padding: $spacing-small;
    padding-bottom: 0;
  }
}