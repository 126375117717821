// TEXT FIELDS
input.c-field {
  display: block;
  width: 100%;
  margin: $field-margin;
  padding: $field-padding;
  border: $field-border;
  border-radius: $field-border-radius;
  outline: 0;
  background-color: $field-background-color;
  font-family: inherit;
  font-size: $field-font-size;
  font-weight: $field-font-weight;
  resize: vertical;
  appearance: none;
  height: auto;
  line-height: normal;
  &:focus {
    //border-color: $field-focus-border-color;
    //box-shadow: $field-focus-box-shadow;
  }
  &.c-field__valorization {
    display: inline-block;
    width: 50px;
    margin: 0;
  }
}

.o-field--icon-right {
  .c-icon {
    right: $spacing-small;
  }

  .c-field {
    padding-right: $field-padding * 4;
  }
}
input.c-valorization-field {
  display: inline-block;
  text-align: center;
  width: 50px;
  margin: 0;
  &__large{
    width: 90px;
  }
}

.ReactTags__suggestions {
  z-index: 1000;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
}