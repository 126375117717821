.bs-select-wa{
  font-size: 16px;
  width: fit-content;
  position: relative;
}
.bs-select-wa-display-default{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid;
  font-size: 1rem;;
  line-height: 1;
  padding: 1px;
}
.bs-select-wa-display-default>div:first-child{
  padding: 1px;
  margin-right: 10px;
}
.bs-select-wa-values-container {
  position: absolute;
  z-index: 1;
  top: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.bs-select-wa-values-container-scrollable{
  height: 190px;
}
.bs-select-wa-values-container-scrollable.bs-select-wa-values-container>div:nth-child(2){
  height: calc(100% - 27px);
}
.bs-select-wa-values-container-scrollable .bs-select-wa-values-content{
  height: 100%;
}
.bs-select-wa-values-content {
  background-color: white;
}
.bs-select-wa-values-content>div{
  height: 100%;
  overflow:hidden;
}

.bs-select-wa-values-content .bs-scrollBar{
  overflow-y: scroll;
}

.bs-select-wa-values-item {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bs-select-wa-values-item:hover {
  cursor: pointer;
  background-color:rgba(82,168,236,0.8);
}

.bs-select-wa-values-container-search{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cecbc8;
}
.bs-select-wa-values-container-search>span{
  margin-right: 3px;
}
.bs-select-wa-values-container-search>input{
  background-color: white;
  border: rgba(0, 0, 0, 0) 0px solid;
  border-radius: 0px;
}
.bs-select-wa-values-container-search>div{
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  background-color: #eee;
}