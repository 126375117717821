.c-dropdown-menu {
  position: relative;
  padding: $spacing-tiny;
  width: 10px;
  height:10px;
  top:-15px;
  &__arrow{
    position: relative;
    display: block;
    @include arrow(top, white, 10px);
    z-index:2;
    left:-15px;
  }
  &__body{
    z-index:1;
    background: #fff;
    position: absolute;
    top:15px;
    right:-0;
    padding: $spacing-tiny;
    min-width: $size-x-small;
    box-shadow: $box-shadow-high;
    text-align: center;
  }
  &__item{
    font-size: $text-font-size-small;
    &:hover{
      font-weight:$font-weight-semi-bold;
    }
  }
}
