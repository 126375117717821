.c-issue-detail-field{
  &__label{
    color: $color-black;
    font-size: $text-font-size-small;
    &-large{
      font-size: $text-font-size-medium;
    }
  }
}

.c-field-text {
  &__actions {
    display: inline-block;
    margin-left: $spacing-medium;
  }
}

.c-field-radios {
  & label {
    display: inline-block;
    margin-right: $spacing-tiny;
    & span {
      vertical-align: top;
      margin-left: $spacing-tiny;
    }
  }
}