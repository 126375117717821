@font-face {
	font-family: 'icomoon';
	src:url('../font/icomoon.eot');
	src:url('../font/icomoon.eot') format('embedded-opentype'),
		url('../font/icomoon.woff') format('woff'),
		url('../font/icomoon.ttf') format('truetype'),
		url('../font/icomoon.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-office:before {
	content: "\e600";
}
.icomoon-library:before {
	content: "\e601";
}
.icomoon-user:before {
	content: "\e602";
}
.icomoon-user2:before {
	content: "\e603";
}
.icomoon-user3:before {
	content: "\e604";
}
.icomoon-users:before {
	content: "\e605";
}
.icomoon-users2:before {
	content: "\e606";
}
.icomoon-user4:before {
	content: "\e607";
}

.icomoon-repeat {
	content: "\e984";
}