.bs-input-wa-hour{
  display: flex;
  flex-direction: row;
  position: relative;
}
.bs-input-wa-hour>div{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.bs-input-wa-hour>div>div:nth-child(1){
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  padding-left: 19px;
}
.bs-input-wa-hour>div>div:nth-child(1)>input{
  font-size: 1rem;
  width: 73px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
}
.bs-input-wa-hour>div>div:nth-child(2){
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  max-height: 190px;
  overflow: hidden;
}
.bs-input-wa-hour .bs-scrollBar{
    max-height: inherit;
    overflow-y: scroll;
}
.bs-input-wa-hour-select-value{
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bs-input-wa-hour-select-value:hover{
  cursor: pointer;
  background-color:rgba(82,168,236,0.8);
}