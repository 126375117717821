.c-issue-log-comment{
  font-weight: $font-weight-medium;
  font-size: $text-font-size-medium;
  font-style: italic;
  color:$color-black;
  border-radius:$border-radius;
}

.c-issue-log-comment.c-issue-log-comment--highlight {
  background-color: #fff;
  padding: 12px;
}