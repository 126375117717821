.c-issue-history-day{
  &__date{
    text-align: center;
    padding-top: $spacing-tiny;
    padding-bottom: $spacing-tiny;
    position: relative;
    //
    &>.c-date{
      background:#efefef;
      position: relative;
      z-index: 3;
      padding-right: $spacing-tiny;
      padding-left: $spacing-tiny;
      font-weight: bold;
      font-size: $text-font-size-medium;
    }
    &:after{
      z-index: 2;
      content : ' ';
      position: absolute;
      left:50%;
      width: calc(100% - #{2*$spacing-small});
      bottom: $text-font-size-medium;
      transform : translate(-50%);
      border-bottom: 2px solid #cecece;
    }
  }
  & img {
    width: 3em;
  }
}