@import "../../../../../node_modules/blaze/scss/mixins/settings.global";
@import "../../../../../node_modules/blaze/scss/mixins/objects.grid";
@import "../../../../../node_modules/blaze/scss/mixins/utilities.alignment";

@media screen{
  .o-modal {
    @include center-block__content;
    display: block;
    width: $modal-width;
    border: $modal-border;
    border-radius: $modal-border-radius;
    background-color: $modal-background-color;
    overflow: hidden;
    z-index: $modal-z-index;

    & > .c-card {
      background-color: transparent;
      box-shadow: none;
    }

    .c-card__body {
      position: relative;
    }

    &__actions-bar {
      position: fixed;
      top: $spacing-large;
      right: $spacing-large;
      padding: $spacing-small;
    }
  }
  .o-modal--ghost {
    background-color: $modal-ghost-background-color;
    color: $modal-ghost-color;

    .c-heading {
      color: $modal-ghost-heading-color;
    }
  }

  .o-modal--full {
    top: $spacing-small;
    left: $spacing-small;
    width: calc(100% - #{2*$spacing-small});
    height: calc(100% - #{2*$spacing-small});
    @include mq($from: desktop) {
      top: $spacing-medium;
      left: $spacing-medium;
      width: calc(100% - #{2*$spacing-medium});
      height: calc(100% - #{2*$spacing-medium});
    }
    @include mq($from: wide) {
      top: $spacing-large;
      left: $spacing-large;
      width: calc(100% - #{2*$spacing-large});
      height: calc(100% - #{2*$spacing-large});
    }

    transform: none;

    .c-card__body {
      padding: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .c-card__footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  /*
  @media print {
    .o-modal {
      background-color: #fff !important;
      -webkit-print-color-adjust: exact;
    }

    .o-modal--full {
      top: 0;
      left: 0;
      width: 100%;
      height: inherit;
    }
  }
  */
}
