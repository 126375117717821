.c-brand {
  color: $color-brand;
  font-weight: bold;
  display: flex;

  &__betterstreet {
    display: inline-block;
    a{
      display: block;
    }
  }
  &__arrow {
    display: inline-block;
    @include ph($inuit-global-spacing-unit-tiny);
    svg{
      height: 1.5rem;
      width: auto;
    }
  }
  &__link {
    @include ph($inuit-global-spacing-unit-tiny);
    display: inline-block;
    color:#867185;
    position: relative;
    top:-4px;
  }
  &__name{
    font-size:24px;
  }

  &__compact {
    display: none;
    @include mq($from: mobile, $until: tablet) {
      display: block;
    }
    img,
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
  &__full {
    display: none;
    flex-direction: row;
    margin:0;
    img,
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    @include mq($from: tablet) {
      display: flex;
      align-items: center;
      padding-right: $inuit-global-spacing-unit;
      padding-left: $inuit-global-spacing-unit;
    }
  }
}
.c-brand--city{
  .c-brand__betterstreet{
    svg{
      width: 2.5rem;
      height: auto;
    }
  }
}
.no-flexbox{
  .c-brand__full{
    @include mq($from: tablet) {
      display:block;
    }
    //line-height: 68px;
    > * {
      line-height: normal;
      vertical-align:middle;
    }
  }
  .c-brand__link{

  }
  .c-brand__arrow{
    max-width: 20px;
  }
  .c-brand__betterstreet{
    svg{
      max-height: 44px;
    }

  }
}