
.c-panel{
  $panel-border: 1px solid $border-color;
  $panel-content-background: rgba(255,255,255,.5);
  border: $panel-border;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right:0;
  &__header{
    //background:#dedede;
    border-bottom: $panel-border;
    padding: $spacing-small;
  }
  &__content{
    background: $panel-content-background;
    flex:1;
    overflow-y:scroll;
    position: relative;
  }
  &__inner{
    height: 100%;
  }
}

.c-panel.c-issue-list {
  overflow: visible;
}