.bs-input-checkbox{
  line-height: 1;
}

.bs-button-radio{
  line-height: 1;
}

.bs-button-text {
  line-height: 1;
}
