.c-issues-loading-counter{
  padding: $spacing-small;
  z-index:2;
  position: relative;
  display: none;
  &--visible{
    display: block;
  }
  &__body{
    margin: auto;
  }
}