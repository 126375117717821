@mixin button-color($background-color: $button-background-color, $color: $button-color) {
  border: $button-border;
  background-color: $background-color;
  color: $color;

  &.c-button--active {
    background-color: darken($background-color, $color-tint);
  }

  &.c-button--toggled {
    background-color: $background-color;
    color: white;
  }

  &:not(:disabled) {
    &:hover {
      background-color: lighten($background-color, $color-tint);
    }

    &:focus {
      border-color: $button-focus-border-color;
      box-shadow: $button-focus-box-shadow;
    }

    &:active {
      background-color: darken($background-color, $color-tint);
    }
  }
}
@mixin button-ghost($color: $button-ghost-color, $color-hover: $button-ghost-hover-color) {
  border: $button-ghost-border-width $button-ghost-border-style $color;
  background-color: transparent;
  color: $color;

  &.c-button--active {
    border-color: darken($color, $color-tint);
    background-color: darken($color, $color-tint);
    color: $color-hover;
  }

  &:not(:disabled) {
    &:hover {
      background-color: $color;
      color: $color-hover;
    }

    &:focus {
      border-color: $button-focus-border-color;
      box-shadow: $button-focus-box-shadow;
    }

    &:active {
      border-color: darken($color, $color-tint);
      background-color: darken($color, $color-tint);
      color: $color-hover;
    }
  }
}
@mixin button {
  @include button-color;
  display: inline-block;
  max-width: 100%;
  margin: $button-margin;
  padding: $button-padding;
  border-radius: $button-border-radius;
  outline: 0;
  font-family: $button-font-family;
  font-size: $button-font-size;
  line-height: $button-line-height;
  text-align: $button-text-align;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: $button-white-space;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  appearance: none;
  user-select: none;
  font-weight: $font-weight-regular;
  transition: all .3s;
  &:disabled {
    cursor: not-allowed;
    opacity: $button-disabled-opacity;
  }
}