.c-issue-detail-field-date{
  &__label,
  &__field,
  &__date{
    display: inline-block;
  }
  &__date{
    &:first-child{
      .c-issue-detail-field-date__label{
        &:before {
          content: "";
        }
      }
    }
  }
  &__label{
    &:before {
      content: "\00a0";
    }
    &:after{
      content: "\00a0";
    }
  }
}