@media screen{
  .c-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($overlay-background-color, .3);
    z-index: $overlay-z-index;


    &__actions {
      position: absolute;
      right: $spacing-huge;
      top: 10px;
    }
  }

  .c-overlay--fullpage {
    position: fixed;
  }

  .c-overlay--transparent {
    background-color: $overlay-transparent-background-color;
  }

  .c-overlay--dismissable {
    cursor: pointer;
  }

}
