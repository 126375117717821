.c-avatar {
  display: inline-block;
  position: relative;
  width: $avatar-medium-size;
  height: $avatar-medium-size;
  margin: $avatar-margin;
  border-radius: 50%;
  overflow: hidden;
  background-color: $avatar-background-color;
  color: $avatar-color;
  vertical-align: middle;
  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--large {
    width: 180px;
    height: 180px;
    max-width: 100%;
    max-height: 100%;
    & .c-avatar__label {
      font-size: 80px !important;
    }
  }
  &--huge {
    width: 220px;
    height: 220px;
    max-width: 100%;
    max-height: 100%;
    & .c-avatar__label {
      font-size: 80px !important;
    }
  }
}

.c-avatar__img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: $avatar-img-border-radius;
  overflow: hidden;
}

.c-avatar__img + .c-avatar__img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: $avatar-inner-img-size;
  height: $avatar-inner-img-size;
}