.c-tabs {
  & .c-tab-heading {
    padding: $spacing-small;
    &.c-tab-heading--active {
      font-weight: bold;
    }
  }
} 

.c-tabs__tab {
  padding: $spacing-small 0;
}