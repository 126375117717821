
.c-console-panel {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  margin-bottom: $spacing-medium;
  padding: 0;
  &__header {
    padding: $spacing-small;
    background-color: $color-grey-table-header;
    margin-bottom: $spacing-small;
    border-bottom: 1px solid $border-color;
  }
  & table {
    width: 100%;
    & th {
      font-weight: bold;
      font-size: $text-font-size-xsmall;
    }
    & td {
      font-size: $text-font-size-small;
    }
  }
}