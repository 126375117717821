.c-issue-history{
  $modal-background-color : #f2eeeb;
  $more-background-color : transparentize($modal-background-color,.1);
  //
  position: relative;
  overflow: hidden;
  margin-bottom: $spacing-medium;

  @media print{
    overflow: auto;
  }
  //
  &__logs{

  }
  &__header{
    padding: $spacing-small 0 0 0;
  }
  &__more{
    display: block;
    text-align: center;
    padding: $spacing-tiny;
  }
  &__editor{
    position: relative;
    z-index:4;
  }
}