.rt-th, .rt-td {
  &:focus{
    outline: none;
  }
  font-size: $text-font-size-large;
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 $color-brand;
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 $color-brand;
}

.rt-td{
  & a{
    font-weight: bold;
  }
}

.rc-table-row {
  cursor: pointer;
  & td {
    vertical-align: middle;
  }
}

.rc-table-placeholder {
  text-align: center;
  padding: $spacing-small 0;
  font-weight: bold;
  border-top: 1px solid $border-color;
}