.c-issues{
  // position: absolute;
  // top:0;
  // bottom: 0;
  // left:0;
  // right:0;
  height:100%;
  display: flex;
  flex-direction: row-reverse;
  //background: $color-background;
  @media print {
    overflow: visible;
    //position: relative;
  }

  &__filters{
    // position: absolute;
    // left: 0;
    // top:0;
    // bottom: 0;
    flex: 0 0 auto;
    padding: $spacing-tiny;
    overflow-y: auto;
    border-right:1px solid darken($color-sidebar-dark,8%);
    //box-shadow: $box-shadow-higher;
    // z-index: $z-over-content+1;
    background: $color-sidebar-dark;
    color:white;
    box-sizing: border-box;
    width: $size-small;
    @include mq($from: desktop) {
      width: $size-medium;
      padding: $spacing-small;
    }
    @include mq($from: wide) {
      width: $size-large;
      padding: $spacing-medium;
    }
  }

  &__main{
    @media screen{
      height: 100%;
      flex-grow: 1;
      display:flex;
      flex-direction: column;
      // position: absolute;
      // top:0;
      left: $size-small;
      // right:0;
      // bottom: 0;
      @include mq($from: desktop) {
        left: $size-medium;
      }
      @include mq($from: wide) {
        left: $size-large;
      }
      &--scroll{
        overflow-y: scroll;
      }
      &--collapsed {
        left: 3rem !important;
      }
    }
  }

  &__subnav{
    //background:$color-header-subnav;
    border-bottom: 1px solid $border-color;
    padding: $spacing-small $spacing-medium;
  }

  &__toolbar{
    margin-top: $spacing-small;
  }

  &__selector-toolbar{
    background-color: $color-grey-blue;
    padding: $spacing-small $spacing-medium;
    border-bottom: 1px solid $border-color;
    & a.c-button {
      color: $color-black;
      display: inline-block;
      margin-left: $spacing-small;
    }
    & .o-dropdown {
      display: inline-block;
    }
  }

  &__list{
    flex:1;
    position: relative;
  }


  &__issue-map{
    display: flex;
    flex:1;
  }

  &__map{
    flex:2;
    //height:100%;
    position: relative;
    overflow: hidden;
  }

  &--map{
    .c-issues-toolbar{
    }
    .c-issues{
      &__list{
        flex:1;
        width:$size-medium;
      }
    }
  }
  &--list{
    .c-issues-toolbar{
      //padding: 0 $spacing-small;
      //@extend .o-wrapper;
    }
    .c-issues-navbar{
      //@extend .o-wrapper;
    }
  }
}

.c-issues__filters .bs-select-wa-values-item{
  color: #5e676c;
}

.c-issues__main.c-issues__main--collapsed .c-issues__map {
  flex: 3;
}