//
// BetterStyling V2
//

html,
body{
  font-family: 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif;
  //background-color: $color-global-background;
  @media screen{
    width:100%;
    height:100%;
    overflow-x: hidden;
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
}
html{
  font-size: 80%;
  @include mq($from:large){
    font-size: 100%;
  }
}
body{
  font-size: $text-font-size-medium;
}
#content{
  max-width: none;
}
address{
  margin: 0;
}
//hack bootstrap for react-popover
.popover{
  max-width: none;
  border:none;
  box-shadow: none;
  border:0;
  background:none;
}


input[type=text], input[type=password], input[type=email] {
  &.overide-bs{
    position: relative;
    height: inherit;
    margin: 0;
    padding: 0;
    border: none;
    background: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}

li, dt, dd, p, figure, th, td, caption, pre {
  font-size: 1em;
}


.c-button--close.u-hide-for-print{
  height: auto !important;
}