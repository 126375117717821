/*!
 * Project:   BETTERSTREET
 * Date:      2013/06/05
/* ----------------------------------------
  Line Updated 186, 695 & 188
 */

* { margin: 0; padding: 0; }
::-webkit-validation-bubble-message,::-webkit-validation-bubble,
::-webkit-validation-bubble-arrow,
::-webkit-validation-bubble-message { display: none; }

/*html { overflow-y: scroll; font-size: 100.01%; }*/
/*body { background: #f2eeeb; font-size: 62.5%; }*/
body { background: #f2eeeb; }

body, textarea, input, select, option, button { color: #5e676c; font-family: 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; line-height: 1.5; }

body.background {background: url('../images/background.jpg') no-repeat fixed center center; width: 100%; height: 100%;}
li, dt, dd, p, figure, th, td, caption, pre { font-size: 1.2em; } /*---*/ li *, li p, li li, dt *, dd *, p *, figure *, th *, td * { font-size: 1em; }

h1, h2, h3, h4, h5, h6 { font: 600 1em/1.2 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; }
h1 { font-size: 2em; }
h2 { font-size: 1.8em; }
h3 { font-size: 1.6em; }
h4 { font-size: 1.4em; }
h5 { font-size: 1.2em; }
h6 { font-size: 1.0em; }

a { color: #aaa; text-decoration: none; cursor: pointer;}
a:hover, a:focus, a:active { color: #bbb; text-decoration: none; }
 a span { cursor: pointer; }

* { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
img { box-sizing: auto; -moz-box-sizing: auto; -webkit-box-sizing: auto; }

/*! layout -------- */

 #top { position: relative; width: 940px; margin: 0 auto; padding-bottom: 1px; }
 #top:before { position: absolute; top: 0; bottom: 0; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #cdcac6; background: #fff; content: ' '; }
 #top:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  #logo { float: left; width: 23.0769%; }
  .homepage #logo { width: auto; }
   #logo img { display: inline-block; max-width: 185px; vertical-align: middle; max-height: 40px;}
   #logo span { display: none; margin-left: 10px; color: #aaa; font-size: 11px; font-weight: 600; vertical-align: middle; }
   .homepage #logo a span { display: inline-block; }
 #top .btn-navbar { margin-top: 14px; }
 #top .user { float: right; margin: 0; }
  #nav { float: left; margin: 0; border-right: 1px solid #efefef; }
  .homepage #nav { float: right; margin: 0; *margin: 0; border-right: none; }
   #nav ul { list-style: none; margin: 0; padding: 0; }
   #nav ul li { position: relative; font-size: 14px; font-weight: 600; line-height: 40px; border-left: 1px solid #efefef; }
    #nav ul li a { position: relative; padding: 10px 20px; color: #666; outline: none; }
    #nav ul li.parent > a:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
    #nav ul li.parent > a { padding-right: 30px; }
    #nav ul li a:hover, #nav ul li a:focus, #nav ul li a:active, #nav ul li:hover > a, #nav ul li.active > a, #nav ul li.parent.active > a, #nav ul li.parent.open > a { background: #f9f7f5; color: #000;}
    #nav ul li.active a { -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; cursor: default;}
     #nav ul li a .ico { display: inline-block; margin: -2px 0 0 8px; vertical-align: middle }
    #nav ul li ul { display: none; position: absolute; top: 60px; left: 0; width: 200px; border: 1px solid #ddd; background: #fff; }
    #nav ul li:hover > ul { display: block; }
     #nav ul li ul li { float: none; width: 100%; border: none; font-size: 13px; }
     #nav ul li ul li ~ li { border-top: 1px solid #efefef; }
      #nav ul li ul li a { display: block; height: auto; padding: 14px; line-height: 1.2; }
#right-nav { margin: 0; border-right: 0;float:right;  }
  .homepage #right-nav { float: right; margin: 0; *margin: 0; border-right: none; }
   #right-nav ul { list-style: none; margin: 0; padding: 0; }
   #right-nav ul li { position: relative; font-size: 14px; font-weight: 600; line-height: 40px; border-left: 1px solid #efefef; }
    #right-nav ul li a { position: relative; padding: 23px 20px; color: #666; outline: none; line-height: 40px; }
    #right-nav ul li.parent > a:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
    #right-nav ul li.parent > a { padding-right: 30px; }
    #right-nav ul li a:hover, #right-nav ul li a:focus, #right-nav ul li a:active, #right-nav ul li:hover > a, #right-nav ul li.active > a, #right-nav ul li.parent.active > a, #right-nav ul li.parent.open > a { background: #f9f7f5; color: #000; }
    #right-nav ul li.active a { -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
     #right-nav ul li a .ico { display: inline-block; margin: -2px 0 0 8px; vertical-align: middle }
    #right-nav ul li ul { display: none; position: absolute; top: 60px; left: 0; width: 200px; border: 1px solid #ddd; background: #fff; }
    #right-nav ul li:hover > ul { display: block; }
     #right-nav ul li ul li { float: none; width: 100%; border: none; font-size: 13px; }
     #right-nav ul li ul li ~ li { border-top: 1px solid #efefef; }
      #right-nav ul li ul li a { display: block; height: auto; padding: 14px; line-height: 1.2; }
 #content { position: relative; z-index: 2; margin-bottom: 0; padding: 0 0 40px; min-height:400px; z-index: 100; max-width: 1600px; margin-left: auto; margin-right: auto;}
 #footer { position: relative; z-index: 1; margin: 0; padding: 20px 0; border-top: 1px solid #e3dfdc; }
  #footer .logo { display: inline-block; margin: 0 10px; vertical-align: middle; }
   #footer .logo img { display: block; margin: 0 auto; }
  #footer nav { display: inline-block; margin: 0 10px; vertical-align: middle; }
  #footer .two nav { float: right; }
   #footer nav ul { list-style: none; margin: 0; padding: 0; }
    #footer nav ul li { display: inline-block; margin: 0; vertical-align: middle; }
    #footer nav ul li ~ li { margin-left: 10px; }
     #footer nav ul li a { color: #838383; }
     #footer nav ul li a:hover,
     #footer nav ul li a:focus,
     #footer nav ul li a:active,
     #footer nav ul li.active a { color: #aaa; }

/*! elements ------ */

.addissue-a { }
 .addissue-a > header { position: relative; z-index: 1; min-height: 40px; margin: 0 0 20px; }
 .addissue-a > header:before { position: absolute; top: 0; bottom: -1px; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #cecbc8; background: #f9f7f5; content: ' '; }
 .addissue-a > header:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .addissue-a > header h2 { float: left; margin: 0; padding: 10px 0; color: #5e666c; font-size: 1.4em; font-weight: normal; line-height: 20px; }
   .addissue-a > header h2 span { margin-left: 5px; color: #999; font-size: 0.93em; line-height: 20px; }
 .addissue-a .block-issue { position: relative; margin-bottom: 10px; border: 1px solid #ddd9d7; background: #fff; }
  .addissue-a .block-issue fieldset { margin: 0; padding: 20px 20px 8px; border: none; }
  .addissue-a .block-issue fieldset.a { background: #fff; }
  .addissue-a .block-issue fieldset.b { background: #f9f7f5; }
  .addissue-a .block-issue fieldset ~ fieldset { border-top: 1px solid #e9e8e6; }
  .addissue-a .block-issue fieldset h3 { margin: 0 0; color: #5e676c; font-size: 1.4em; line-height: 1.43; }
 .addissue-a .block-issue p { position: relative; margin: 0 0 12px; }
   .addissue-a .block-issue p.field label { color: #b0adb1; font-size: 12px; font-weight: 600; }
   .js .addissue-a .block-issue p.field label { position: absolute; top: 0; left: 11px; z-index: 10; line-height: 27px; }
   .js .addissue-a .block-issue p.field.ico label { left: 32px; }
   .js .addissue-a .block-issue p.field.message label { top: 5px; }
   .addissue-a .block-issue p.field input { width: 100%; border: none; box-shadow: none; }
   .addissue-a .block-issue p.field textarea, .addissue-a .block-issue div.field textarea { width: 100%; min-height: 100px; box-shadow: none; resize: none; }
   .addissue-a .block-issue p.field .select-a { width: 100%; }
 .addissue-a .block-issue .wrap { width: 100%; margin: 0 0 12px; }
 .addissue-a .block-issue .wrap:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .addissue-a .block-issue .wrap p, .addissue-a .block-issue .wrap div { float: left; margin: 0; padding: 0; }
  .addissue-a .block-issue .wrap p ~ p, .addissue-a .block-issue .wrap div ~ p { padding-left: 10px; }
  .js .addissue-a .block-issue .wrap p ~ p.field label { left: 21px; }
  .js .addissue-a .block-issue .wrap p ~ p.field.ico label { left: 42px; }
  .addissue-a .block-issue .wrap p.field, .addissue-a .block-issue .wrap div.field { width: 50%; }
 .addissue-a .block-issue .wrap.upload { font-size: 0; text-align: center; letter-spacing: -5px; }
  .addissue-a .block-issue .wrap.upload p.dragndrop { display: inline-block; width: 60%; margin: 0; padding: 20px 10px; color: #999; font-size: 16px; font-weight: normal; line-height: 27px; letter-spacing: 0; vertical-align: middle; }
   .addissue-a .block-issue .wrap.upload p.dragndrop:before { display: inline-block; width: 16px; height: 18px; content: ' '; overflow: hidden; margin: -2px 8px 0 0; background: url(../img/arrow-top-a.png) no-repeat; vertical-align: middle; }
  .addissue-a .block-issue .wrap.upload p.file { display: inline-block; width: 40%; padding: 20px 0; color: #999; font-size: 12px; letter-spacing: 0; vertical-align: middle; }
   .addissue-a .block-issue .wrap.upload p.file .inner { display: inline-block; position: relative; z-index: 1; overflow: hidden; vertical-align: middle; }
   .addissue-a .block-issue .wrap.upload p.file label { display: inline-block; position: relative; top: auto; left: auto; z-index: 0; height: 27px; margin: 0; padding: 0 10px; background: #a09696; color: #fff; font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; line-height: 27px; }
   .addissue-a .block-issue .wrap.upload p.file label { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
    .addissue-a .block-issue .wrap.upload p.file label i { margin-right: 5px; }
    .addissue-a .block-issue .wrap.upload p.file label em { font-weight: normal; }
   .addissue-a .block-issue .wrap.upload p.file .inner:hover label { background-color: #948989; color: #fff; cursor: pointer; }
   .addissue-a .block-issue .wrap.upload p.file input[type=file] { display: block; position: absolute; top: 0; left: 0; z-index: 2 !important; width: 100%; height: 27px; border: none; margin: 0; padding: 0; font-size: 27px; cursor: pointer; opacity: 0; }
  .ie8 .addissue-a .block-issue .wrap.upload p.file input[type=file] { filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0); }
 .addissue-a .block-issue p.action .button-a { width: 100%; }
 .addissue-a .apps-a { }
  .addissue-a .apps-a ul { display: table; table-layout: fixed; width: 100%; border: none; }
   .addissue-a .apps-a ul li { display: table-cell; margin: 0; padding: 0; border: 1px solid #ddd9d7; font-size: 1.1em; line-height: 12px; }
   .addissue-a .apps-a ul li ~ li { border-left: none; }
 

.adv-a { border: 1px solid #ddd9d7; background: #f9f7f5; }
 .adv-a header { display: table; width: 100%; margin: 0; border-bottom: 10px solid #4697ac; background: #5dc9e6 url(../img/adv-a-bg.png) 100% 100% no-repeat; }
  .adv-a header h3 { display: table-cell; padding: 15px; color: #fff; font-size: 16px; font-weight: 800; line-height: 1.2; vertical-align: middle; }
  .adv-a header figure { display: table-cell; width: 70px; padding: 15px 0 0; text-align: left; vertical-align: bottom; }
   .adv-a header figure img { max-width: 50px; margin-bottom: -2px; }
  .adv-a .content { padding: 22px; }
  .adv-a p.action { margin: 0; border-top: 1px solid #e9e9e9; text-align: center; }
   .adv-a p.action a { display: block; height: 40px; background: #fff; color: #5e666c; font-size: 18px; line-height: 40px; }
    .adv-a p.action a:before { display: inline-block; width: 10px; height: 12px; margin: -3px 8px 0 0; background: url(../img/ico-flag-a.png) 50% 50% no-repeat; vertical-align: middle; content: ' '; }
   .adv-a p.action a:hover,
   .adv-a p.action a:focus,
   .adv-a p.action a:active { background: #fffdfb; color: #938989; }

.apps-a { margin: 0 0 20px; }
 .apps-a ul { list-style: none; margin: 0; padding: 0; border: 1px solid #ddd9d7; }
  .apps-a ul li { margin: 0; padding: 0; font-size: 1.1em; line-height: 12px; }
  .apps-a ul li ~ li { border-top: 1px solid #e9e8e6; }
   .apps-a ul li a { display: block; position: relative; padding: 20px 15px 20px 70px; background: #f9f7f5; color: #aaa; }
   .apps-a ul li a:hover { background: #fff; }
   .apps-a ul li.add a { background: #fff; }
    .apps-a ul li a:before { position: absolute; top: 50%; left: 15px; width: 40px; height: 40px; overflow: hidden; margin: -20px 0 0; background: url(../img/apps-a-bgs.png) -10001px -10001px no-repeat; content: ' '; }
    .apps-a ul li.add a:before { background-position: 0 0; }
    .apps-a ul li.appstore a:before { background-position: 0 -50px; }
    .apps-a ul li.android a:before { background-position: 0 -100px; }
    .apps-a ul li a span { display: block; }
    .apps-a ul li a strong { display: block; color: #5e676c; font-size: 18px; font-weight: normal; line-height: 28px; }
    .apps-a ul li.appstore a strong { width: 125px; height: 28px; overflow: hidden; background: url(../img/apps-a-bgs.png) -50px -48px no-repeat; text-indent: 105%; white-space: nowrap; }
    .apps-a ul li.android a strong { width: 125px; height: 28px; overflow: hidden; background: url(../img/apps-a-bgs.png) -50px -99px no-repeat; text-indent: 105%; white-space: nowrap; }

ul.connect-a, .connect-a ul { list-style: none; height: 40px; padding: 0 16px; border: 1px solid #ddd9d7; border-width: 0 1px; }
ul.connect-a:after, .connect-a ul:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
 .connect-a li { float: left; margin: 0; padding: 12px 0; }
 .connect-a li ~ li { margin-left: 4px; }
  .connect-a li a { display: block; width: 16px; height: 16px; overflow: hidden; background: url(../img/connect-a-icons.png) -10001px -10001px no-repeat; text-indent: 105%; white-space: nowrap; }
  .connect-a li.facebook a { background-position: 0 0; }
  .connect-a li.twitter a { background-position: -20px 0; }
  .connect-a li.pinterest a { background-position: -40px 0; }

.details-a { }
 .details-a > header { position: relative; z-index: 1; min-height: 40px; margin: 0 0 15px; padding-left: 25.668355319148934%; *padding-left: 25.816163829787233%; }
 .details-a > header:before { position: absolute; top: 0; bottom: -1px; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #cecbc8; background: #f9f7f5; content: ' '; }
 .details-a > header:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .details-a > header h2 { float: left; margin: 0; padding: 10px 0; color: #5e666c; font-size: 1.4em; font-weight: normal; line-height: 20px; }
   .details-a > header h2 span.light { margin-left: 5px; color: #999; font-size: 0.93em; line-height: 20px; }
  .details-a > header .utils { float: right; margin: 0; }
   .details-a > header .utils ul { list-style: none; margin: 0; padding: 0; }
    .details-a > header .utils ul li { float: left; margin: 0; border-right: 1px solid #e9e8e6; font-size: 1.3em; line-height: 40px; }
    .details-a > header .utils ul li:first-child { border-left: 1px solid #e9e8e6; }
     .details-a > header .utils ul li a { display: block; padding: 0 10px; color: #999; }
     .details-a > header .utils ul li a:hover,
     .details-a > header .utils ul li a:focus,
     .details-a > header .utils ul li a:active { background: #f4f2f0; color: #999; }
     .details-a > header .utils ul li a.ico:before { display: inline-block; width: 12px; height: 12px; overflow: hidden; margin: -4px 6px 0 0; background: url(../img/icons-sprite-a.png) -10001px -10001px no-repeat; vertical-align: middle; content: ' '; }
     .details-a > header .utils ul li a.ico.email:before { background-position: -1px -41px; }
     .details-a > header .utils ul li a.ico.print:before { background-position: -1px -61px; }
     .details-a > header .utils ul li a [class^="icon-"],
     .details-a > header .utils ul li a [class*=" icon-"] { margin-top: 2px; }
 .details-a .images { position: relative; z-index: 2; margin-top: -45px; }
 .details-a .block-status  { position: relative; margin-bottom: 3px; border: 1px solid #ddd9d7; background: #fff; }
  .details-a .block-status form { margin: 0; }
  .details-a .block-status p,
  .details-a .block-status .wrap { margin: 0; padding: 10px 10px; border-top: 1px solid #efefef; }
  /*.details-a .block-status .wrap { margin: 0; padding: 10px 10px; border-top: 1px solid #efefef; }*/
  .details-a .block-status p:after,
  .details-a .block-status .wrap:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .details-a .block-status p:first-child,
  .details-a .block-status .wrap:first-child { border-top: none; }
   .details-a .block-status p.field label,
   .details-a .block-status p.flabel { float: left; width: 33%; margin: 0; padding: 0 0 0 0; font-size: 12px; font-weight: normal; line-height: 27px; }
   /*.details-a .block-status p.flabel { float: left; width: 20%; margin: 0; padding: 0 10px 0 0; font-size: 12px; font-weight: normal; line-height: 27px;}*/
   .details-a .block-status p.field .select-a,
   .details-a .block-status div.field .select-a,
   .details-a .block-status .wrap .dropdown-b { float: left; width: 100%; max-width: 170px; margin: 0;}
   .details-a .block-status p.field input { float: left; width: 100%; max-width: 330px; }
  .details-a .block-status p.action { padding: 15px 20px; border-top: 1px solid #eae9e8; background: #faf9f8; text-align: right; }
.white-box  { position: relative; margin-bottom: 8px; border: 1px solid #ddd9d7; background: #fff; }


  .details-a .block-info { margin: 0 0 20px; border: 1px solid #ddd9d7; background: #f9f7f5; }
 .details-a .block-info .head { position: relative; margin: 0 0 20px; border-bottom: 1px solid #e9e8e6; }
 .details-a .block-info .head h3 { margin: 0; padding: 15px 44px 15px 20px; color: #5e676c; font-size: 1.4em; line-height: 1.43; }
 .details-a .block-info .head p.edit-a { position: absolute; top: 50%; right: 15px; margin: -14px 0 0; padding: 0; }
 .details-a .block-info .head.closed { margin-bottom: 0; }
 .details-a .block-info.followers .head h3 { padding-right: 20px; }
 .details-a .block-info p { margin: 0 0 15px; padding: 0 8px; }
 .details-a .block-info p:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .details-a .block-info p strong { float: left; width: 20%; margin: 0; padding: 0 10px 0 0; color: #999; font-size: 12px; font-weight: normal; }
  .details-a .block-info p span { float: left; width: 80%; margin: 0; padding: 0 0 0 10px; font-size: 12px; font-weight: normal; }
   .details-a .block-info p span em { margin-left: 5px; }
   .details-a .block-info p span em:first-child { margin-left: 0; }

 .details-a .block-edit-info { position: relative; margin: 0 0 20px; border: 1px solid #ddd9d7; background: #fff; }
  .details-a .block-edit-info .head { position: relative; margin: 0 0 20px; }
   .details-a .block-edit-info .head h3 { margin: 0; padding: 15px 44px 15px 20px; border-bottom: 1px solid #e9e8e6; color: #5e676c; font-size: 1.4em; line-height: 1.43; }
  .details-a .block-edit-info p { margin: 0; }
    .details-a .block-edit-info div.field input { width: 100%; }
    .details-a .block-edit-info p.field input { width: 100%; }
    .details-a .block-edit-info div.field .select-a,
    .details-a .block-edit-info p.field .select-a { width: 100%; }
  .details-a .block-edit-info .wrap { width: 100%; margin: 0 0 12px; padding: 0 20px; }
  .details-a .block-edit-info .wrap:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
   .details-a .block-edit-info .wrap p { float: left; margin: 0; padding: 0; }
   .details-a .block-edit-info .wrap p ~ p { padding-left: 10px; }
   .details-a .block-edit-info .wrap p.fieldname { width: 20%; }
   .details-a .block-edit-info .wrap p.field { width: 40%; }
   .details-a .block-edit-info .head p.edit-a { position: absolute; top: 50%; right: 15px; margin: -14px 0 0; padding: 0; }
  .details-a .block-edit-info p.action { margin: 20px 0 0; padding: 15px 20px; border-top: 1px solid #eae9e8; background: #faf9f8; text-align: right; }

 .details-a .updates { margin: 0 0 15px; text-align: right; }
  .details-a .updates p { margin: 0 0 5px; color: #878787; font-size: 1.2em; line-height: 1.2; }
   .details-a .updates p span { margin-right: 4px; color: #aaa; }

 .details-a .activity { position: relative; margin: 30px 0 20px; border: 1px solid #ddd9d7; background: #fff;}
  .details-a .activity h3 { margin: 0; padding: 15px 44px 15px 20px; color: #5e676c; font-size: 1.4em; line-height: 1.43;}
  .details-a .activity .item { margin: 0; padding: 10px 10px; border-top: 1px solid #ddd9d7;}
  .details-a .activity .item p:first-child { border-top: none; }
  .details-a .activity .item:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
   .details-a .activity .item figure { position: relative; float: left; width: 20px; height: 20px; overflow: hidden; margin: 0; }
   .details-a .activity .item figure {}
    .details-a .activity .item figure img { display: block; max-width: 12px; margin: 0; }
   .details-a .activity .item figure ~ * { margin-left: 54px !important; }
   .details-a .activity .item h4 { margin: 0 0 4px 56px; font-size: 1.2em; font-weight: 600; line-height: 1.2; }
    .details-a .activity .item h4 a { color: #a09696; }
   .details-a .activity .item p { margin: 0 0 5px; font-size: 1.2em; line-height: 1.2; }
   .details-a .activity .item p.info { margin-left: 56px; padding-left: 12px; border-left: 1px solid #B6B6B6; color: #5E676C; font-size: 1.2em; font-style: italic; line-height: 1.2; white-space: pre-wrap; overflow: hidden;}
   .details-a .activity .item p.date { color: #aaa; font-size: 1.1em; line-height: 1.2; }

.dropdown-a { }
 .dropdown-a a.dropdown-toggle { display: block; position: relative; width: 100%; height: 40px; overflow: hidden; margin: 0; padding: 0 34px 0 10px; border: 1px solid #ddd9d7; background: #f9f7f5; color: #999; font-size: 12px; font-weight: normal; line-height: 40px; text-overflow: ellipsis; white-space: nowrap; outline: none; }
 .dropdown-a a.dropdown-toggle:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
  .dropdown-a a.dropdown-toggle strong { float: left; min-width: 75px; margin: 0 15px 0 -10px; padding: 0 15px; border-right: 1px solid #e9e8e6; background: #fff; color: #5e676c; font-weight: normal; }
 .dropdown-a ul.dropdown-menu { left: 50%; width: 240px; margin: 6px 0 0 -120px !important; padding: 6px !important; border: none; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); }
 .dropdown-a ul.dropdown-menu { -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
 .dropdown-a ul.dropdown-menu:before { position: absolute; top: 5px; bottom: 5px; left: 5px; right: 5px; z-index: -1; border: 1px solid #b8b5b6; background: #f9f7f5; content: ' '; }
 .dropdown-a ul.dropdown-menu:after { position: absolute; top: -5px; left: 50%; width: 10px; height: 11px; margin: 0 0 0 -5px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat; content: ' '; }
  .dropdown-a ul.dropdown-menu li { float: none; width: 100%; border: none !important; padding: 10px; font-size: 12px; }
  .dropdown-a ul.dropdown-menu li:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .dropdown-a ul.dropdown-menu li ~ li { border-top: 1px solid #e9e8e6 !important; }
   .dropdown-a ul.dropdown-menu li .label-b { float: left; margin: 0; }
   .dropdown-a ul.dropdown-menu li .label-b + span { float: right; margin: 0; color: #aaa; font-size: 11px; line-height: 25px; }

.dropdown-a.user { border: 1px solid #efefef; border-width: 0 1px; }
 .dropdown-a.user a.dropdown-toggle { float: left; width: auto; height: 60px; padding: 17px 34px 17px 17px; border: none; background: #fff; color: #666; }
  .dropdown-a.user a.dropdown-toggle .avatar { position: relative; float: left; width: 26px; height: 26px; overflow: hidden; margin: 0; border: 2px solid #dad5d4; background: #dad5d4; }
  .dropdown-a.user a.dropdown-toggle .avatar { -moz-border-radius: 13px; -webkit-border-radius: 13px; border-radius: 13px;  }
   .dropdown-a.user a.dropdown-toggle .avatar img { display: block; max-width: 22px; margin: 0; }
  .dropdown-a.user a.dropdown-toggle .avatar ~ * { margin-left: 36px !important; }
  .dropdown-a.user a.dropdown-toggle .name { display: block; position: relative; margin: 0; font-size: 14px; font-weight: 600; line-height: 26px; }

.dropdown-b { }
 .dropdown-b a.dropdown-toggle,
 .btn-group.open a.dropdown-toggle { display: inline-block; position: relative; width: 100%; height: 27px; overflow: hidden; margin: 0; padding: 0 30px 0 10px; border: none; background: #f2eeeb url(../img/select-a.png) 100% 50% no-repeat; color: #666; font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-overflow: ellipsis; white-space: nowrap; vertical-align: top; outline: none; }
 .dropdown-b a.dropdown-toggle [class^="icon-"],
 .dropdown-b a.dropdown-toggle [class*=" icon-"] { margin-top: 1px; }
 .dropdown-b a.dropdown-toggle,
 .btn-group.open a.dropdown-toggle { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;  }
 .dropdown-b a.dropdown-toggle.green,
 .btn-group.open a.dropdown-toggle.green { background-color: #89cc1f; color: #fff; }
 .dropdown-b a.dropdown-toggle.orange,
 .btn-group.open a.dropdown-toggle.orange { background-color: #f77448; color: #fff; }
 .dropdown-b a.dropdown-toggle.blue,
 .btn-group.open a.dropdown-toggle.blue { background-color: #5dc9e6; color: #fff; }
 .dropdown-b a.dropdown-toggle.gold,
 .btn-group.open a.dropdown-toggle.gold { background-color: #e3b43e; color: #fff; }
 .dropdown-b a.dropdown-toggle.graphite,
 .btn-group.open a.dropdown-toggle.graphite { background-color: #6b98b5; color: #fff; }
 .dropdown-b a.dropdown-toggle.purple,
 .btn-group.open a.dropdown-toggle.purple { background-color: #a286c0; color: #fff; }
 .dropdown-b a.dropdown-toggle.pink,
 .btn-group.open a.dropdown-toggle.pink { background-color: #e78fa5; color: #fff; }
 .dropdown-b a.dropdown-toggle.azure,
 .btn-group.open a.dropdown-toggle.azure { background-color: #00c5b1; color: #fff; }
 .dropdown-b a.dropdown-toggle.army,
 .btn-group.open a.dropdown-toggle.army { background-color: #aaaa5a; color: #fff; }
 .dropdown-b a.dropdown-toggle.beige,
 .btn-group.open a.dropdown-toggle.beige { background-color: #dedccf; color: #fff; }
 .dropdown-b a.dropdown-toggle.grey,
 .btn-group.open a.dropdown-toggle.grey { background-color: #a09696; color: #fff; }
 .dropdown-b ul.dropdown-menu { left: 50%; width: 240px; margin: 10px 0 0 -120px !important; padding: 6px !important; border: none; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); }
 .dropdown-b ul.dropdown-menu { -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
 .dropdown-b ul.dropdown-menu:before { position: absolute; top: 5px; bottom: 5px; left: 5px; right: 5px; z-index: -1; border: 1px solid #b8b5b6; background: #f9f7f5; content: ' '; }
 .dropdown-b ul.dropdown-menu:after { position: absolute; top: -5px; left: 50%; width: 10px; height: 11px; margin: 0 0 0 -5px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat; content: ' '; }
  .dropdown-b ul.dropdown-menu li { float: none; width: 100%; border: none !important; padding: 0; font-size: 12px; }
  .dropdown-b ul.dropdown-menu li:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .dropdown-b ul.dropdown-menu li ~ li { border-top: 1px solid #e9e8e6 !important; }
   .dropdown-b ul.dropdown-menu li a { padding: 5px 10px; }
   .dropdown-b ul.dropdown-menu li .label-b { float: left; margin: 0; }
   .dropdown-b ul.dropdown-menu li .label-b + span { float: right; margin: 0; color: #aaa; font-size: 11px; line-height: 25px; }

.dropdown-calendar { position: absolute; top: 100%; left: 50%; z-index: 100; width: 471px; margin: 10px 0 0 -120px !important; padding: 6px !important; border: none; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); }
.dropdown-calendar { -moz-border-radius: 0; -webkit-border-radius: 0; border-radius: 0; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }
.dropdown-calendar:before { position: absolute; top: 5px; bottom: 5px; left: 5px; right: 5px; z-index: -1; border: 1px solid #b8b5b6; background: #f9f7f5; content: ' '; }
.dropdown-calendar:after { position: absolute; top: -5px; left: 50%; width: 10px; height: 11px; margin: 0 0 0 -112px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat; content: ' '; }
 .dropdown-calendar .inner { display: table; table-layout: fixed; width: 100%; margin: 0; }
  .dropdown-calendar .inner .col { display: table-cell; padding: 0; border-left: 1px solid #e9e8e6; }
   .dropdown-calendar table.calendar { display: table; border-collapse: collapse; width: 100%; padding: 0; border-spacing: 0; }
    .dropdown-calendar table.calendar th,
    .dropdown-calendar table.calendar td { font-size: 13px; font-weight: normal; line-height: 20px; text-align: center; vertical-align: middle; }
    .dropdown-calendar table.calendar tr.header th { padding: 10px; border-bottom: 1px solid #e9e8e6; background: #fff; font-weight: normal; line-height: 20px; }
    .dropdown-calendar table.calendar th.title { color: #5e676c; font-size: 14px; text-align: left; }
    .dropdown-calendar table.calendar th.action { font-size: 11px; text-align: right; }
    .dropdown-calendar table.calendar tr.nav th,
    .dropdown-calendar table.calendar tr.subnav th { padding: 0; border-bottom: 1px solid #e9e8e6; }
     .dropdown-calendar table.calendar tr.nav th a,
     .dropdown-calendar table.calendar tr.subnav th a { display: block; width: 100%; height: 30px; line-height: 30px;  }
    .dropdown-calendar table.calendar th.prev { width: 38.25px; border-right: 1px solid #e9e8e6; }
    .dropdown-calendar table.calendar tr.subnav th.switch { text-align: left; }
    .dropdown-calendar table.calendar th.next { width: 38.25px; border-left: 1px solid #e9e8e6; }
     .dropdown-calendar table.calendar th.switch a { position: relative; padding: 0 20px; color: #a09696 }
     .dropdown-calendar table.calendar tr.subnav th.switch a { padding-left: 10px; }
     .dropdown-calendar table.calendar th.switch a:before { position: absolute; top: 50%; right: 6px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
     .dropdown-calendar table.calendar th.prev a,
     .dropdown-calendar table.calendar th.next a { color: #ccc; }
     .dropdown-calendar table.calendar th.prev a:hover,
     .dropdown-calendar table.calendar th.switch a:hover,
     .dropdown-calendar table.calendar th.next a:hover { color: #666; }
    .dropdown-calendar table.calendar tr.pickdate th a { display: block; width: 100%; height: 24px; padding: 105px 10px 65px 10px; background: url(../img/ico-calendar-a.png) 50% 50px no-repeat; font-size: 11px; font-weight: normal; line-height: 24px; }
    .dropdown-calendar table.calendar th.dow,
    .dropdown-calendar table.calendar td.day { width: 30px; padding: 3px 0; }
    .dropdown-calendar table.calendar th.dow { padding-top: 11px; font-size: 11px; }
    .dropdown-calendar table.calendar th.dow:first-child,
    .dropdown-calendar table.calendar td.day:first-child { padding-left: 12px; }
    .dropdown-calendar table.calendar th.dow:last-child,
    .dropdown-calendar table.calendar td.day:last-child { padding-right: 12px; }
    .dropdown-calendar table.calendar th.dow.last-child,
    .dropdown-calendar table.calendar td.day.last-child { padding-right: 12px; }
    .dropdown-calendar table.calendar tr:last-child td.day { padding-bottom: 11px; }
    .dropdown-calendar table.calendar tr.last-child td.day { padding-bottom: 11px; }
     .dropdown-calendar table.calendar th.dow a { display: inline-block; width: 24px; height: 20px; color: #a09696; line-height: 20px; }
     .dropdown-calendar table.calendar td a { display: inline-block; position: relative; width: 24px; height: 20px; color: #5e676c; line-height: 20px; }
     .dropdown-calendar table.calendar td a { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
     .dropdown-calendar table.calendar td a:hover { background: #eee; }
     .dropdown-calendar table.calendar td.active a { background: #dad5d4; }
     .dropdown-calendar table.calendar td.old a,
     .dropdown-calendar table.calendar td.new a { color: #bbb; }
	/* unpicked */
	.dropdown-calendar table.calendar tr.nav { display: none; }
	/*.dropdown-calendar table.calendar tr.subnav { display: table-row; }*/
	/*.dropdown-calendar table.calendar tr.pickdate { display: table-row; }*/
	.dropdown-calendar table.calendar tr.dows { display: none; }
	.dropdown-calendar table.calendar tbody tr { display: none; }

	/* picked */
	.dropdown-calendar table.calendar.picked tr.nav { display: table-row; }
	.dropdown-calendar table.calendar.picked tr.subnav { display: none; }
	.dropdown-calendar table.calendar.picked tr.pickdate { display: none; }
	.dropdown-calendar table.calendar.picked tr.dows { display: table-row; }
	.dropdown-calendar table.calendar.picked tbody tr { display: table-row; }


.edit-a { width: 28px; height: 28px; }
 .edit-a a { display: block; position: relative; width: 100%; height: 100%; background: transparent; text-indent: 105%; white-space: nowrap; }
 .edit-a a { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
 .edit-a a:before { position: absolute; top: 50%; left: 50%; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0 -7px; background: url(../img/icons-sprite-a.png) 0 -120px no-repeat; content: ' '; }
 .edit-a a:hover { background: url(../img/overlay-b.png); background: rgba(160,150,150,0.35); }
  .edit-a a span { display: none; position: absolute; top: 50%; right: 100%; float: none; width: auto !important; height: 28px; margin: -14px 10px 0 0 !important; padding: 0 10px !important; border: 1px solid #e4e2e0; background: #fff; color: #666; font-size: 12px; font-weight: normal; line-height: 26px; text-indent: 0; white-space: nowrap; }
  .edit-a a:hover span { display: block; }
  .edit-a a span { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px; }
  .edit-a a span:before { position: absolute; top: 50%; right: -4px; width: 4px; height: 8px; margin: -4px 0 0; background: url(../img/edit-a-arrow.png) 0 50% no-repeat; content: ' '; }

.filter-a { }
 .filter-a .block { margin-bottom: 10px; }
 .filter-a .block.date a + a { border-top: none; }
 .filter-a .checklist { border: 1px solid #ddd9d7; background: #f9f7f5; }
  .filter-a .checklist .head { width: 100%; margin: 0; padding: 4px 15px; background: #fff; }
  .filter-a .checklist .head:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
   .filter-a .checklist .head h3 { float: left; margin: 0; color: #5e676c; font-size: 12px; font-weight: normal; line-height: 20px; }
  .filter-a .checklist .head p.all, .filter-a .checklist .head .all { float: right; margin: 0; font-size: 11px; font-weight: normal; line-height: 20px; }
   .filter-a .checklist .head p.all a, .filter-a .checklist .head .all a  { color: #aaa; margin-left: 4px;}
  .filter-a .checklist .fields { width: 100%; margin: 0; }
   .filter-a .checklist .fields p { margin: 0; padding: 12px 15px; border-top: 1px solid #e9e8e6; }
   .filter-a .checklist .fields .wrap { display: table; table-layout: fixed; width: 100%; }
    .filter-a .checklist .fields .wrap p { display: table-cell; }
    .filter-a .checklist .fields .wrap p ~ p { border-left: 1px solid #e9e8e6; }
    .filter-a .checklist .fields input[type=checkbox] { display: inline-block; vertical-align: middle; margin: -3px 5px 0 0; }
    .filter-a .checklist .fields label { display: inline; color: #666; font-size: 12px; font-weight: normal; line-height: 21px; }
    :root .filter-a .checklist .fields input[type=checkbox] { position: absolute; clip: rect(0,0,0,0); }
    :root .filter-a .checklist .fields input[type=checkbox] + label { display: inline-block; position: relative; padding-left: 27px; line-height: 17px; vertical-align: top; }
    :root .filter-a .checklist .fields input[type=checkbox] + label:before { position: absolute; top: 0; left: 0; content: ' '; width: 17px; height: 17px; margin: 0; background: url(../img/checkbox-a.png) no-repeat; vertical-align: top; }
    :root .filter-a .checklist .fields input[type=checkbox]:checked + label:before { background-position: 0 -50px; }

p.info-a { margin: 0 0 20px; padding-left: 12px; border-left: 1px solid #e2dad3; color: #777781; font-size: 1.2em; font-weight: normal; font-style: italic; line-height: 1.2; }

.issues-a { border: 1px solid #ddd9d7; background: #fff; }
 .issues-a .item { padding: 10px; }
 .issues-a .item:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
 .issues-a .item + .item { border-top: 1px solid #e9e9e9; }
  .issues-a .item p.type { float: right; margin: 0 -20px 5px 0 }
  .issues-a .item figure { float: left;  max-width: 70px; margin: 0; }
   .issues-a .item figure a { display: block; padding: 4px; border: 1px solid #dbdbdb; background: #f9f7f5; }
   .issues-a .item figure img { display: block; max-width: 60px; margin: 0; }
  .issues-a .item figure ~ * { margin-left: 80px !important; }
  .issues-a .item h3 { margin: 0 0 7px; }
  .issues-a .item p { margin: 0 0 7px; color: #aaa; font-size: 1.1em; line-height: 1.2; }
  .issues-a .item p.number { color: #5e676c; font-size: 1.2em; }
 .issues-a p.more { margin: 0; border-top: 1px solid #e9e9e9; font-size: 1.2em; font-weight: 600; line-height: 1.666; text-align: center; }
  .issues-a p.more a { display: block; padding: 10px; background: #f9f7f5; color: #9f9696; }
  .issues-a p.more a:hover,
  .issues-a p.more a:focus,
  .issues-a p.more a:active { background: #f4f2f0; color: #938989; }

/*.issues-b { border: 1px solid #ddd9d7; background: #fff; }*/
 .issues-b .item { position: relative; z-index: 1; margin: 0; }
 .issues-b .item:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
 .issues-b .item + .item { border-top: 1px solid #e9e9e9; }
  .issues-b .item header { float: right; width: 25%; margin: 0; padding: 10px; }
  /*.issues-b .item header { box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }*/
  .issues-b .item header:before { position: absolute; top: 0; bottom: 0; right: 0; left: 75%; z-index: -1; border-left: 1px solid #e9e9e9; background: #f9f7f5; content: ' '; }
   .issues-b .item header figure { position: relative; float: left; width: 26px; height: 26px; overflow: hidden; margin: 0; border: 2px solid #dad5d4; background: #dad5d4; }
   .issues-b .item header figure { -moz-border-radius: 13px; -webkit-border-radius: 13px; border-radius: 13px;  }
    .issues-b .item header figure img { display: block; max-width: 22px; margin: 0; }
   .issues-b .item header figure ~ * { margin-left: 36px !important; }
   .issues-b .item header h2 { margin: 6px 0 10px; font-size: 1.2em; font-weight: 600; line-height: 1.2; }
    .issues-b .item header h2 a { color: #a09696; }
   .issues-b .item header p { margin: 0 0 5px; font-size: 1.2em; line-height: 1.2; }
   .issues-b .item header p.comments { font-size: 1.1em; line-height: 1.2; }
  .issues-b .item .content { float: left; width: 75%; margin: 0; padding: 10px; }
   .issues-b .item .content .utils { float: right; min-width: 90px; margin: 0 -10px 0 15px; }
    .issues-b .item .content .utils p.type { margin: 0 0 5px; text-align: right; }
     .issues-b .item .content .utils .label-a:after { display: none; }
    .issues-b .item .content .utils p.date { padding: 0 10px 0 0; color: #aaa; }
     .issues-b .item .content .utils p.date span,
     .issues-b .item .content .utils p.date a { display: block; margin: 0 0 5px; font-size: 11px; line-height: 1.2; }
     .issues-b .item .content .utils p.date span:before,
     .issues-b .item .content .utils p.date a:before { display: inline-block; width: 9px; height: 9px; overflow: hidden; margin: -2px 5px 0 0; background: url(../img/date-icons-a.png) -10001px -10001px no-repeat; content: ' '; }
     .issues-b .item .content .utils p.date span.start:before,
     .issues-b .item .content .utils p.date a.start:before { background-position: 0 0; }
     .issues-b .item .content .utils p.date span.end:before,
     .issues-b .item .content .utils p.date a.end:before { background-position: 0 -18px; }
   .issues-b .item .content figure { float: left; max-width: 70px; margin: 0; }
    .issues-b .item .content figure a { display: block; padding: 4px; border: 1px solid #dbdbdb; background: #f9f7f5; }
    .issues-b .item .content figure img { display: block; max-width: 60px; margin: 0; }
   .issues-b .item .content figure ~ * { margin-left: 80px !important; }
   .issues-b .item .content h3 { margin: 0 0 7px; }
   .issues-b .item .content p { margin: 0 0 7px; color: #aaa; font-size: 1.1em; line-height: 1.2; }
   .issues-b .item .content p.number { color: #5e676c; font-size: 1.2em; }

.issues-list-a { }
 .issues-list-a > header { position: relative; z-index: 100; min-height: 40px; margin: 0 0 15px; }
 .issues-list-a > header:before { position: absolute; top: 0; bottom: -1px; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #cecbc8; background: #f9f7f5; content: ' '; }
 .issues-list-a > header:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .issues-list-a > header p.reset { float: left; width: 23.0769%; margin: 0; border-left: 1px solid #e9e8e6; }
  .issues-list-a > header div.reset, header.tools-header div.reset { float: left; width: 23.0769%; margin: 0; border-left: 1px solid #e9e8e6; padding: 6px 12px;}
   .issues-list-a > header p.reset a { display: block; position: relative; height: 40px; margin: 0; padding: 0 10px 0 24px; color: #999; font-size: 13px; font-weight: normal; line-height: 40px; outline: none; }
   .issues-list-a > header p.reset a:before { position: absolute; top: 50%; left: 5px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -260px no-repeat; content: ' '; }
  .issues-list-a > header h2 { float: left; margin: 0; padding: 10px; border-left: 1px solid #e9e8e6; color: #5e666c; font-size: 13px; font-weight: normal; line-height: 20px; }
  .issues-list-a > header .utils { float: right; margin: 0; }
   .issues-list-a > header .utils > ul { list-style: none; float: left; margin: 0; padding: 0; border-right: 1px solid #e9e8e6; }
    .issues-list-a > header .utils > ul li { float: left; margin: 0; border-left: 1px solid #e9e8e6; }
     .issues-list-a > header .utils > ul li a { position: relative; float: left; width: 40px; height: 40px; overflow: hidden; text-indent: 105%; white-space: nowrap; }
     .issues-list-a > header .utils > ul li.active a { background: #f0edeb; }
     .issues-list-a > header .utils > ul li a:before { position: absolute; top: 50%; left: 50%; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0 -7px; background: url(../img/icons-sprite-a.png) -10001px -10001px no-repeat; content: ' '; }
     .issues-list-a > header .utils > ul li.list a:before { background-position: 1px -220px; }
     .issues-list-a > header .utils > ul li.grid a:before { background-position: 1px -240px; }
     .issues-list-a > header .utils > ul li.pins a:before { background-position: 0 -20px; }
   .issues-list-a > header .utils .sort { float: left; margin: 0; min-width: 167px; }
    .issues-list-a > header .utils .sort > a { float: left; border: none; font-size: 13px; }
    .issues-list-a > header .utils .sort > a:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
 .tools-header { position: relative; z-index: 100; min-height: 40px; margin: 0 0 15px; }
 .tools-header:before { position: absolute; top: 0; bottom: -1px; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #cecbc8; background: #f9f7f5; content: ' '; }
 .tools-header:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .tools-header p.reset { float: left; width: 23.0769%; margin: 0; border-left: 1px solid #e9e8e6; }
  .tools-header div.reset { float: left; width: 23.0769%; margin: 0; border-left: 1px solid #e9e8e6; padding: 6px 12px;}
   .tools-header p.reset a { display: block; position: relative; height: 40px; margin: 0; padding: 0 10px 0 24px; color: #999; font-size: 13px; font-weight: normal; line-height: 40px; outline: none; }
   .tools-header p.reset a:before { position: absolute; top: 50%; left: 5px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -260px no-repeat; content: ' '; }
  .tools-header h2 { float: left; margin: 0; padding: 10px; border-left: 1px solid #e9e8e6; color: #5e666c; font-size: 13px; font-weight: normal; line-height: 20px; }
  .tools-header .utils { float: right; margin: 0; }
   .tools-header .utils > ul { list-style: none; float: left; margin: 0; padding: 0; border-right: 1px solid #e9e8e6; }
    .tools-header .utils > ul li { float: left; margin: 0; border-left: 1px solid #e9e8e6; }
     .tools-header .utils > ul li a.absolute { position: relative; float: left; width: 40px; height: 40px; overflow: hidden; text-indent: 105%; white-space: nowrap; }
     .tools-header .utils > ul li.active a { background: #f0edeb; }
     .tools-header .utils > ul li a.absolute:before { position: absolute; top: 50%; left: 50%; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0 -7px; background: url(../img/icons-sprite-a.png) -10001px -10001px no-repeat; content: ' '; }
     .tools-header .utils > ul li.list a:before { background-position: 1px -220px; }
     .tools-header .utils > ul li.grid a:before { background-position: 1px -240px; }
     .tools-header .utils > ul li.pins a:before { background-position: 0 -20px; }
   .tools-header .utils .sort { float: left; margin: 0; min-width: 167px; }
    .tools-header .utils .sort > a { float: left; border: none; font-size: 13px; }
    .tools-header .utils .sort > a:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
.map-a { position: relative; border: 1px solid #ddd9d7; }
 .map-a img, .map-a iframe { display: block; width: 100%; height: auto; margin: 0; }
 .map-a .pin { position: absolute; top: 50%; left: 50%; z-index: 5; width: 34px; height: 54px; overflow: hidden; margin: -27px 0 0 -17px; padding: 0; background: url(../img/marker-a.png) 50% 50% no-repeat; text-indent: 105%; white-space: nowrap; }
 .map-a .edit { /*display: none;*/ position: absolute; top: 0; left: 0; right: 0; z-index: 10; margin: 0; padding: 10px 44px 10px 15px; background: url(../img/overlay-a.png); background: rgba(255,255,255,0.8); color: #5e676c; font-size: 12px; line-height: 1.2; }
 .map-a:hover .edit { display: block; }
 .map-a .edit .edit-a { position: absolute; top: 50%; right: 10px; z-index: 10; margin: -14px 0 0; }

.map-b { position: relative; width: 940px; margin: 0 auto 20px; }
 .map-b .steps-a { position: absolute; z-index: 2; top: 0; left: 0; right: 0; margin: 0; padding: 0; }
 .map-b .wrap { position: relative; z-index: 1; width: 100%; height: 500px; overflow: hidden; margin: 0; }
  .map-b .wrap > img { display: block; min-width: 1280px; max-width: none; height: auto; margin: 0; }
  .map-b .wrap .pin { display: block; position: absolute; top: 0; left: 0; width: 32px; height: 52px; overflow: hidden; margin: 0; padding: 0; background: url(../img/pins-sprite-a.png) -10001px -10001px no-repeat; text-indent: 105%; white-space: nowrap; }
  .map-b .wrap .pin.a { background-position: 0 0; }
  .map-b .wrap .pin.b { background-position: -50px 0; }
  .map-b .wrap .pin.c { background-position: -100px 0; }
  .map-b .wrap .pin.d { background-position: -150px 0; }
  .map-b .wrap .pin.e { background-position: -200px 0; }
  .map-b .wrap .pin.f { background-position: -250px 0; }
  .map-b .wrap .pin.g { background-position: -300px 0; }
  .map-b .wrap .pin.h { background-position: -350px 0; }
  .map-b .wrap .pin.i { background-position: -400px 0; }
  .map-b .wrap .pin.j { background-position: -450px 0; }
  .map-b .wrap .pin.k { background-position: -500px 0; }
  .map-b .wrap .pin.l { background-position: -550px 0; }
 .map-b .wrap .location { display: none; position: absolute; z-index: 100; width: 350px; height: auto; margin: 5px 0 0 -175px; padding: 5px; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); }
 .map-b .wrap .location.on { display: block; }
  .map-b .wrap .location:before { position: absolute; top: -5px; left: 50%; width: 10px; height: 10px; margin: 0 0 0 -5px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat; content: ' '; }
  .map-b .wrap .location .inner { width: 100%; padding: 10px; background: #fff; }
  .map-b .wrap .location .inner:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
   .map-b .location p.type { float: right; margin: 0 -20px 5px 0 }
   .map-b .location figure { float: left; max-width: 70px; margin: 0; }
    .map-b .location figure a { display: block; padding: 4px; border: 1px solid #dbdbdb; background: #f9f7f5; }
     .map-b .location figure img { display: block; max-width: 60px; height: auto; margin: 0; }
   .map-b .location figure ~ * { margin-left: 80px !important; }
   .map-b .location h3 { margin: 0 0 7px; }
   .map-b .location p { margin: 0 0 7px; color: #aaa; font-size: 1.1em; line-height: 1.2; }
   .map-b .location p.number { color: #5e676c; font-size: 1.2em; }
 .map-b .utils { position: absolute; bottom: 0; left: 0; right: 0; z-index: 3; margin: 0; padding: 0; }
 .map-b .utils:before { position: absolute; top: 0; bottom: 0; left: -100%; right: -100%; z-index: -1; border-bottom: 1px solid #d3d2d0; background: url(../img/overlay-e.png); background: rgba(255,255,255,0.8); content: ' '; }
 .map-b .utils .inner { width: 940px; height: 40px; margin: 0 auto; }
 .map-b .utils .inner:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
  .map-b .utils .inner ul.connect-a { float: left; margin: 0; }
  .map-b .utils .inner p { float: right; margin: 0; }
   .map-b .utils .inner p a { display: block; position: relative; min-width: 220px; height: 40px; overflow: hidden; margin: 0; padding: 0 15px; border: 1px solid #ddd9d7; border-width: 0 1px; color: #9f9696; font-size: 12px; font-weight: 600; line-height: 40px; text-align: center; text-overflow: ellipsis; white-space: nowrap; outline: none; }

.map-c { position: relative; margin: 0 0 20px; border: 1px solid #ddd9d7; }
 .map-c .wrap { position: relative; z-index: 1; width: 100%; height: 460px; overflow: hidden; margin: 0; }
  .map-c .wrap > img { display: block; min-width: 100%; max-width: none; min-width: 100%; height: auto; margin: 0; }
  .map-c .wrap .pin { display: block; position: absolute; top: 0; left: 0; width: 32px; height: 52px; overflow: hidden; margin: 0; padding: 0; background: url(../img/pins-sprite-a.png) -10001px -10001px no-repeat; text-indent: 105%; white-space: nowrap; }
  .map-c .wrap .pin.a { background-position: 0 0; }
  .map-c .wrap .pin.b { background-position: -50px 0; }
  .map-c .wrap .pin.c { background-position: -100px 0; }
  .map-c .wrap .pin.d { background-position: -150px 0; }
  .map-c .wrap .pin.e { background-position: -200px 0; }
  .map-c .wrap .pin.f { background-position: -250px 0; }
  .map-c .wrap .pin.g { background-position: -300px 0; }
  .map-c .wrap .pin.h { background-position: -350px 0; }
  .map-c .wrap .pin.i { background-position: -400px 0; }
  .map-c .wrap .pin.j { background-position: -450px 0; }
  .map-c .wrap .pin.k { background-position: -500px 0; }
  .map-c .wrap .pin.l { background-position: -550px 0; }
 .map-c .wrap .location { display: none; position: absolute; z-index: 100; width: 210px; height: auto; margin: 5px 0 0 -175px; padding: 5px; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); color: #5e666c; }
 .map-c .wrap .location.on { display: block; }
  .map-c .wrap .location:before { position: absolute; top: -5px; left: 50%; width: 10px; height: 10px; margin: 0 0 0 -5px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat; content: ' '; }
  .map-c .wrap .location .inner { width: 100%; padding: 12px 10px; background: #fff; }
  .map-c .wrap .location .inner:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
   .map-c .location p { margin: 7px 0 0; font-size: 1.1em; line-height: 1.2; }
   .map-c .location p:first-child { margin-top: 0; }


.photo-a { position: relative; margin: 0 0 6px; padding: 4px; border: 1px solid #ddd9d7; background: #fff; }
  .photo-a img { display: block; width: 100%; height: auto; margin: 0; }
  .photo-a .label-a { position: absolute; top: 16px; right: -11px; z-index: 20; margin: 0; }
  .photo-a .edit { display: none; position: absolute; bottom: 4px; left: 4px; right: 4px; z-index: 10; margin: 0; padding: 13px 44px 13px 15px; background: url(../img/overlay-a.png); background: rgba(255,255,255,0.8); color: #5e676c; font-size: 12px; line-height: 1.2; }
  .photo-a:hover .edit { display: block; }
   .photo-a .edit .edit-a { position: absolute; top: 50%; right: 10px; z-index: 10; margin: -14px 0 0; }

.steps-a { position: relative; padding-bottom: 1px; }
.steps-a:before { position: absolute; top: 0; bottom: 1px; left: -100%; right: -100%; z-index: -1; background: url(../img/overlay-d.png); background: rgba(242,238,235,0.94); content: ' '; }
.steps-a:after { position: absolute; bottom: 0; left: -100%; right: -100%; z-index: -1; height: 1px; background: url(../img/overlay-e.png); background: rgba(0,0,0,0.15); content: ' '; }
 .steps-a ol { display: table; width: 940px; list-style: none; margin: 0 auto; padding: 0; }
  .steps-a ol li { display: table-cell; position: relative; height: 120px; margin: 0; padding: 10px 10px 10px 100px; background: url(../img/steps-a-icons.png) -10001px -10001px no-repeat; color: #5e666c; font-size: 12px; line-height: 1.2; vertical-align: middle; }
  .steps-a ol li.i1 { background-position: 0 50%; }
  .steps-a ol li.i2 { background-position: -800px 50%; }
  .steps-a ol li.i3 { background-position: -1600px 50%; }
  .steps-a ol li.i4 { padding: 10px 0 10px 84px; background-position: -2400px 50%; }
   .steps-a ol li.i4 span { display: none; }

.user-a { border: 1px solid #efefef; border-width: 0 1px; }
 .user-a a { display: block; position: relative; height: 60px; padding: 17px 34px 17px 17px; color: #666; }
 .user-a a:before { position: absolute; top: 50%; right: 10px; width: 14px; height: 14px; overflow: hidden; margin: -7px 0 0; background: url(../img/icons-sprite-a.png) 0 -180px no-repeat; content: ' '; }
 .user-a a:after { display: block; overflow: hidden; clear: both; content: ""; width: 100%; height: 0; }
 .user-a a:hover, .user-a a:focus, .user-a a:active, .user-a.active a { background: #f9f7f5; color: #000; }
  .user-a a figure { position: relative; float: left; width: 26px; height: 26px; overflow: hidden; margin: 0; border: 2px solid #dad5d4; background: #dad5d4; }
  .user-a a figure { -moz-border-radius: 13px; -webkit-border-radius: 13px; border-radius: 13px;  }
   .user-a a figure img { display: block; max-width: 22px; margin: 0; }
  .user-a a figure ~ * { margin-left: 36px !important; }
  .user-a a p { margin: 0; font-size: 1.4em; font-weight: 600; line-height: 26px; }

/*! labels ------ */

.label-a { display: inline-block; position: relative; height: 25px; margin-left: 10px; padding: 0 10px; border: none; background: url(../img/labels-sprite-a.png) 0 -500px repeat-x; color: #fff !important; font: bold 11px/25px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-align: center; }
.label-a:before { position: absolute; top: 0; left: -10px; width: 10px; height: 25px; background: url(../img/labels-sprite-a.png) 0 -525px repeat-x; content: ' '; }
.label-a:after { position: absolute; bottom: -5px; right: 0; width: 10px; height: 5px; background: url(../img/labels-sprite-a.png) 100% -525px repeat-x; content: ' '; }

.label-a-print { display: inline-block; position: relative; height: 25px; margin-left: 10px; padding: 0 10px; border: none; background:  0 -500px repeat-x; color: #5e676c !important; font: bold 11px/25px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-align: center; }
.label-a-print:before { position: absolute; top: 0; left: -10px; width: 10px; height: 25px; background:  0 -525px repeat-x; content: ' '; }
.label-a-print:after { position: absolute; bottom: -5px; right: 0; width: 10px; height: 5px; background:  100% -525px repeat-x; content: ' '; }


.label-a.green { background-position: 0 0; }
.label-a.green:before { background-position: 0 -25px; }
.label-a.green:after { background-position: 100% -25px; }

.label-a.resolved { background-position: 0 0; }
.label-a.resolved:before { background-position: 0 -25px; }
.label-a.resolved:after { background-position: 100% -25px; }

.label-a.orange { background-position: 0 -50px; }
.label-a.orange:before { background-position: 0 -75px; }
.label-a.orange:after { background-position: 100% -75px; }

.label-a.open { background-position: 0 -50px; }
.label-a.open:before { background-position: 0 -75px; }
.label-a.open:after { background-position: 100% -75px; }

.label-a.transferred { background-position: 0 -300px; }
.label-a.transferred:before { background-position: 0 -325px; }
.label-a.transferred:after { background-position: 100% -325px; }

.label-a.blue { background-position: 0 -100px; }
.label-a.blue:before { background-position: 0 -125px; }
.label-a.blue:after { background-position: 100% -125px; }

.label-a.planned { background-position: 0 -100px; }
.label-a.planned:before { background-position: 0 -125px; }
.label-a.planned:after { background-position: 100% -125px; }

.label-a.gold { background-position: 0 -150px; }
.label-a.gold:before { background-position: 0 -175px; }
.label-a.gold:after { background-position: 100% -175px; }

.label-a.graphite { background-position: 0 -200px; }
.label-a.graphite:before { background-position: 0 -225px; }
.label-a.graphite:after { background-position: 100% -225px; }


.label-a.in_progress { background-position: 0 -200px; }
.label-a.in_progress:before { background-position: 0 -225px; }
.label-a.in_progress:after { background-position: 100% -225px; }

.label-a.purple { background-position: 0 -250px; }
.label-a.purple:before { background-position: 0 -275px; }
.label-a.purple:after { background-position: 100% -275px; }

.label-a.pink { background-position: 0 -300px; }
.label-a.pink:before { background-position: 0 -325px; }
.label-a.pink:after { background-position: 100% -325px; }

.label-a.azure { background-position: 0 -350px; }
.label-a.azure:before { background-position: 0 -375px; }
.label-a.azure:after { background-position: 100% -375px; }

.label-a.army { background-position: 0 -400px; }
.label-a.army:before { background-position: 0 -425px; }
.label-a.army:after { background-position: 100% -425px; }

.label-a.rejected { background-position: 0 -400px; }
.label-a.rejected:before { background-position: 0 -425px; }
.label-a.rejected:after { background-position: 100% -425px; }

.label-a.beige { background-position: 0 -450px; }
.label-a.beige:before { background-position: 0 -475px; }
.label-a.beige:after { background-position: 100% -475px; }

.label-a.grey { background-position: 0 -500px; }
.label-a.grey:before { background-position: 0 -525px; }
.label-a.grey:after { background-position: 100% -525px; }

.label-a.deleted { background-position: 0 -500px; }
.label-a.deleted:before { background-position: 0 -525px; }
.label-a.deleted:after { background-position: 100% -525px; }

.label-b { display: inline-block; position: relative; height: 25px; padding: 0 12px; border: none; background: #a09696; color: #fff !important; font: bold 11px/25px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-align: center; vertical-align: top; }
.dropdown-menu > li > a.label-b { display: inline-block; position: relative; height: 25px; padding: 0 12px; font: bold 11px/25px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-align: center; vertical-align: top; }
.label-b,
.dropdown-menu > li > a.label-b { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
.label-b:hover, .label-b:focus,
.dropdown-menu > li > a.label-b:hover,
.dropdown-menu > li > a.label-b:focus { background-image: none; filter: none; }
.label-b.white,
.dropdown-menu > li > a.label-b.white:hover,
.dropdown-menu > li > a.label-b.white:focus { border: 1px solid #e4e2e0; background-color: #fff; color: #5e676c !important; font-weight: normal !important; line-height: 23px !important; }
.label-b.green,
.dropdown-menu > li > a.label-b.green:hover,
.dropdown-menu > li > a.label-b.green:focus { background-color: #89cc1f; }
.label-b.orange,
.dropdown-menu > li > a.label-b.orange:hover,
.dropdown-menu > li > a.label-b.orange:focus { background-color: #f77448; }
.label-b.blue,
.dropdown-menu > li > a.label-b.blue:hover,
.dropdown-menu > li > a.label-b.blue:focus { background-color: #5dc9e6; }
.label-b.gold,
.dropdown-menu > li > a.label-b.gold:hover,
.dropdown-menu > li > a.label-b.gold:focus { background-color: #e3b43e; }
.label-b.graphite,
.dropdown-menu > li > a.label-b.graphite:hover,
.dropdown-menu > li > a.label-b.graphite:focus { background-color: #6b98b5; }
.label-b.purple,
.dropdown-menu > li > a.label-b.purple:hover,
.dropdown-menu > li > a.label-b.purple:focus { background-color: #a286c0; }
.label-b.pink,
.dropdown-menu > li > a.label-b.pink:hover,
.dropdown-menu > li > a.label-b.pink:focus { background-color: #e78fa5; }
.label-b.azure,
.dropdown-menu > li > a.label-b.azure:hover,
.dropdown-menu > li > a.label-b.azure:focus { background-color: #00c5b1; }
.label-b.army,
.dropdown-menu > li > a.label-b.army:hover,
.dropdown-menu > li > a.label-b.army:focus { background-color: #aaaa5a; }
.label-b.beige,
.dropdown-menu > li > a.label-b.beige:hover,
.dropdown-menu > li > a.label-b.beige:focus { background-color: #dedccf; }
.label-b.grey,
.dropdown-menu > li > a.label-b.grey:hover,
.dropdown-menu > li > a.label-b.grey:focus { background-color: #a09696; }

/*! forms -------- */
input[type=number],
input[type=text],
input[type=password],
input[type=email] { position: relative; height: 27px; margin: 0; padding: 0 9px; border: 1px solid #e3dfdc; background: #f2eeeb; color: #666; font-size: 12px; font-weight: 600; }


input[type=number],
input[type=text],
input[type=password],
input[type=email] { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px; -moz-transition: none; -webkit-transition: none; -o-transition: none; -ms-transition: none; transition: none;  }

input[type=text].ico,
input[type=password].ico,
input[type=email].ico { padding-left: 30px; }

input[type=text].ico.email,
input[type=password].ico.email,
input[type=email].ico.email { background-image: url(../img/ico-mail-a.png); background-position: 10px 8px; background-repeat: no-repeat; }

input[type=text].ico.phone,
input[type=password].ico.phone,
input[type=email].ico.phone { background-image: url(../img/ico-tel-a.png); background-position: 10px 8px; background-repeat: no-repeat; }

input[type=text].ico.address,
input[type=password].ico.address,
input[type=email].ico.address { background-image: url(../img/ico-address-a.png); background-position: 10px 8px; background-repeat: no-repeat; }

textarea { position: relative; margin: 0; padding: 9px; border: 1px solid #e3dfdc; background: #f2eeeb; color: #666; font-size: 12px; font-weight: 600; line-height: 17px; }
textarea { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px; -moz-transition: none; -webkit-transition: none; -o-transition: none; -ms-transition: none; transition: none;  }

span.select-a { }
.js span.select-a { display: inline-block; position: relative; height: 27px; border: none; background: #f2eeeb url(../img/select-a.png) 100% 50% no-repeat; color: #666; font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; vertical-align: top; }
html.js* span.select-a { display: inline; }
.js span.select-a { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
 .js span.select-a span { display: block; position: absolute; left: 10px; top: 0; bottom: 0; right: 30px; overflow: hidden; height: 100%; text-overflow: ellipsis; white-space: nowrap;}
 .js span.select-a select { position: relative; z-index: 2; float: left; width: 100%; height: 100%; margin: 0 !important; border: none; opacity: 0; filter: alpha(opacity=0); }
  .js span.select-a select option { padding: 0 9px; }
span.select-a.green { background-color: #89cc1f; color: #fff; }
span.select-a.orange { background-color: #f77448; color: #fff; }
span.select-a.blue { background-color: #5dc9e6; color: #fff; }
span.select-a.gold { background-color: #e3b43e; color: #fff; }
span.select-a.graphite { background-color: #6b98b5; color: #fff; }
span.select-a.purple { background-color: #a286c0; color: #fff; }
span.select-a.pink { background-color: #e78fa5; color: #fff; }
span.select-a.azure { background-color: #00c5b1; color: #fff; }
span.select-a.army { background-color: #aaaa5a; color: #fff; }
span.select-a.beige { background-color: #dedccf; color: #fff; }
span.select-a.grey { background-color: #a09696; color: #fff; }


/*! buttons ------- */

.button-a { display: inline-block; position: relative; height: 27px; padding: 0 10px; border: none; background-color: #a09696; color: #fff; font: 600 12px/27px 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; text-decoration: none !important; text-align: center; outline: none; }
.button-a { -moz-border-radius: 3px; -webkit-border-radius: 3px; border-radius: 3px;  }
.button-a:hover { background-color: #948989; color: #fff; }
.button-a.button-export {background-color: #EBEBEB;}
.button-a.button-export:hover {background-color: #D5D5D5;}
.button-a + .button-a { margin-left: 7px; }

.button-a.clear { background-color: #f2eeeb; color: #acaaa8; }
.button-a.clear:hover { background-color: #e8e1dc; color: #999; }

.button-a.orange { background-color: #fa7251; color: #fff; }
.button-a.orange:hover { background-color: #f95e38; color: #fff; }

.button-a.green { background-color: #89cc1f; color: #fff; }
.button-a.green:hover { background-color: #41CC15; color: #fff; }


.button-a.red { background-color: #FD1515; color: #fff; }
.button-a.red:hover { background-color: #C30E0E; color: #fff; }

.button-a.orange-soft { background-color: #fa9f89; color: #fff; }
.button-a.orange-soft:hover { background-color: #f95e38; color: #fff; }

.button-a.large { height: 37px; padding: 0 20px; font-size: 13px; line-height: 37px; text-decoration: none !important; }

.button-a.small { height: 19px; padding: 0 6px; font-size: 10px; line-height: 19px; text-decoration: none !important; }

.button-a.light {
  background-color: #DCD5D1;
  color: #A0A0A0;
}

.button-a:hover img.ico.default {
  display: none;
}

.button-a img.ico.default {
  display: inline;
}

.button-a:hover img.ico.hover {
  display: inline;
}

.button-a img.ico.hover {
  display: none;
}

.button-a.light:hover {
  background-color: #a09696;
  color: white;
}

.button-a.light i {
    font-size: 15px;
    vertical-align: sub;
}

.button-a.multi-lines {
    height: inherit;
    line-height: 15px;
    padding: 7px 10px;
}

/*! helpers ------- */

.offset { position: absolute !important; left: -10001px !important; overflow: hidden !important; }

/*! responsive ------- */
@media (min-width: 980px) {

.modal.modal-helper {
    width: 76%;
    margin-left: -38%;
}

.modal.modal-helper button.close {
  font-size: 30px;
}
}
@media (min-width: 1200px) {

.modal.modal-helper {
    width: 60%;
    margin-left: -30%;
}

#top { width: 100%; max-width: 1600px; }

#top #logo img {
  width: 185px;
  height: 40px;
}

.map-b { width: 1170px; }
.map-b .steps-a ol,
.map-b .utils .inner { width: 1170px; }

} @media screen and (max-width: 980px) {

.adv-a { }
 .adv-a header h3 { padding-left: 10px; padding-right: 10px; font-size: 12px; }
 .adv-a header figure { width: 46px; }
  .adv-a header figure img { max-width: 36px; height: auto; }

.apps-a { }
 .apps-a ul { }
  .apps-a ul li { text-align: center; }
   .apps-a ul li a { padding: 65px 15px 15px; }
    .apps-a ul li a:before { top: 15px; left: 50%; margin: 0 0 0 -20px; }
    .apps-a ul li a span,
    .apps-a ul li a strong { margin: 0 auto; }

} @media (min-width: 768px) and (max-width: 979px) {
#top { width: 724px; }
#nav { float: none !important; margin-left: 0; *margin-left: 0; border: none; }
#nav.in { padding-bottom: 10px; }
  #nav ul li { border: none; }
   #nav ul li a, .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a { -moz-border-radius: none !important; -webkit-border-radius: none !important; border-radius: none !important; }
   #nav ul li.parent > a:hover, #nav ul li.parent > a:focus, #nav ul li.parent > a:active, #nav ul li.parent:hover > a { background: none; }
   #nav ul li.parent.active > a { background: #f9f7f5; }
   #nav ul li ul { display: none; position: relative; top: auto; left: auto; width: 100%; border: none; background: transparent; }
   .js #nav ul li:hover ul { display: none; }
    #nav ul li ul li { margin: 0 0 2px; border: none !important; }
     #nav ul li ul li a, #nav.nav-collapse .nav > li > a { margin: 0; }
#right-nav { float: none !important; margin-left: 0; *margin-left: 0; border: none; }
#right-nav.in { padding-bottom: 10px; }
  #right-nav ul li { border: none; }
   #right-nav ul li a, .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a { -moz-border-radius: none !important; -webkit-border-radius: none !important; border-radius: none !important; }
   #right-nav ul li.parent > a:hover, #right-nav ul li.parent > a:focus, #right-nav ul li.parent > a:active, #right-nav ul li.parent:hover > a { background: none; }
   #right-nav ul li.parent.active > a { background: #f9f7f5; }
   #right-nav ul li ul { display: none; position: relative; top: auto; left: auto; width: 100%; border: none; background: transparent; }
   .js #right-nav ul li:hover ul { display: none; }
    #right-nav ul li ul li { margin: 0 0 2px; border: none !important; }
     #right-nav ul li ul li a, #right-nav.nav-collapse .nav > li > a { margin: 0; }


 .addissue-a .block-issue .wrap.upload { }
  .addissue-a .block-issue .wrap.upload p.dragndrop { width: 100%; padding: 20px 0; }
  .addissue-a .block-issue .wrap.upload p.file { display: block; width: 100%; margin: 0; }

.map-b { width: 724px; }
.map-b .steps-a ol,
.map-b .utils .inner { width: 724px; }

} @media screen and (max-width: 768px) {

body { padding: 0 !important; }

#top { width: auto; }
#nav { float: none !important; margin-left: 0; *margin-left: 0; border: none; }
#nav.in { padding-bottom: 10px; }
 #nav ul li { border: none; }
  #nav ul li a, .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a { -moz-border-radius: none !important; -webkit-border-radius: none !important; border-radius: none !important; }
  #nav ul li.parent > a:hover, #nav ul li.parent > a:focus, #nav ul li.parent > a:active, #nav ul li.parent:hover > a { background: none; }
  #nav ul li.parent.active > a { background: #f9f7f5; }
  #nav ul li ul { display: none; position: relative; top: auto; left: auto; width: 100%; border: none; background: transparent; }
  .js #nav ul li:hover ul { display: none; }
   #nav ul li ul li { margin: 0 0 2px; border: none !important; }
    #nav ul li ul li a, #nav.nav-collapse .nav > li > a { margin: 0; }

#right-nav { float: none !important; margin-left: 0; *margin-left: 0; border: none; }
#right-nav.in { padding-bottom: 10px; }
 #right-nav ul li { border: none; }
  #right-nav ul li a, .nav-collapse .nav > li > a, .nav-collapse .dropdown-menu a { -moz-border-radius: none !important; -webkit-border-radius: none !important; border-radius: none !important; }
  #right-nav ul li.parent > a:hover, #right-nav ul li.parent > a:focus, #right-nav ul li.parent > a:active, #right-nav ul li.parent:hover > a { background: none; }
  #right-nav ul li.parent.active > a { background: #f9f7f5; }
  #right-nav ul li ul { display: none; position: relative; top: auto; left: auto; width: 100%; border: none; background: transparent; }
  .js #right-nav ul li:hover ul { display: none; }
   #right-nav ul li ul li { margin: 0 0 2px; border: none !important; }
    #right-nav ul li ul li a, #right-nav.nav-collapse .nav > li > a { margin: 0; }

.map-b { width: auto; margin-left: -15px; margin-right: -15px; }
.map-b .steps-a, .map-b .utils { padding-left: 15px; padding-right: 15px; }
.map-b .steps-a ol,
.map-b .utils .inner { width: 100%; }

.adv-a { }
 .adv-a header h3 { padding-left: 10px; padding-right: 10px; font-size: 12px; }
 .adv-a header figure { width: 46px; }
  .adv-a header figure img { max-width: 36px; height: auto; }

 .details-a header { margin: 0 0 15px; padding-left: 0; *padding-left: 0; }
 .details-a .images { margin-top: 0; }

.row-fluid [class*="span"] { margin-bottom: 30px; }

} @media screen and (max-width: 640px) {

#footer { text-align: center; }
 #footer .logo { display: block; margin: 0 0 15px; }
 #footer nav { display: block; margin: 0 0 15px; text-align: center; }
 #footer .two nav { float: none; }

  .steps-a ol li { height: 50px; padding: 10px 5px 10px 45px; color: #5e666c; font-size: 10px; line-height: 1.2; }
  .steps-a ol li.i1 { background-position: 0 50%; }
  .steps-a ol li.i2 { background-position: -800px 50%; }
  .steps-a ol li.i3 { background-position: -1600px 50%; }
  .steps-a ol li.i4 { padding: 10px 0 10px 40px; background-position: -2400px 50%; }

} @media screen and (max-width: 480px) {

 .addissue-a .block-issue .wrap.upload { }
  .addissue-a .block-issue .wrap.upload p.dragndrop { width: 100%; padding: 20px 0; }
  .addissue-a .block-issue .wrap.upload p.file { display: block; width: 100%; margin: 0; }

} @media screen and (max-width: 320px) {

 .addissue-a .apps-a ul { display: block; border: none; border: 1px solid #ddd9d7; }
  .addissue-a .apps-a ul li { display: block; border: none; }
  .addissue-a .apps-a ul li ~ li { border-top: 1px solid #ddd9d7; }

 .map-b .steps-a { padding-top: 4px; padding-bottom: 4px; }
 .map-b .utils { padding-left: 0; padding-right: 0; }
  .map-b .utils .connect-a { border: none; }
   .map-b .utils .inner p a { border-width: 0 0 0 1px; }

 .steps-a ol { }
  .steps-a ol li { display: table-row; height: auto; padding: 0 !important; background: none; }
   .steps-a ol li span { display: table-cell !important; height: 48px; padding: 4px 5px 4px 48px !important; vertical-align: middle; }
  .steps-a ol li.i1 span { background-position: 0 50%; }
  .steps-a ol li.i2 span { background-position: -800px 50%; }
  .steps-a ol li.i3 span { background-position: -1600px 50%; }
  .steps-a ol li.i4 span { background-position: -2400px 50%; /*font-size: 0; line-height: 0;*/ }

}

textarea:focus, .select-a.active {
    border: 1px solid rgba(82,168,236,0.8) !important;
    outline: 0 !important;
    outline: thin dotted \9 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6) !important;
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6) !important;
}

form {
  margin: 0;
}


#top.full-width{  z-index: 100 !important; }

.gm-style-cc {
  display: none;
}

.clear-form, .clear-form-disabled:hover, .button-a.clear:hover.submit-disable:hover {
  background-color: #f2eeeb;
  color: #acaaa8;
  cursor: default;
}

.ui-menu-item {
  cursor: pointer;
}

.ui-menu-item:hover {
  background-color: #f9f7f5;
}

.ui-menu-item:hover a {
  color: #5e666c;
}


.modal-body span.select-a {
  background-color: #fff;
}

.modal-body textarea {
  resize: none;
  background-color: #fff;
}

textarea.no-resize {
  resize: none;
}

.line-break {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.new-log {
  background-color: #D7EBE1;
}

.live-log {
  background-color: rgb(255, 255, 233);
}

#overlay-no-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2eeeb;
  background-size:20px 20px;
  filter:alpha(opacity=90);
  -moz-opacity:0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: 10000;
}

#overlay-no-loader.light {
  background-color: rgba(242, 238, 235, 0.71);
}

#overlay, .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  background-color: rgba(244, 244, 244, 0.35);
  background-size:20px 20px;
  filter:alpha(opacity=90);
  -moz-opacity:0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  background-image:url('../images/ajax-loader3.gif');
  background-repeat:no-repeat;
  background-position:center center;
  z-index: 10000;
}

.overlay-add-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2eeeb;
  background-size:20px 20px;
  filter:alpha(opacity=90);
  -moz-opacity:0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  z-index: 10000;
}

.overlay-add-picture .progress {
  width: 100%;
  position: absolute;
  top: 55%;
}


.comment-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2eeeb;
  background-size:20px 20px;
  filter:alpha(opacity=90);
  -moz-opacity:0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
  background-repeat:no-repeat;
  background-position:center center;
  z-index: 100;
}

.overlay.no-loader {
  background-image: none;
}

.overlay.text {
  background-image: none;
  background-color: rgba(249, 249, 249, 0.78);
  padding: 16px;
}

#big-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2eeeb;
  background-size:40px 40px;
  filter:alpha(opacity=90);
  -moz-opacity:0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  background-image:url('../images/ajax-loader3.gif');
  background-repeat:no-repeat;
  background-position:center center;
  z-index: 10000;
}

#who-is-online {
  position: relative;
}

.priority-icons-issue-filter {
  margin-right: 4px;
  margin-top: 4px;
  color: #5e676c;
  font-size: 14px;
}

.owner-issue-filter {
  font-size: 10px;
}

.icon-priority-a_low {
  display: none;
}

.icon-priority-j_middle, .icon-priority-middle {
  color: orange;
}

.icon-priority-z_high, .icon-priority-high {
  color: red;
}


.button-on {
  background-color: #93D37B;
}

.button-off {
  background-color: #D66065;
}

.icon-priority-edit {
  margin-top: 8px;
}


#issue-add-note {
  border-top: 1px solid #ddd9d7;
}
.issue-textarea {
  width: 80%; height: 35px; resize: none;
}
#add-picture-block {
  padding: 4px 0; 
  text-align: center; 
  background:rgba(255,255,255,0); 
  margin-top:12px;
}

#form-edit-pictures {

}

.picture-infos {
    position: absolute;
    top: 0;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.62);
    width: 100%;
    color: white;
    text-align: left;
    padding: 0 0 0 6px;
}

#form-edit-pictures .box {
  margin: 8px auto;
}

#form-edit-pictures .wrap.upload {
  margin: 0;
}

#form-edit-pictures .file {
  width: 100%;
}
#imageupload {
  cursor: pointer; 
  width: 100%;
}

#form-edit-pictures-submit, #form-edit-pictures-cancel {
  margin-bottom: 3px;
  z-index: 10000;
}

.picture-loader .imageupload {
  cursor: pointer; width: 100%; height: 100%;
}

#form-edit-pictures-cancel {
  margin-left: 7px;
}
#issue-priority-edit .input-icon {
  margin-top: 4px;
}
.gmnoprint img { 
  max-width: none;
  background-color: transparent;
}

.modal-body, .modal-body p {
  font-size: 12px;
}

.modal-body img {
  background-color: transparent;
}
/* {
  float: right;
}
 {
  float: right;
}
 {
  float: right;
}*/
.sub-field {
  background-color: #f7f5f3;
}

.sub-field.border-top {
  border-top: 1px solid #efefef;
}

.datepicker {
  z-index: 1000000;
}

.ajax-validation-background {
  background-color: #D5FAE6;
}

.issue-update-field {
  position: relative;
}

.input-icon {
  /*position: relative;*/
  left: 62%;
  z-index: 1000000000000;
}
/*
.issues-b .item .content figure:hover {
  max-width: 170px;
  width: 170px;
  margin-right: 8px;
}


.issues-b .item .content figure:hover img {
  max-width: 160px;
  display: inline-block;
  width: 160px;
}*/

#scheduled-from-date .datepicker-inline {
  margin: auto;
  width: 205px;
  background-color: white;
}
#big-map-link .icon-search {
  font-size: 16px; color: #5e676c;
}

#update-issue-building .icon-pencil {
  font-size: 16px; color: #5e676c;
}
/*#issue-assign-field, #input-issue-state, #issue-priority-edit .select-a, .float-right, #issue-owner-edit .select-a, #scheduled-select-a, #select-a-transfer  {
  float: right;
}*/
#map-edit-button {
  padding-right: 15px;
}
#map-edit-button.publicSpace {
  cursor: pointer;
}
#change-owner-comment {
  width: 100%; 
  resize: none; 
  margin-bottom: 6px; 
  min-height: 80px;
}
.change-issue-tag-select {
  height: 20px;
}
.issue-tag i.icon-pencil.change-issue-tag {
  cursor: pointer;
}
#update-issue-tag {
  display: inline;
}
#update-issue-tag i.icon-remove.cancel-change-issue-tag {
  cursor: pointer;
}

#update-issue-tag-loader {
  height: 18px; margin-right: 4px;
}

#update-issue-tag .button-a {
  display:inline; height: 20px; font-size: 10px; line-height: 10px; top: -2px;
}

#select-transfer {
  width: 100%; border: 1px solid #fff;
}
#update-issue-address #location {
  background-color: white;
  width: 100%;
}

#update-issue-address .autocomplete {
    position: absolute;
    z-index: 10000000;
    background: white;
    width: 80%;
    margin: 10px 0 0 110px;
    padding: 6px 8px 4px;
    border: 1px solid #cccccc;
}

.alert-no-picture {
  overflow: visible; 
  display:none;
  margin: 0;
}

.validation-error {
  padding: 0 4px;
}
.not-owner-action-button, #map-update-address-modal-title, #issue-remove-assign-row, #remove-transfer-row {
  display: none;
}

#write-transfer { background-color: white; width: 100%;}

#form-transfer-comment { 
  width: 100%; 
  resize: none; 
  margin-bottom: 6px; 
  min-height: 80px;
}

#select-assign {
  width: 100%; 
  border: 1px solid #fff; 
  z-index:10;
}

.issue-state-public-comment label {
  font-size: 10px;
}

#issue-tag-update-ok{
  color: #30C416;
  font-size: 16px;
}

.update-issue-address-switch-map-button {
  cursor: pointer;
}

.ajax-validation-select-state {
  display: none;
  position: relative;
  left: 60%;
  top: 5px;
  font-size: 16px;
  color: #30C416;
  z-index: 10000;
  padding: 1px 2px;
  height:20px;
  width:20px;
  background:white;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.action-confirmation {
  color: #30C416;
}

.filter-a hr {
  margin: 5px 0;
  border: none;
  height: 1px;
  background-color: transparent;
}

.select-a-print {
  float: left;
  left: 40%;
  position: absolute;
  line-height: 26px;
  font-weight: bold; 
}

.highcharts-range-selector {
  font-size: 8px;
  line-height: 8px;
}

.filter-type-select {
  color: #bbb;
  display: inline-block;
  width: 100%;
}

.filter-type-select.active {
  color: #5e676c;
  background-color: white;
}

.filter-type-select.active:hover {
  background-color: white;
}

.filter-type-select:hover {
  color: #5e676c;
}

.checklist .head.no-filter-selected {
  background-color: rgb(255, 199, 162);
  border-radius: 0;
}

.email-comment textarea {
  width: 100%;
}

.details-a .block-status p.field label {
  cursor: default;
}

#edit-description, #cancel-edit-description {
  color: #5e676c;
  font-size: 14px;
}

#edit-description {
  margin-left: 4px;
}
.no-description {
  font-style: italic;
}



#textarea-description {
  width: 84%;
  display: none;
  height: 35px;
}

.issue-date-box {
  min-height: 60px !important;
  text-align: center;
}

.issue-date {
  font-size: 12px;
}

.issue-date-title {
  font-size: 12px;
  padding: 3px 0 0 0;
}

.issue-date-from-now {
  font-size: 12px;
}

.show-description, .show-more-text {
  color: #08c;
  cursor: pointer;
  font-size: 12px;
}

.show-description:hover, , .show-more-text:hover {
  color: #08c;
}

.block-info {
  overflow: hidden;
}


.details-a > header .utils ul li.active, .details-a > header .utils ul li.active:hover {
  background-color: #E0E0E0;
  cursor: default;
}

.blue-link, .blue-link:hover {
  color: #08c;
  font-size: 12px;
}

.close-box {
  color: #5e676c;
  font-size: 14px;
}

.table th {
  font-weight: normal;
}

.admin-emails-input {
  width: 96%;
}


.details-a .block-status.print-description p {
  border-top: none;
}

.issues-b .item .content .utils p.date span.issue-liste-date-owner:before {
  width: 0px;
}

.issues-b .item .content p, .issues-b .item .content .utils p.date span {
  color: #5e676c;
}

.readme {
  background-color: #fff;
  border: none;
  padding: 10px;
  color: #666;
}

.dropdown-a a.issue-details-dropdown:before {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 14px;
  height: 14px;
  overflow: hidden;
  margin: -7px 0 0;
  background: url(../img/icons-sprite-a.png) 0 -180px no-repeat;
  content: ' ';
}


.issues-list-a > header p.reset a:before {
  background: none;
}

.issues-list-a > header p.reset a {
  padding: 0 10px 0 10px;

}



.scheduled-expired {
  color: red !important;
}

.details-a .activity .item figure img {
  margin: 6px 0 0 0;
}

.details-a .activity .item p.info.no-border {
  border: none;
  margin-left: 45px;
  white-space: pre-wrap;
}

.undisplayed {
  display: none;
}

.filter-a .filter-date-field .add-on.label {
  background-color: transparent; border: none; color: #5e676c; width: 17%; text-shadow: none; vertical-align: text-top;
}

.filter-a .filter-date-field input {
  width: 78%;
}

.filter-a .filter-date-field .add-on.reset {
  background-color: transparent; border: none; color: #bbb;
}

.filter-a .filter-date-field .add-on.reset i {
   cursor: pointer;
}

.filter-date-field .add-on.label {
  text-align: left;
  background-color: transparent;
  border: none;
  color: #5e676c; 
  font-size: 12px;
  text-shadow: none;
  vertical-align: text-top;
}

.save-filter-corrupted li {
  font-size: 12px;
}

.modal.error-modal {
  background-color: #fa7251;
  color: white;
}

.modal.error-modal .modal-body {
  background-color: #f9f7f5;
  color: #5e676c;
}

.actions-filters {
  background-color: white;
  margin: 8px 0;
  border: 1px solid #ddd9d7;
}

.actions-filters.calendar-filters-actions {
  border: none;
  margin: 0 0 4px 0;
}

.actions-filters .label-filters {
  padding: 6px 15px;
  height: 20px;
  font-size: 13px;
}

.calendar-filters {
  position: relative;
}

.calendar-filters .filter {
    position: absolute;
    z-index: 999;
}

.calendar-filters .filter .checklist.open{
    width: 600px;
}


.calendar-filters .filter .checklist.open .mono-filters {
    width: 100%;
    display: inline-flex;
    border-bottom: 1px solid #e9e8e6;
    border-top: none;
}

#calendar-month-filters, #calendar-print-filters {
    margin-bottom: 20px;
}

#calendar-month-filters .filter {
    width: 20%;
    display: inline-block;
}

#calendar-print-filters .checklist, .filters-inline .checklist {
    width: 33.33%;
    display: inline-block;
    border: none;
    margin-bottom: 0;
}

#calendar-print-filters .checklist p, .filters-inline .checklist p {
    border: none;
}

#calendar-print-filters .checklist {
  background: white;
}

#calendar-month-filters .checklist .mono-filters, #calendar-print-filters .checklist .mono-filters, .filters-inline .checklist .mono-filters {
  width: 100%;
}

.calendar-filters .filter-a .checklist .fields p {
  white-space: nowrap;
  overflow: hidden;
}

.calendar-filters .checklist.open .mono-filters {
  display: inline-block;
}

.calendar-filters .checklist.open .mono-filters {
  width: 33.33% !important;
}

.actions-filters .actions .span4 {
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  color: #5e676c;
}

.actions-filters .actions a {
  width: 100%;
  height: 100%;
  display: block;
}

.actions-filters .actions .span4.bordered {
  border-right: 1px solid #ddd9d7;
}


.actions-filters .actions .span4:hover {
  background-color: #f9f7f5;
  text-decoration: none;
}

#issue-search-bsId-filters {
  background-color: white;
}

@media only screen and (max-width: 767px) {

  .issues-list-a > header p.reset {
    width: 33px;
  }

  .issues-list-a > header .utils .sort {
    min-width: 1px;
  }

  .dropdown-a ul.dropdown-menu {
    left: -80%;
  }

  .dropdown-a ul.dropdown-menu:after { 
    left: 82%;
  }

  .dropdown-a.user a.dropdown-toggle .name {
    display: none;
  }

  .issues-list-a > header div.reset, header.tools-header div.reset {
    width: 40%;
    padding-right: 17px;
  }

  .actions-filters .actions {
    border-left: none;
  }

  .actions-filters .actions .span4 {
    width: 32%;
    margin-bottom: 0;
    display: inline-block;
  }

  .actions-filters .actions a {
    width: 32%;
    height: 100%;
    display: inline;
  }

  .actions-filters .label-filters {
    margin-bottom: 0;
    width: 50%;
    display: inline-block;
    padding: 5px 15px;
  }

  .actions-filters .actions {
    margin-bottom: 0;
    width: 50%;
    display: inline-block;
  }

  .block-info .comment-icon {
    display: inline-block;
    width: 20%;
  }

  .block-info .comment-icon i {
    margin-left: 20px;
    margin-right: 55px; 
  }

  .block-info .comment {
    display: inline-table;
    width: 79%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .actions-filters .row-fluid, .actions-filters .span6, .actions-filters .span4 {
    margin-bottom: 0;
  }
}

#issue-box {
  outline: 0px;
  z-index: 1002;
  height: auto;
  width: 350px;
  position: absolute;
  top: 95px;
  right: 30px;
  display: block;
  padding: 0px;
}

#issue-box .close-box {
  top: -15px; right: -15px; position: absolute; height: 25px; z-index: 9999; width: 25px; cursor: pointer; background: url(../images/x.png);
}


#issue-box .inner:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
#issue-box p.type { float: right; margin: 0 -20px 5px 0 }
#issue-box figure { float: left; max-width: 70px; margin: 0; }
#issue-box figure a { display: block; padding: 4px; border: 1px solid #dbdbdb; background: #f9f7f5; }
#issue-box figure img { display: block; max-width: 60px; height: auto; margin: 0; }
#issue-box figure ~ * { margin-left: 80px !important; }
#issue-box h3 { margin: 0 0 7px; font-size: 16px;}
#issue-box p { margin: 0 0 7px; color: #aaa; font-size:1.1em; line-height: 1.2; }
#issue-box p.number { color: #5e676c; font-size: 1.2em; }
#issue-box { display: none; position: absolute; z-index: 100; width: 350px; height: auto; padding: 5px; background: url(../img/overlay-c.png); background: rgba(78,78,92,0.25); color: #5e666c; }
#issue-box.on { display: block; }
#issue-box:before { position: absolute; top: -5px; left: 50%; width: 10px; height: 10px; margin: 0 0 0 -5px; background: url(../img/location-a-arrow.png) 50% 0 no-repeat;}
#issue-box .inner { width: 100%; padding: 12px 10px; background: #fff; position: relative;}
#issue-box .inner:after { content: ""; display: block; overflow: hidden; clear: both; height: 0; }
#issue-box p:first-child { margin-top: 0; }

.wrap.map-container { position: relative; z-index: 1; width: 100%; height: 100%; overflow: hidden; margin: 0; }

#map-edit-button .edit-container {position: absolute; right: 12px;}


.ui-autocomplete {
  max-height: 600px;
}

#password-validation .error {
  margin: 4px 0;
}

#issue-comment-validation {margin-top: 8px;}

.list-actived-icon {font-size: 15px; vertical-align: middle;}

.article-link:hover .block-status{background-color: rgb(241, 241, 241); color: #757575;}

textarea.wisyhtml{ font-weight: normal;}

.bootstrap-wysihtml5-insert-link-modal.modal.fade.in {top: 10%;}


.dropdown-a ul#exports-menu.dropdown-menu:after{
  margin: 0 0 0 86px;
}

.button-a.empty {
  background-color: inherit;
  color: #A7A7A7;
}

.button-a.empty:hover {
  background-color: rgba(160,150,150,0.35);
}

.button-a.blue {
  background-color: cadetblue;
}

.button-a.purple {
  background-color: rgb(160, 95, 159);
}

.button-a.purple:focus {
  color: white;
}

.button-a.blue:focus {
  color: white;
}


hr.red {
  border: 1px solid #fa7251; 
  border-bottom: none;
  margin: 6px 0;
}
@media screen and (min-width: 768px) {
  .modal.large {
      width: 96%;
      margin-left: -48%;
      height: 96%;
  }

  .modal-large {
    width: 96% !important;
    margin-left: -48% !important;
    height: 96% !important;
  }

  .modal-medium {
    width: 80%;
    margin-left: -40%;
  }
  #issuePictureModal .modal-body {
      max-height: 93%;
      height: 93%;
  }
}

.modal-large {
  max-height: 900px !important;
}

.modal-helper textarea {
  background-color: whitesmoke !important;
}

.modal-large .modal-content, .modal-large {
  height: initial !important;
}

.bs-slider-nav-content {
  height: 98%;
  overflow-y: scroll; 
  padding-right: 16px;
}

.picture-tools-bar {
  height: 98%;
}

.bs-slider-nav li.active {
  border: 2px solid #f77448;
}

.bs-slider-nav li {
  margin-top: 4px;
  position: relative;
}

.bs-slider-nav li i, .bs-slider-nav li .fa {
  position: absolute;
  right: 8px;
  top: 8px;
  color: white;
  font-size: 30px;
}

.bs-slider-nav li:first-child {
  margin-top: 0;
}

.bs-slider {
  height: 98%;
}

.bs-slider .naviguate {
  height: 100%;
}

.bs-slider-viewer {
  height: 100%;
}

.bs-slider img {
  max-height: 100%;
}

.issue-picture-to-modal {
  cursor: middle;
}

.button-a.transparent {
  background-color: transparent;
  color: #5e676c;
}

.preview-pictures .img-polaroid {
  width: 100%;
}


.color-open {
  color: #f77448;
}
.color-resolved {
  color: #89cc1f;
}
.color-in_progress {
  color: #6b98b5;
}
.color-planned {
  color: #5dc9e6;
}
.color-rejected {
  color: #aaaa5a;
}
.color-deleted {
  color: #a09696;
}
.color-transferred {
  color: #e78fa5;
}

.border-open {
  border: 1px solid #f77448 !important;
}
.border-resolved {
  border: 1px solid #89cc1f !important;
}
.border-in_progress {
  border: 1px solid #6b98b5 !important;
}
.border-planned {
  border: 1px solid #5dc9e6 !important;
}
.border-rejected {
  border: 1px solid #aaaa5a !important;
}
.border-deleted {
  border: 1px solid #a09696 !important;
}
.border-transferred {
  border: 1px solid #e78fa5 !important;
}

.border-b-open {
  border-bottom: 1px solid #f77448 !important;
}
.border-b-resolved {
  border-bottom: 1px solid #89cc1f !important;
}
.border-b-in_progress {
  border-bottom: 1px solid #6b98b5 !important;
}
.border-b-planned {
  border-bottom: 1px solid #5dc9e6 !important;
}
.border-b-rejected {
  border-bottom: 1px solid #aaaa5a !important;
}
.border-b-deleted {
  border-bottom: 1px solid #a09696 !important;
}
.border-b-transferred {
  border-bottom: 1px solid #e78fa5 !important;
}

.bg-open {
  background-color: #f77448 !important; color: #fff !important;
}
.bg-resolved {
  background-color: #89cc1f !important; color: #fff !important;
}
.bg-transferred {
  background-color: #e78fa5 !important; color: #fff !important;
}
.bg-in_progress {
  background-color: #6b98b5 !important; color: #fff !important;
}
.bg-planned {
  background-color: #5dc9e6 !important; color: #fff !important;
}
.bg-rejected {
  background-color: #aaaa5a !important; color: #fff !important;
}
.bg-deleted {
  background-color: #a09696 !important; color: #fff !important;
}
.checklist {
  margin-bottom: 2px;
}

#filter-search .dropdown-menu, #filter-communes .dropdown-menu {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  min-width: 269px;
}

#filter-search .dropdown-menu a, #filter-communes .dropdown-menu a {
  color: #5e666c !important;
  font-size: 14px;
  line-height: 17px;
}

#filter-search .dropdown-menu>li>a:hover, #filter-communes .dropdown-menu>li>a:hover{
  color: #5e666c !important;
  text-decoration: none !important;
  background-color: #f9f7f5 !important;
  background-image: -moz-linear-gradient(top,#f9f7f5,#f9f7f5) !important;
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#f9f7f5),to(#f9f7f5)) !important;
  background-image: -webkit-linear-gradient(top,#f9f7f5,#f9f7f5) !important;
  background-image: -o-linear-gradient(top,#f9f7f5,#f9f7f5) !important;
  background-image: linear-gradient(to bottom,#f9f7f5,#f9f7f5) !important;
  background-repeat: repeat-x !important;
}

#filter-search .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus, #filter-communes .dropdown-menu>.active>a {
  color: #5e666c;
  text-decoration: none;
  background-color: #f9f7f5;
  background-image: none;
  background-image: none;
  background-image: none;
  background-image: none;
  background-image: none;
  background-repeat: no-repeat;
  outline: 0;
}

.issues-search-icon {
  display: inline-block;
}

.match, .highlighted {
  background-color: yellow;
}

.match-strong, .highlighted-strong {
  font-weight: bold;
}

.loader-info {
  font-weight: bold;
  color: #FD881B;
}
.loader, .geolocLoader {
   height:32px;
   width:32px;
   margin:0 auto;
   position:relative;
   -webkit-animation: rotation 1.9s infinite linear;
   -moz-animation: rotation 1.9s infinite linear;
   -o-animation: rotation 1.9s infinite linear;
   animation: rotation 1.9s infinite linear;
   border:6px solid rgba(239, 143, 0, 0.15);
   border-radius:100%;
}


.loader.blue {
   border: 6px solid rgba(59,89,152, 0.15);
}



.loader:before, .geolocLoader:before {
   content:"";
   display:block;
   position:absolute;
   left:-6px;
   top:-6px;
   height:100%;
   width:100%;
   border-top:6px solid rgba(239, 67, 0, 0.8);
   border-left:6px solid transparent;
   border-bottom:6px solid transparent;
   border-right:6px solid transparent;
   border-radius:100%;
}

.loader.blue:before {
  content:"";
  display:block;
  position:absolute;
  left:-6px;
  top:-6px;
  height:100%;
  width:100%;
  border: 6px solid rgba(59,89,152, 0.8);
  border-left:6px solid transparent;
  border-bottom:6px solid transparent;
  border-right:6px solid transparent;
  border-radius:100%;
}

.loader.big {
  height: 150px;
  width: 150px;

}

.loader.middle {
  height: 70px;
  width: 70px;
}

.loader.small {
  height: 16px;
  width: 16px;
  border: 3px solid rgba(129, 129, 129, 0.15);
}

.loader.small:before {
  left:-3px;
  top:-3px;
  border: 3px solid rgba(139, 139, 139, 0.8);
  border-left:3px solid transparent;
  border-bottom:3px solid transparent;
  border-right:3px solid transparent;
}

.loader.inline {
  display: inline-block;
}

.field .loader.small {
  vertical-align: middle;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}


#app-loader, .action-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #f2eeeb;
  z-index: 10000000;
}

.no-header {
  margin-bottom: 15px;
}


.action-loader.absolute {
  position: absolute;
}

#app-loader .content, .action-loader .content {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 70%; left: 0; bottom: 0; right: 0;
  margin: auto;
}

#app-loader .loader {

}

#app-loader h1 {
  color: 6px solid rgba(239, 67, 0, 1);
}

#app-loader h1 span {
  width: 20px;
}

.overlay-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(242, 238, 235, 0.77);
    z-index: 100000;
}

.overlay-loader .loader {
    top: 50%;
}

@media (max-width: 1200px) {
  #app-loader .content {

  }
}

#app-loader .bs-logo img {
  width: 120px;
  position: absolute;
  top: 20px;
  left: 20px;
}

#back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 40px;
    cursor: pointer;
    padding-top: 7px;
}

.authentification-panel {
  margin-top: 40px;
}
#reset-password{
  width: 1200px;
}

.authentification-panel .panel-body {
  padding-top: 6px;
  padding-bottom: 10px;
}

.authentification-panel .panel-body .alert {
  margin-bottom: 10px;
  margin-top: -10px;
}

#login .panel-footer .contact {
    text-align: right;
}

#login .panel-footer .reset-password a {
    font-size: 14px; color:#fb6b42;
}

#login .panel-footer .span6 {
  line-height: 28px;
}

.authentification-panel .action-success {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 67%;
}

i.green, .fa.green {
  color: #30C416;
}

.authentification-panel .panel {
  position: relative;
}

.new-issue-form p.file.drop-box {
  float: initial; height: 113px; width: 100% !important; border: 3px dashed #ddd; margin: 0; padding: 0;
}

.new-issue-form .imageupload {
  cursor: pointer; width: 100%; height: 100% !important;
}

.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 100% !important;
    height: 180px;
    text-align: center;
    margin: auto;
    padding-top: 26px;
}

.drop-box .icon {
  font-size: 30px;
}

.new-issue-form .addissue-a .block-issue .wrap.upload p.file {
  width: 100%;
}

.new-issue-form .preview-pictures img {
  width: 100%;
}


.img-polaroid .progress {
  margin: 8px;
}


.progress .bar.orange {
  background-color: #E74B22;
  background-image: -moz-linear-gradient(top,#E74B22,#E06935);
  background-image: -webkit-gradient(linear,0 0,0 100%,from(#E74B22),to(#E06935));
  background-image: -webkit-linear-gradient(top,#E74B22,#E06935);
  background-image: -o-linear-gradient(top,#E74B22,#E06935);
  background-image: linear-gradient(to bottom,#E74B22,#E06935);
}

.filter-autocomplete .input-append {
  width: 100%;
  margin-bottom: 2px;
}

.filter-autocomplete input {
  width: 85%;
  background-color: white;
  height: 34px;
  border-radius: 0;
  border: 1px solid #ccc;
}

.filter-autocomplete .add-on {
  background-color: rgb(224, 224, 224);
  width: 15%;
  height: 34px;
  padding-top: 6px;
  border-radius: 0;
  cursor: pointer;
}

.filter-autocomplete .add-on.red {
  background-color: rgb(242, 35, 35);
}

.filter-autocomplete .add-on.red i {
  text-shadow: none;
}

.filter-autocomplete .add-on:hover {
  background-color: rgb(239, 239, 239);
}

.filter-autocomplete .add-on.red:hover {
  background-color: rgb(187, 20, 20);
}


.filter-autocomplete .add-on i {

}

.filtres-result-info {
  line-height: 35px;
  margin-bottom: 9px;
  font-size: 16px;
}

.filtres-result-info .separator {
  margin: 0 8px;
}

.filtres-result-info a {
  text-decoration: underline;
  color: #5E5E5E;
}

.issues-list-a header .utils ul li.link {
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #bbb;
  border-left: none;
  border-right: 1px solid #e9e8e6;
  width: 40px;
}

.issues-list-a header .utils ul li.link:first-child {
  border-left: 1px solid #e9e8e6;
}

.issues-list-a header .utils ul li.link.active {
  background-color: #f2eeeb;
}

.modal-dialog.modal-lg {
  height: 100%;
}
.modal-content {
  /*height: 100%;*/
}

.modal-dialog.modal-lg  .modal-body {
  max-height: 91%;
}

.added-log {
  background-color: rgb(244, 255, 248);
}

a.orange {
  color: #fa7251;
}

.map-a .angular-google-map-container { height: 300px; }

.issue-big-map .angular-google-map-container {height: 600px;}

.pac-container.hdpi {
  z-index: 1000000000 !important;
}

.pac-container {
  z-index: 1000000000 !important;
}

.error.hover-map {
  position: absolute;
  top: 47px;
  left: 92px;
  background-color: white;
  padding: 0 4px;
  width: 39%;
}

.select-state-no-bg-pics {
  background-image: none !important;
}

.select-state-no-bg {
  background: none !important;
}

.issue-picture-loader {
  padding: 30px 10px;
  text-align: center;
}

.issue-big-picture-loader {
  text-align: center;
  margin-top: 25%;
}

#better-size {
  font-size: 80%;
}

#better-size .details-a .block-status p.field label, #better-size #description span, #better-size #consigne, #better-size .issue-tag span {
  font-size: 16px !important;
}

.loader-points {
  width: 100px;
  height: 20px;
  margin-left: 49%;
  transform: translate(-50%, -50%);
}

.loader-points > div {
  content: " ";
  width: 20px;
  height: 20px;
  background: #fa7251;
  border-radius: 100%;
  position: absolute;
  -moz-animation: shiftons 2s linear infinite;
  -webkit-animation: shiftons 2s linear infinite;
  animation: shiftons 2s linear infinite;
}
.loader-points.small > div {
  width: 10px;
  height: 10px;
} 
.loader-points > div:nth-of-type(1) {
  -moz-animation-delay: -.4s;
  -webkit-animation-delay: -.4s;
  -o-animation-delay: -.4s;
  animation-delay: -.4s;
}
.loader-points > div:nth-of-type(2) {
  -moz-animation-delay: -.8s;
  -webkit-animation-delay: -.8s;
  -o-animation-delay: -.8s;
  animation-delay: -.8s;
}
.loader-points > div:nth-of-type(3) {
  -moz-animation-delay: -1.2s;
  -webkit-animation-delay: -1.2s;
  -o-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.loader-points > div:nth-of-type(4) {
  -moz-animation-delay: -1.6s;
  -webkit-animation-delay: -1.6s;
  -o-animation-delay: -1.6s;
  animation-delay: -1.6s;
}

@keyframes shiftons {
  0% {
    left: -60px;
    opacity: 0;
    background-color: #FFC2B3;
  }
  10% {
    left: 0;
    opacity: 1;
  }
  90% {
    left: 100px;
    opacity: 1;
  }
  100% {
    left: 160px;
    background-color: #fa7251;
    opacity: 0;
  }
}

.block-status-title a.btn {
  font-size: 12px;
  line-height: 15px;
  color: #5e676c;
  font-weight: 200;
  margin-top: -1px;
}
.block-status-title a.button-a {
  margin-top: -4px;
}
.block-status-title h5 {
  font-size: 14px;
}

.issue-data .block-status, .issue-data .content {
  font-size: 14px !important;
}

#ie-error img {
  width: 50px;
}

#ie-error .browsers {
  margin: 20px 0 40px;
}

#ie-error .browsers .span4, #ie-error .browsers .span6 {
  text-align: center;
}
  
#ie-error .browsers a {
  color: #5e676c;
}

:-ms-input-placeholder {
    color: #9B9595 !important;
}

#route-loader {
  position: absolute;
  height: 1000px;
  z-index: 100000;
  background: #f2eeeb;
  width: 100%;
  padding-top: 134px;
  text-align: center;
}

#nav li:active, #nav li:active a {
  background-color: #f9f7f5;
}


.print-work-order {
  cursor: pointer;
  font-weight: normal;
}

.print-issues-menu {
  width: 250px !important;
  min-width: 166px;
  left: -50% !important;
}

.dropdown-a ul.dropdown-menu.print-issues-menu:after {
  left: 83%;
}

.dropdown-a ul.dropdown-menu li .label-b {
  width: 100%;
}

.admin-texts textarea {
  height: 120px;
}


option {
  background-color: #F2EEEB;
  color: #5e676c;
}

.footer-email {
  max-height: 120px;
  overflow-y: scroll;
}

.validation-notif {
  color: #30C416;
  font-size: 12px;
}

#f-description {
  resize: none !important;
}

.clear-form.clickable {
  color: #5e676c;
}

.clear-form.clickable:hover {
  color: white;
}

#user-pro-buildings-list .remove {
  cursor: pointer;
}

.actions-state ul.dropdown-menu {
  padding: 5px 0;
  padding: 0;
  border-radius: 0;
  left: -129px;
}

.list-infos .actions-state ul.dropdown-menu {
  top: 33px;
  left: -147px;

}

.actions-state ul.dropdown-menu li {
  height: 25px !important;
  margin: 0 !important;
  font-size: 12px;
  text-align: right;
}

.actions-state ul.dropdown-menu li a {
  color: white !important;
  font-weight: bold !important;
  padding: 3px 10px !important;
}

.list-infos .actions-state i.icon-caret-down {
  margin-left: 4px;
}

.actions-state .label-a.dropdown-toggle {
  white-space: nowrap;
}

.actions-state-calendar-button {
  padding-top: 4px;
  height: 20px;
  line-height: 22px;
}

.actions-state-calendar-button.small {
  height: 15px;
  line-height: 15px;
}


.issue-filter-block .dropdown {
  float: right;
}

.issue-filter-block .last-update {
  display: inline-block;
  margin-top: 2px;
  width: 100%;
}

.issue-filter-block.small .actions-state a {
  color: white;
  padding: 0 4px;
  font-weight: bold;
}

.issue-filter-block.small .actions-state ul.dropdown-menu {
  top: 14px;
  width: 150px;
  right: 0;
  left: -93px;
}

.issue-filter-block.small .comment p {
  margin-bottom: 0;
}

.issue-filter-block.small .issue-description {
  margin-top: 4px;
  margin-bottom: 0;
}


.issue-filter-block.small h5 {
  margin: 4px 0 2px 0;
}

.issue-filter-block.small .pull-right {
  margin-right: 2px;
  font-size: 10px;
  line-height: 14px;
}

.issue-filter-block.small .address {
  font-size: 11px;
}

.issue-filter-block.small .content .description {
  border: none;
}

.issue-filter-block.small address {
  line-height: 10px;
  margin-top: 4px;
}

.chosen-container-single {
  margin-bottom: 0px;
}

#flashes-box {
  position: fixed;
  top: 106px;
  right: 60px;
  z-index: 1000000;
}

#flashes-box .alert {
  width: 430px;
  margin-bottom: 6px;
}

#flashes-box .alert.alert-success {
  background-color: rgba(223, 240, 216, 0.8);
}

#flashes-box h5, #flashes-box p {
  margin: 0;
}

.modal { position: fixed !important; max-height: 98%; }

.modal textarea, .modal-body textarea {
  min-height: 90px !important;
  width: 100%;
}

.modal-header.confirmation-error {
  background-color: #fa7251;
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-top-right-radius: 6px;
}

.table-bordered {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
        border-collapse: none;
}

#commune-dashboard .centered, .centered {
  text-align: center;
}

.lefted {
  text-align: left !important;
}

#commune-dashboard .low {
  text-align: right;
  background-color: rgba(241, 13, 13, 0.55);
}

#commune-dashboard .medium {
  text-align: right;
  background-color: rgba(249, 170, 26, 0.65);
}

#commune-dashboard .high {
  text-align: right;
  background-color: rgba(53, 189, 53, 0.65);
}

#commune-dashboard th {
  padding: 0 4px;
}

#commune-dashboard thead tr th {
  cursor: pointer;
}

.sub-arrow {
    font-size: 19px;
    padding: 8px;
}

.sub-arrow-sm {

}

.issue-cover {
      min-height: 80px;
}

.rotate-90 {
  /* Safari */
-webkit-transform: rotate(90deg);

/* Firefox */
-moz-transform: rotate(90deg);

/* IE */
-ms-transform: rotate(90deg);

/* Opera */
-o-transform: rotate(90deg);

/* Internet Explorer */
filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.rotate-90.inline {
  display: inline-block;
  padding-left: 9px;
}

.button-a.default {
    color: #616161;
    border: 1px solid #DDD9D7;
}

.button-a.default:hover {
  color: #616161;
}

select.inline {
    width: 80px;
    height: 24px;
    vertical-align: baseline;
    margin: 0 4px;
    padding: 0 4px;
}

input.inline {
    width: 40px;
    height: 24px;
    vertical-align: middle;
    padding: 0 3px;
    margin: 0 4px;    
    padding: 0 4px;
}

select.inline.large, input.inline.large {
  width: 100px;
}

select.inline.small, input.inline.small {
  width: 60px;
}

.dropdown-menu {
  z-index: 1000000;
}

.small-addon {
    height: 24px;
    margin-left: -4px;
    padding: 0 12px;
}

.white-input {
    background-color: white !important;
    border-color: #CCCCCC;
}

.recurrence-modal p {
  font-size: 14px;
}

.recurrence-modal .marged {
  margin-left: 40px;
}

.recurrence-modal .marged .marged {
  margin-top: 10px;
}

.recurrence-modal section  {
  margin-bottom: 30px;
}

.issue-recurrence {
  display: inline-block;
  color: #f77448;
  width: 99%;
}

.popover.helper p {
    font-size: 14px;
}

.popover.helper img {
  margin: 12px 0;
}
.popover-content {
  background-color: white;
}

a.helper {
    font-size: 21px;
    color: #5E676C;
    margin-top: -6px;
}

a.helper:hover {
    color: #bbb;
}

.popover.helper .well {
    padding: 8px;
    font-size: 14px;
}
.popover.helper .well .span1 {
  text-align: center;
}
.popover.helper .well i {
    font-size: 28px;
    margin: auto;
}


.btn-toolbar.inline {
    vertical-align: super;
    margin-left: 4px;
}

.recurrence-preview {
  margin: 12px 0;
}

.recurrence-preview .date {
  vertical-align: top;
  width: 65px;
  float: inherit;
  display: inline-block;
  margin-bottom: 12px;
}

.recurrence-preview .month, .recurrence-preview .year {
  font-size: 10px !important;
}

.recurrence-preview .date .day.day-number {
  background-color: white;
  font-size: 10px;
  color: #818181;
}

.recurrence-preview .creation-date {
  font-size: 8px;
}

.recurrence-preview .date.virtual {
  border: 1px dashed;
  background-color: #A9A6A6;
}

.recurrence-preview p {
  font-size: 12px;
  margin-bottom: 2px !important;
}

.pdf-preview {
    width: 33%;
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 4px;
}
.file-hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(119, 119, 119, 0.3);
  z-index: 1;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -moz-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-hover i {
  max-width: 50%;
  font-size: 32px;
  color: #5e676c;
}

.file-hover.transparent {
  background-color: inherit !important;
}

.picture-preview {
  display: inline-block;
  position: relative;
  height: 100%;width: 25%;
  margin-top: 4px;
}

.pdf-actions .button-a.first {
    margin-top: 50%;
    margin-left: 11%;
}

.work-order-form h5 {
    font-weight: normal;
}

.work-order-form .activity {
  margin: 0 0 10px 0;
}

.w-100 {
  width: 100%;
}

@media print {
  .page-break { display: block; page-break-before: always; }
  #dpi { display: none; }
}

#issue-thumbnails .img-polaroid {
  padding: 1px;
}

.remove-cross {
    position: absolute;
    right: -6px;
    top: -9px;
    font-size: 16px;
    z-index: 10;
    cursor: pointer;
    color: #5e676c;
}

h3.nears-title {
  margin-top: 0;
}

.modal-body .issue-filter-block .list-img-preview-content img {
  padding: 0;
}

.modal-body {
  font-size: 1.2em;
}

.inline-label {
    display: inline;
    padding-left: 0;
}

.new-issue-submit, .new-issue-submit button {
  width: 100%;
}

.near-issue-modal h5 {
  font-size: 1em !important;
  margin-bottom: 0;
}

.recurrence-other {
    font-size: 18px;
    vertical-align: bottom;
    height: 33px;
    display: inline-block;
}

#calendar-filters-container {
  width: 20%;
}

#calendar-filters-container p.data {
  display: inline-block; width: 80%; margin-bottom: 0px;
}

#calendar-filters-container a.filters-button {
  height: 55px;
  cursor: default;
}

#calendar-filters-container .button-calendar {
  margin-top: -4px; margin-left: 0px;
}

#calendar-filters-container .filters-button label span {
  color: white; position: absolute; width: 203px; top: 1px;
}

#filters-and-issues {
  position: fixed;
  width: inherit;
  max-width: 340px;
}

#filters-and-issues #issues-filters {
  overflow-x: scroll;
}

input[readonly]:focus {
  border-color: #E0E0E0;
      outline: 0;
    outline: thin dotted \9;
    -webkit-box-shadow: inherit;
    -moz-box-shadow: inherit;
    box-shadow: inherit;
}

/* input[disabled], input[readonly] {
    cursor: not-allowed !important;
    background-color: #eee !important;
} */

.recurrence-details th {
  padding: 2px;
}

.recurrence-modal p.error {
    font-size: 12px;
}

.fakeIssue {
  height: 136px;
}

.in-list-schedulto {
  min-width: 86px; font-size: 12px; font-weight: normal; margin-top: 1px; text-align: right;
}

.owner-highlighted th {
  background-color: rgba(255, 245, 206, 0.74) !important;
}

.relative {
  position: relative !important;
}

#contacts li {
    text-align: center;
    border-top: none !important;
    padding: 4px;
}

#contacts li a, #contacts li span {
    font-size: 14px;
    color: #8B8B8B;
}

#contacts li a:hover {
  color: inherit;
  background: #ddd9d7;
}

.dropdown-a.user a.dropdown-toggle .name  {
  font-size: 12px !important;
}

.dropdown-a.user a.dropdown-toggle .avatar.no-bg {
  background: inherit;
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin-left: 7px;
}

.near-distance {
    float: right;
    padding: 1px 4px;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 2px;
}

.dropdown-a.help a.dropdown-toggle {
  padding: 17px;
}

.dropdown-a.help a.dropdown-toggle:before {
  display: none;
}

.dropdown.dropdown-a.user a:hover, .dropdown.dropdown-a.user a:active {
  background: #f9f7f5;
  color: inherit;
}

#contacts li:first-child  {
  font-size: 14px;
  margin-top: 7px;
}

#contacts li:last-child {
  margin-bottom: 7px;
}

/*.dropdown-a.help a.dropdown-toggle:before {
  content: inherit;
  background: inherit;
}

.dropdown.help .dropdown-toggle {
    width: 55px !important;
    font-size: 1em;
}*/

.details-a #logs .activity {
  margin: 0;
    width: 635px;
    border-top: none;
}

.details-a #logs .activity .item {
  min-height: 69px;
  background-color: white;
}

#logs .log-line, #logs .log-issue {
  padding: 8px;
}

#logs .device {
  font-size: 13px;
  margin-right: 4px;
}

.issues-activities#logs .activity {
  padding: 8px;
}

.issues-activities#logs .log-line {
  padding: 0;
}

.issues-activities#logs .log-issue {
  padding-bottom: 0;
}

.log-desc {
      margin-left: 75px !important;
}


.stats-block, .admin-link-block {
  background-color: white;
  border: 1px solid #cecbc8;
  text-align: center;
  padding: 4px 12px;
  margin-bottom: 12px;
}

.stats-block .title {
  padding: 8px 12px;
  font-size: 14px;
  color: #8B8B8B;
  border-bottom: 1px solid #cecbc8;
}

.stats-block .value.bold {
  font-weight: bold;
}

.admin-link-block .title {
  padding: 8px 12px;
  font-size: 14px;
  color: #5e666c;
  font-weight: bold;
}

.stats-block .value, .admin-link-block .value {
  font-size: 32px;
  padding: 16px 0;
}

.stats-block .value .small-value {
  font-size: 22px;
}

.stats-block a {
  font-size: 12px;
}

.stats-block .stats-no-data {
    padding-top: 120px;
    font-size: 20px;
}

.admin-link-block .value {
  padding: 16px 0 0;
}

.stats-block small, .admin-link-block small {
  color: gray;
}

.admin-link-block a {
  color: #5e666c;}

.admin-link-block a:hover, .admin-link-block a:hover .title {
  color: #bbb;
}

#print-stats .stats-block .title {
    font-size: 10px;
}

.filter-a .checklist .fields input[type=checkbox] + label.unchecked:before {
    background-position: inherit !important;
}

.filter-a .checklist .fields input[type=checkbox] + label.checked:before {
    background-position: 0 -50px !important;
}

.logs-container .activity {
    border: none;
    background: none;
}

.logs-container .activity .item.issue-log {
    border: none;
    background-color: inherit !important;
    padding: 0;
    min-height: inherit !important;
}


.flex-center {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
align-items: center; 
}

.flex-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-row {
    width: auto;
}

.flex-center .aligned {
  max-width: 50%;
}

.flex-center.chart-loader {
  position: absolute;
  top: 0;
  height: 300px;
  width: 100%;
  z-index: 100000000000;
}

.quick-entry-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(242, 238, 235, 0.77);
  z-index: 100000;
}

.addissue-a .block-issue .wrap.upload p.file.dragover {
  background-color: #EAEAEA;
}

.fields.filter-field label {
  display: inline !important;
}

.fields.filter-date-field p.input-append {
  padding: 6px 19px 6px 8px;
}

.filter-date-field {
  position: relative;
}

.filter-date-field .date-picker {
    left: 0px;
    top: 35px;
    z-index: 1000;
    position: absolute;
    height: 286px;
}

.dp-cell, .dp-footer-today {
   outline:0px !important;
    -webkit-appearance:none;
}

.dp-footer-selected {
  display: none
}

.citizen-app-link a {
  color: white;
  text-decoration: underline;
}

.filter-date-field .date-picker {
  height: 301px;
}

.print-view {
  float: none !important;
}

.link-to-back-office {
    font-size: 15px;
    padding: 7px 12px 7px 12px;
    color: #6C7D88;
}

.link-to-back-office i, .link-to-back-office .fa {
  font-size: 30px;
}

.link-to-back-office i.icon-chevron-right, .link-to-back-office .fa-chevron-right {
    font-size: 24px;
    margin-top: 4px;
}

.link-to-back-office span.text {
    vertical-align: super;
    margin-left: 10px;
}

#issues-bottom-nav {
    z-index: 10000000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 57px;
}

#issues-bottom-nav .back-to-list {
    height: 100%;
    padding: 14px 18px;
    font-size: 18px;
    border-top: 1px solid #ddd9d7;
    border-right: 1px solid #ddd9d7;
    cursor: pointer;
    background-color: #EFEFEF;
}

#issues-bottom-nav #nav-issues {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}

#issues-bottom-nav .nav-issues {
  display: inline-block;
  width: 250px;
}


#issues-bottom-nav .span1 {
  margin: 0;
  width: 4%;
}

#issues-bottom-nav .span11 {
  margin: 0;
  width: 96%;
}

#issues-bottom-nav .issue-filter-block .description > .row-fluid {
  background-color: #EFEFEF;
}

#issues-bottom-nav .issue-filter-block .description > .row-fluid:hover {
  background-color: #f9f7f5;
}

#issues-bottom-nav .issue-filter-block .address {
  font-size: 9px;
} 

#issues-bottom-nav h5 {
    font-size: 1.2em;
    font-weight: 100;
    padding-right: 4px;
    margin: 10px 0 0 0;
}

#issues-bottom-nav .nav-issues.active .description > .row-fluid {
  background-color: white !important;
}

.righted {
  text-align: right;
}

.force-text-righted {
  text-align: right !important;
}

.empty-filter-checkbox{
    width: 8px;
    display: inline-block;
}
.filter-a .filter-field.no-hover {
  cursor: default;
}

.filter-a .filter-field.no-hover:hover {
  background: none;
}

.checkbox-disabled-overlay {
    position: absolute;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.modal.modal-helper {
    background: #fff;
    border: none;
    border-radius: 0;
}

.modal-helper h2 small {
    font-size: 12px;
    color: #f77448;
}

.modal-helper h2 {
    /*margin-top: 0px;*/
}

.freemium-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.69);
    z-index: 1000;
    cursor: pointer;
    top: 0;
    left: 0;
    text-align: right;
}

.freemium-overlay .flex-center .aligned {
  width: 100%;
  text-align: right;
  padding-right: 12px;
}

.freemium-overlay i {
    font-size: 14px;
    padding: 0 13px;
    margin-top: 6px;
    display: inline-block;
    color: #C5C5C5;
}

.input-locked {
    font-size: 15px;
    margin-right: 8px;
    vertical-align: text-bottom; margin-left: 4px;
}

.inline-block {
    display: inline-block;
}

textarea.white {
    border: none;
    box-shadow: none;
    background: white;
}

.hide-map #auto-complete-map-canvas {
  height: 0 !important;
  border: 0 !important;
}

.quick-entry .new-issue-address-explanation, .quick-entry .nears-issue, .quick-entry .hide-for-quick-entry {
  display: none;
}

.quick-entry .select-a, .quick-entry input[type='text'] {
  width: 100%;
}

.quick-entry textarea {
    width: 100%;
    min-height: 100px;
    border: none;
    box-shadow: none;
}

.quick-entry .modal-body h3 {
  font-size: 14px;
}

.quick-entry .modal-body span.select-a, .quick-entry .modal-body textarea {
  background-color: #f2eeeb;
}

.quick-entry .chosen-container {
  margin-top: 4px;
}

.issues-tools {
  margin-bottom: 15px;
}

.issues-tools .btn, .calendar-tools .btn {
    color: #5e676c;
    background: #f9f7f5;
    border-color: #ddd9d7;
}

.calendar-tools .btn.nav-btn span {
  line-height: 20px;
}

.issues-tools .issues-number {
  min-width: 120px;
}

.issues-tools .dropdown-a a.dropdown-toggle {
    color: #5e676c;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    padding: 0 26px 0 10px;
}

.issues-tools .issues-sort, .calendar-tools .view-type-btn {
  color: #a0a0a0;
}

.issues-tools .issues-sort.active, .calendar-tools .view-type-btn.active {
  color: #5e676c;
}

.issues-tools .btn.icon, .issues-tools .btn.icon:active {
  background-color: #efefef;
}

.no-issues {
  font-size: 14px;
}

.issues-tools .dropdown-a ul.dropdown-menu {
  margin: 6px 0 0 -71px !important;
}

.search-more-issues a {
  text-decoration: underline;
  color: #677074;
  font-size: 14px;
}

.btn.no-clickable:active {
  background: #f9f7f5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#content.full-width {
  width: 100% !important; 
  max-width: 1600px !important;
}

#content.small {
  width: 100% !important; 
  max-width: 800px !important;
}


#smart-home .issues-list {
  background-color: #f9f7f5;
  border: 1px solid #cdcac6;
  width: 20.5%;
  margin-right: 0.5%;
  display: inline-block;
  vertical-align: top;
}

#smart-home .weather {
  width: 16%;
  display: inline-block;
  vertical-align: top;
}

#smart-home .issues-activities {
  background-color: #f9f7f5;
  border: 1px solid #cdcac6;
  display: inline-block;
  width: 62.5%;
}

#smart-home .issues-activities .issues-list-content {
  height: 400px;
  overflow-x: scroll;
}

#smart-home .no-result {
  text-align: center;
  font-size: 12px;
  color: #cecbc8;
}

#smart-home .no-result i {
  font-size: 16px;
}

#smart-home .issues-list .title, #smart-home .issues-activities .title {
  padding: 8px;
  background-color: #f9f7f5;
  border-bottom: 1px solid #cdcac6;
  margin-bottom: 0;
  font-size: 13px;
  position: relative;
}

#smart-home .issues-list .title .well {
    background-color: white;
    margin-top: -1px;
    padding: 0 4px;
    position: absolute;
    right: 4px;
    top: 8px;
}

#smart-home .issues-list .title h3, #smart-home .issues-activities .title h3 {
  margin: 0;
  font-size: 1.4em;
  font-weight: 100;
  margin-bottom: 4px;
}

#smart-home .issues-activities h4 {
  margin: 0;
  font-size: 12px;
  font-weight: 100;
  margin-bottom: 4px;
  margin-left: 56px;
}

#smart-home .issues-list .issues-list-content {
  padding: 0 2px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

#smart-home .issues-list .issues-list-content.activities {
  height: inherit;
}


#smart-home .issues-loader {
  font-size: 34px;
  color: #e84923;
}

#smart-home .issues-list .additional-content {
  margin-top: 3px;
}

#smart-home .issues-list .group-label {
  position: absolute;
  top: -8px;
  padding-right: 4px;
  text-align: center;
  width: 100%;
}

#smart-home .issues-list .group-label span {
  background: #f9f7f5;
  font-size: 13px;
  padding: 0 8px;
}

#smart-home .issue-filter-block.small .address {
  font-size: 11px;
  line-height: 11px;
}

#smart-home .issues-list hr {
  margin: 10px 0;
}


.issue-filter-block .last-activity {
  font-size: 12px;
}

#smart-home .issue-filter-block.medium{
  margin-bottom: 2px;
}

#smart-home .issue-filter-block.medium h5 {
  font-size: 13px;
  margin-bottom: 4px;
}

#smart-home .issue-filter-block.medium h5 .pull-right {
  margin-right: 4px;
}

#smart-home .last-activity .log{
  padding-left: 8px;
}

#smart-home .last-activity {
    border-top: 1px solid #ddd9d7;
    border-bottom: 1px solid #ddd9d7;
    background: #f9f7f5;
    margin: 4px 0;
    padding: 4px;
}

#smart-home .last-activity h4 {
      margin-top: 0;
}

#smart-home .last-activity p {
  margin-bottom: 0;
} 

#smart-home .infos {
  min-height: 30px;
}

.issues-list-a > header .utils > ul li.link.text {
  width: inherit;
  padding-left: 8px;
  padding-right: 8px;
}

.popover-title {
    font-size: 12px;
    background-color: #5f5e5e;
    color: white;
    font-weight: bold;
}

.print-list-actions {
    padding: 8px;
    font-size: 14px;
    background: white;
    border: 1px solid #ddd9d7;
}

.issues-arranged-pictures img {
  width: 220px;
}

.issues-arranged-pictures .landscape-pictures, .issues-arranged-pictures .portrait-pictures {
  display: inline-block;
}

.issues-arranged-pictures .landscape-pictures img {
  vertical-align: top;
  display: block;
}

#smart-home .log-issue {
    background: #f3f0ed;
    margin: 8px 0px;
    border-bottom: 1px solid #cdcac6;
    border-top: 1px solid #cdcac6;
}

#smart-home #logs .issue-filter-block.small {
  margin-bottom: 8px;
}

#logs-scroll {
  height: 400px;
  overflow-x: scroll;
}

#smart-home .log-issue .activity:first-child {
  padding-top: 0;
}

#smart-home .log-issue .log-desc {
  margin-left: 0 !important;
  width: 100%;
}

#smart-home .log-issue .activity {
  width: 100%;
}

.weather-label {
  display: inline-block;
  padding: 0 4px;
  color: white;
  border-radius: 2px;
  margin-top: -2px;
  cursor: default;
  line-height: 17px;
}

.weather-label.min-weather  {
  background-color: #8b8b8b;
  margin-right: 8px;
}

.weather-label.max-weather {
  background-color: #fc714a;
}

.text-info {
  color: #fc714a;
}

.refresh-home {
  cursor: pointer;
  padding: 0 11px;
  color: #999;
}

.refresh-home:hover, .refresh-home:active {
    background: #f4f2f0;
    color: #999;
}

.print-week-day {
    color: #5e676c;
    margin-right: 4px;
    margin-top: 2px;
}

.dashed-writer {
  display: inline-block;
  border-bottom: 1px dashed black;
  margin-left: 6px;
  width: 300px;
}

.followers-contact input {
   display: inline-block;
}

.followers-contact label {
    display: inline-block;
    vertical-align: sub;
    margin-left: 4px;
}

.followers-contact p {
  margin: 0;
}

.label-email-to {
    padding: 0 4px;
    border: 1px solid #b3c3cc;
    background-color: #e3f6fd;
    border-radius: 6px;
    line-height: 20px;
    display: inline-block;
}

.label-email-to input {
  margin: -1px 4px 0 0;
}

.label-email-to span, .label-email-to {
    font-size: 12px;
}

.manual-input {
  border-bottom: 1px dashed black;
  min-height: 20px !important;
}

.manual-input.performer {
  height: 17px;
  min-height: 17px !important;
}
a[disabled], a[disabled] span {
    cursor: default;
}

.pictures-viewer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 24em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.pictures-viewer .item-picture {
    text-align: center;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    max-width: 76%;
    max-height: 100%;
}

#slaves-container i.icon-remove {
  cursor: pointer;
  margin-left: 4px;
}

#slaves-container {
  margin-bottom: 0;
  font-size: 1.2em;
}

#link-tags-form table tbody select.empty {
  background-color: rgba(249, 244, 94, 0.2);
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.no-margin {
  margin: 0!important;
}

.details-a.white {
  background-color: white;
  padding: 4px 8px;
}

#contact-owner textarea {
  resize: vertical;
  min-height: 120px;
}

.confirmation {
  color: green;
  font-weight: bold;
}

.admin-modal .input-prepend .add-on {
  width: 5%;
  height: 30px;
  cursor: pointer;
}
.admin-modal .input-prepend input {
  width: 95%;
}

.log-requestor {
    position: absolute;
    top: 10px;
    right: 10px;
}

h4.log-title {
  max-width: 65%;
}
/*
#filters-result.issues-b.result.boxed.issues-drag-over { 
    border: 3px solid green;
}
*/
.gm-style-iw {
    width: 243px !important; 
    color: #5e676c; font-family: 'Open Sans',Helvetica Neue,Helvetica,Arial,sans-serif; line-height: 1.5;
    overflow: visible !important;
}

.gm-style-iw .issue-preview.issue-popover { 
    max-width: 242px !important; 
    width: 242px !important; 
}

.gm-style-iw div {
  overflow: visible !important;
}

.gm-style-iw .label-a {
    z-index: 100000;
    top: 0px;
    right: -25px;
}

.gm-style-iw .owner-deadline {
  text-align: left !important;
}

.gm-style-iw .issue-preview p.popover-picture {
  text-align: center;
}

.gm-style-iw img {
  max-height: 180px !important;
}



#issues-map #loader {
    position: absolute;
    top: -40px;
    right: 30px;
}

#issues-map #filters-result.issues-b.result.boxed {
  border: 1px solid #A09696;
}


.label-file {
    cursor: pointer;
    font-weight: bold;
}
.input-file {
    display: none;
}

.csv-table input {
    margin: 0;
}

.actions-filters .actions .span4 {
  padding-top: 9px;
}

.issue-filter-block .list-infos .dropdown ul {
  padding: 0 !important;
}

.issues-tools .btn.btn-refresh , .issues-tools .btn.no-clickable.icon {
  padding: 7px 11px;
}

.issue-log .date {
  margin-top: 6px;
}

.block-status.light, .white-box.light  {
  background-color: #f5f3f2 !important;
}

.state-label {
    padding: 2px;
    border-radius: 4px;
    color: white;
}

#nav a .fa-lg {
    line-height: 40px;
}

.white-box .bottom-bar {
    padding: 2px 4px 2px 0;
    /*background: #efefef;*/
    border-top: 1px solid #dad5d4;
}

input.date-editable {
    background-color: transparent !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    height: 23px;
    text-align: center;
    margin: 0 8px;
    height: 16px;
    font-weight: normal;
    text-align: left;
    padding: 0;
    cursor: pointer !important;
    font-size: 1rem;
}
input.date-editable.date-editable-withoutHour{
  width: 80px;
}
input.date-editable.date-editable-withHour{
  width: 125px;
}

.date-editable-container {
  display: inline-block;
  margin-top: 4px;
}

.Select {
  max-width: 200px;
  cursor: pointer;
}

.Select.Select--single.is-pseudo-focused .Select-control {
  border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.Select-control {
    border: none;
    padding: 0;
    height: 20px;
    border-radius: 0 !important;
}

.Select-control:hover {
  box-shadow: 0 !important;
}

.Select-control .Select-value {
    padding: 0;
    height: 20px;
    cursor: pointer;
}

.Select-control .Select-value {
  padding-left: 0 !important;
}

.Select-control .Select-value .Select-value-label {
  color: #5e676c !important;
}

.Select-control .Select-value:hover, .Select-control .Select-value:active, .Select-control .Select-value:focus {
  border: none;
}

.Select-control .Select-value-label {
    line-height: 22px;
}

.Select-control .Select-menu-outer {
  z-index: 3;
}
.Select-control .Select-input {
  height: 26px;
}

.date-editable.flatpickr-input {
  margin-left: 0 !important;
}

.btn-group>.btn:hover, .btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active {
  z-index: inherit;
}

.text-light {
  color: #999;
}

.text-editable {
  cursor: text;
}

.select-editable {
  cursor: pointer;
}

.select-editable.disabled {
  cursor: default;
}

.text-editable:hover {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.select-editable:hover {
  border-bottom: 1px dotted #cecece;
}

.select-editable.disabled:hover {
  border-bottom: none !important;
}
.editable-hover {
  display: none;
}

.text-editable:hover .editable-hover, .select-editable:hover .editable-hover {
  display: inherit;
}

.select-editable:hover .editable-hover-hide {
  display: none;
}

.Select-container.condensed .Select {
  display: inline-block;
}

.Select-container.condensed .Select .Select-control {
    width: auto;
}
.Select-container.condensed:not(.multi) .Select .Select-value,
.Select-container.condensed .Select .Select-placeholder {
    position: static;
    display: table-cell;
    padding-right: 0;
}
.Select-container.condensed .Select .Select-loading-zone {
    padding-left: 5px;
}
.Select-container.condensed:not(.multi) .Select .Select-value ~ .Select-input,
.Select-container.condensed .Select .Select-placeholder ~ .Select-input {
    position: absolute;
    left: 0;
}
.Select-container.condensed .Select .Select-menu-outer {
    width: auto;
    min-width: 100%;
}
.Select-container.condensed .Select .Select-menu {
    overflow-y: auto;
    overflow-x: hidden;
}
.Select-container.condensed:not(.multi) .Select .Select-option {
    white-space: nowrap;
    padding-right: 25px; /* absolute positioning scrollbar fix */
}
.Select-container.condensed.multi .Select .Select-multi-value-wrapper {
    display: initial;
    position: initial;
}


#details-tags-select {
  margin: 0;
}

.editable-icon {
   font-size: 14px;
   padding-left: 4px;
   color: #cecece;
}

.issue-log.closed {
  max-height: 500px;
  overflow: hidden;
}

.bs-loader {
  margin-top: 85px;
}

#logs-scroll .bs-loader {
  margin: 40px 0 20px;
}
.search-item {
  padding: 8px 4px;
  background-color: white;
  font-size: 12px;
  font-weight: 600;
}

.search-item .fa {
  font-size: 13px;
}

.search-item.active {
  background-color: #f9f7f5;
  font-weight: 700;
}

.search-item.active .fa {
  font-size: 16px;
} 


.filter-autocomplete {
  width: 100% !important;
}

.filter-autocomplete .add-on {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.filter-autocomplete .add-on .fa {
    margin-top: 3px;
}

.add-on.red .fa {
  text-shadow: none;
}

.view-dropzone, .view-overlay {
    color: white;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

#content {
  position: inherit !important;
}

.control-group.inline, .control-group.inline .controls {
  display: inline-block !important;
}

input.inline.date {
  width: 75px;
}

fieldset label.span6, fieldset label.span4 {
    padding-left: 0;
}

fieldset label.span6 input, fieldset label.span4 input {
    margin-top: -2px;
    margin-right: 4px;
}

.Select.select-a .Select-control {
  background-color: #f2eeeb;
  margin-bottom: 8px;
}

.Select.select-a .Select-control .Select-multi-value-wrapper, .Select.select-a .Select-control .Select-value-label {
    color: #666 !important;
    font-size: 12px;
    margin-top: 3px;
    display: inline-block;
    font-weight: 600;
}

.Select.select-a .Select-control .Select-arrow-zone {
  padding-top: 5px;
}

.Select.select-a .Select-control .Select-placeholder {
    line-height: 29px;
    color: #666 !important;
    font-weight: 600;
}

.Select.select-a .Select-control .Select-input input {
    color: #666 !important;
    font-weight: 600;
    padding-top: 0;
}

.Select.select-a.is-open .Select-control .Select-input input {
    padding-top: 4px;
}

.addissue-a .dropzone, .addissue-a textarea {
  margin: 8px 0;
}

.radio-field-small {
  margin-left: 16px;
}

.addissue-a .progress {
  width: 100%;
  height: 16px;
  min-height: 0px;
  margin-top: 4px !important;
}
.gm-style-iw {
  width: 280px!important;
  height: auto!important;
  left: 0!important;
  font-size: 15px!important;
  font-weight: normal!important;
  top: 0!important;
  overflow: hidden!important;
  border-radius: 3px;
}

.infoBox {
  max-width: 400px !important;
  width: 400px !important;
}

.infoBox .info-content {
  width: 400px;
}

.infoBox img {
  display: none;
}

.infoBox .info-content img {
  display: inherit;
}

#issue-thumbnails {
  border-top: 1px solid #ddd9d7;
}

#issue-thumbnails .picture-preview {
  height: inherit;
  padding: 0 4px 4px;
}

.details-states ul.dropdown-menu {
  left: -50px !important;
  top: 19px !important;
}

.first-recurrence a.btn {
  padding: 7px 10px 6px;
}

.issue-log textarea {
  background-color: white;
  resize: none;
  width: 100%;
}

.note-container {
  padding: 8px;
}



.upper-case {
    text-transform: uppercase;
}


.pre { white-space: pre-line; }

#overlay-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.48);
    padding: 10px 80px 40px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#overlay-view #overlay-content {
    padding: 8px;
    background: #f2eeeb;
    border: 2px solid #ddd9d7;
    /*height: 100%;*/
    overflow: visible;
    max-width: 1600px;
    margin: auto;
}

#overlay-view .overlay-title {
    height: 40px;
    max-width: 1600px;
    margin: auto;
}

.to-drag {
  transition: all .3s ease-out !important;
}

.is-drag {
  transform: scale(1.1);
  z-index: 100 !important;
}

.calendar-picker {
    height: 0 !important;
    width: 0;
    border: none !important;
    position: absolute !important;
    left: 0;
    top: 30px;
}

ul.views-links li.link span.fa {
    display: inline-block;
    height: 40px;
    padding-top: 14px;
}

.close-overlay-view {
  color: #f5f3f2;
  cursor: pointer;
}

.hover-container .hover-show {
  display: none !important;
}

.hover-container:hover .hover-show {
  display: initial !important;
}

.hover-container:hover .hover-hide {
  display: none !important;
}

.bs-old-issues-map-modal{
  height: 96% !important;
  max-height: 96% !important;
  overflow: hidden !important;
}

.bs-old-issues-map-modal .modal-content {
  height: 100% !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bs-old-issues-map-modal .modal-content>.modal-header,
.bs-old-issues-map-modal .modal-content>.modal-footer {
  flex: 0 0 auto;
}

.bs-old-issues-map-modal .modal-content>.modal-body {
  flex: 1 1 0;
}