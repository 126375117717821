.c-popover-issue-wrapper {
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.c-popover-issue{
  background: white;
  box-shadow: $box-shadow-higher;
  width: 28rem;
  &.c-issue--select-active {
    margin-left: 22px !important;
    & .c-issue__primary{
      overflow: visible;
    }
  }
}

.c-popover-issue__in-list {
  border-radius: $card-border-radius;
  width: inherit;
}

//From react-popover
.Popover-tipShape{
  fill:$color-default;
}