
@import "./deleteDefault.css";
@import "./form.css";
@import "./date.css";
@import "./datetime.css";
@import "./hour.css";
@import "./select.css";
@import "./text.css";
@import "react-day-picker/lib/style.css";
@import "./normalizedCss.css";
@import "./tool.css";
@import "./file.css";
@import "./modal.css";

.c-field-mentions{
  overflow: hidden;
}
