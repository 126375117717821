.c-issues-list{
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom: 0;
  overflow: hidden;

  & .c-issue-infos .o-flag__img {
    width: 35px !important;
  }
}