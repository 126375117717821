.c-issue{
    position: relative;
    max-height: $size-xx-small;
    cursor: pointer;
    height: $size-xx-small;
    box-shadow: $box-shadow-high;
    margin:$spacing-small;

    &__image{
        position: absolute;
        top:0;
        left:0;
        width: $size-xx-small;
    }
    &__content{
        position: absolute;
        top:0;
        left:$size-xx-small;
        right:0;
        bottom: 0;
        padding:$spacing-tiny;
        display: flex;
        flex-direction: column;
    }

    &__infos{

    }
    &__meta{
        background:$color-grey-light;
        position: absolute;
        right:0;
        top:0;
        bottom: 0;
        width:$size-small;
        padding: $spacing-small;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-left:1px solid darken($color-grey-light,5%);
        border-top-right-radius:$border-radius;
        border-bottom-right-radius:$border-radius;

    }
    &__primary{
        position: absolute;
        left:0;
        top:0;
        bottom: 0;
        right:$size-small;
        padding: $spacing-tiny $spacing-small;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    &__valorization-total {
        margin-top: -4px;
        color: #5e676c;
    }
    &__description{
        padding:$spacing-tiny 0;
    }
    &__id{

    }

    &__state{
        position: absolute;
        top:0px;
        right:0;
        text-align: center;
    }

    &__location{
        display: flex;
        margin-top:$spacing-tiny;
        justify-content: center;
        align-items: center;
    }
    &__address{
        flex:1;
        overflow: hidden;
    }
    &__state-dropdown{
        //padding: 0 $spacing-tiny;
        position: absolute;
        right:$spacing-tiny;
        top:$spacing-medium;
    }
    &__state{
        position: absolute;
        right:0;
        top: 0px;
        padding: 0;
    }
    &__title{
        //display: flex;
        //align-items: center;
        overflow: hidden;
    }

    &.scheduled{
        background: darken(white, 6%);
    }
    &__priority{

    }
    &__wrapper{
        padding:$spacing-small 35px $spacing-tiny $spacing-tiny;
        &--stretch{
            padding-top: $spacing-tiny;
            padding-bottom: $spacing-tiny;
            &.c-issue__state-dropdown{
                top:18px;
            }
        }
    }
    &__selector{
        position: absolute;
        top: 45%;
        left: -20px;
        font-size: 14px;
    }
    &--select-active {
        margin-left: $spacing-medium;
        overflow: visible;
    }
}
.c-issue--a_low>div>.c-issue__content .c-issue__state{
  top: -9px;
}
.c-issue--mini{
    //overflow: visible;
    margin:$spacing-tiny;
}
.c-issue--large{
    max-height: none;
    height:  inherit;
    margin:0 auto 0 auto;
    padding: $spacing-small $spacing-medium;
    box-shadow: none;
    @extend .o-wrapper;
    .c-card{
        background: $color-white;
        max-height: $size-x-small;
        height: $size-x-small;
        overflow: visible;
        position: relative;
        box-shadow: $box-shadow-high;
    }
    .c-issue__content{
        left:$size-x-small;
        padding:0;
    }
    .c-issue__image{
        height: $size-x-small;
        width: $size-x-small;
    }
    .c-issue__description{
        padding: $spacing-tiny 0;
    }
    .c-issue__primary{
        justify-content: space-between;
    }
    .c-issue__address{
        //margin-top:0;
    }

    .c-issue-title{
        font-size: $text-font-size-medium;
    }
    .c-address{
        font-size: $text-font-size-small;
    }

}
.c-issue--mini{
    height:inherit;
    overflow: visible;
}

.c-issue--popover{
    box-shadow: none;
    margin: 0;
    max-height: none;
    height: inherit;
    .c-issue{
        &__primary{
            position: relative;
            padding: $spacing-small;
        }
        &__image{
            position: static;
            width: 100%;
            img{
                width: 100%;
            }
        }
        &__meta{
            position: static;
            background:none;
            width:auto;
            display: block;
            padding: 0 $spacing-small $spacing-tiny;
        }
        &__state{
            right:-2px;
            .caret{
                display: none;
            }
        }
    }
}

.c-issue-meta{
    &__value{
        color: $color-black;
        position: relative;
        display: inline-block;
        font-weight: 500;
    }
}

.c-issue--low-priority{
    .c-issue__priority{

    }
}
.c-issue--high-priority{
    .c-card{
        //background: mix($color-error, $color-white, 9%); //TODO Check if it should be enabled
    }
    .c-issue__priority{
        color:$color-error;
    }

}
.c-issue--middle-priority{
    .c-issue__priority{
        color:$color-warning;
    }
}

.c-issue-location .u-hide-for-print{
  height: 100%;
}