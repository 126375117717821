.c-issues-filter{
  margin-bottom: $spacing-medium;
  transition: all .2s;
  &--calendar{
    .c-issues-filter__inputs{
      @extend .o-layout;
    }
    .c-issues-filter__input{
      @extend .o-layout__item;
      width: (100%/3);
      margin:0;
    }
  }
}
.filter-period .DayPicker,
.filter-period .bs-select-values-container{
  color:#5e676c;
}
.filter-period .bs-input-date input{
  color:#fff !important;
}