.u-text-capitalize {
    text-transform:capitalize;
}

.u-text-italic {
    font-style:italic;
}

.u-text-bold {
    font-weight: bold;
}

.u-text-weight-normal {
    font-weight: normal;
}