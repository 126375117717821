
 .bs-input-wa-text {
  padding: 0px 10px;
  box-sizing: border-box;
  position: relative;
  height: 25px;
  margin: 0;
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-size: 12px;
  font-weight: 550;
  border-radius: 3px;
  width: 100%;
  outline: none;
}
