.bs-file>img { 
  display: block;
  height: unset;
}

.c-issue__image .bs-file > img {
  height: 100% !important;
}

.bs-file-pdf {
  background-image: "/images/pdf_icon.svg"
}


