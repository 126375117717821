.u-font-size-huge,
.u-huge {
    font-size: $text-font-size-huge;
}
.u-font-size-super,
.u-super {
    font-size: $text-font-size-super;
}
.u-font-size-xlarge,
.u-xlarge {
    font-size: $text-font-size-xlarge;
}
.u-font-size-large,
.u-large {
    font-size: $text-font-size-large;
}
.u-font-size-medium,
.u-medium {
    font-size: $text-font-size-medium;
}
.u-font-size-small,
.u-small {
    font-size: $text-font-size-small;
}
.u-font-size-xsmall,
.u-xsmall {
    font-size: $text-font-size-xsmall;
}
.u-font-size-xxsmall,
.u-xxsmall {
    font-size: $text-font-size-xxsmall;
}
.u-font-size-tiny,
.u-tiny {
    font-size: $text-font-size-tiny;
}