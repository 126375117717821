@import "../../../../../node_modules/blaze/scss/mixins/components.navs";

.c-nav {
  //@include nav--color;
  width: $nav-width;
  margin: $nav-margin;
  padding: $nav-padding;
  z-index: $nav-z-index;
  font-size:$text-font-size-small;
  text-align: right;
}

.c-nav__item,
.c-nav__content {
  display: block;
  height: $nav-height;
  padding: 0 $nav-item-padding;
  color: inherit;
  line-height: $nav-height;
  vertical-align: middle;

  .o-image {
    height: 100%;
  }
}

.c-nav__item {
  @include nav__item--color;
  text-decoration: none;
  cursor: pointer;
  height: 2.5rem;
  line-height: 2.5rem;
}

.c-nav--inline {
  .c-nav__item,
  .c-nav__content {
    display: inline-block;
  }

  .c-nav__item--right,
  .c-nav__content--right {
    float: right;
  }
}

.c-nav--light {
  @include nav--color(transparent, $nav-light-color);
  text-align:left;
  .c-nav__item{
    &:first-child{
      //border-left: 1px solid $nav-light-border-color;
    }
    &.active{
      //background: #f2eeeb;
      font-weight: $font-weight-extra-bold;
      color:$color-grey-super-dark;
      border-bottom: 1px solid $color-grey-super-dark;
    }
    &:hover{
      //background: darken($nav-light-background-color, $color-tint);
      color: $nav-color;
    }
  }
}

.c-nav--top {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;

}

.c-nav--bottom {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-nav--left {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
}

.c-nav--right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
}

.c-nav--fixed {
  position: fixed;
}

.c-nav__item--active {
  @include nav__item--active;
}

.c-nav__item--brand {
  @include nav__item--color($nav-item-brand-hover-background-color, $nav-item-brand-hover-color);

  &.c-nav__item--active {
    @include nav__item--active($nav-item-brand-active-background-color, $nav-item-brand-active-color);
  }
}

.c-nav__item--info {
  @include nav__item--color($nav-item-info-hover-background-color, $nav-item-info-hover-color);

  &.c-nav__item--active {
    @include nav__item--active($nav-item-info-active-background-color, $nav-item-info-active-color);
  }
}

.c-nav__item--warning {
  @include nav__item--color($color-warning);

  &.c-nav__item--active {
    @include nav__item--active($nav-item-warning-active-background-color, $nav-item-warning-active-color);
  }
}

.c-nav__item--success {
  @include nav__item--color($color-success);

  &.c-nav__item--active {
    @include nav__item--active($nav-item-success-active-background-color, $nav-item-success-active-color);
  }
}

.c-nav__item--error {
  @include nav__item--color($color-error);

  &.c-nav__item--active {
    @include nav__item--active($nav-item-error-active-background-color, $nav-item-error-active-color);
  }
}
