.c-issue-detail {
  @media screen {
    height: 100%;
    overflow: hidden;
  }
  cursor: auto;

  &__dropzone {
    @media screen {
      height: 100%;
    }
  }

  //*************
  //Main wrapper
  //************

  //Main wrapper
  &__wrapper {
    @media screen{
      display: flex;
      height: 100%;
    }
  }

  //Desc+ Images
  &__main {
    flex: 3;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
  }

  &__main-wrapper {
    @media screen {
      @include mq($from: tablet) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  //History
  &__meta {
    background: $color-grey-light;
    @media screen{
      border-left: 1px solid darken($color-grey-light,8%);
    }    
    width: 25%;
    overflow-y: auto;
  }

  //Hashtags
  &__hashtags {
    
  }

  //******************
  // Description area
  //******************

  &__title {
    clear: both;
  }
  &__content { 
    width: 66.66%;
    min-height: 100%;
    padding: $spacing-small;
    @media screen {
      border-left: 1px solid $border-color;
    }
  }

  &__images {
    width: 33.33%;
    padding: 0 $spacing-small 0 0;
  }

  &__description {
    padding-top: $spacing-small;
    padding-bottom: $spacing-medium;

    font-weight: $font-weight-medium;
    font-size: $text-font-size-large;
    color: $color-black;

  }

  &__state {
    position: absolute;
    top: $spacing-small;
    right: 0;
  }
  &__location{
    border:1px solid $color-grey-light;
  }
}