.overlay-filter.filter-list-openned {
  height: 100%;
}
.overlay-filter.open{
  height: 100%;
}
.c-page-calendar{
  display:flex;
  height:100%;
  &__sidebar{
    // position: absolute;
    width: $size-large;
    display: flex;
    // left:0;
    // bottom: 0;
    // top:0;
    flex-direction: column;
    border-right: 1px solid $color-grey-cloud;
    flex: 0 0 auto;
    &--collapsed{
      width: 3rem;
    }
  }

  &__content{
    // position: absolute;
    // right:0;
    display: flex;
    // left: $size-large;
    flex-direction: column;
    // bottom: 0;
    // top:0;
    &--full-size{
      left: 3rem;
    }
  }

  &__list{
    position: relative;
    flex:1;
  }
  &__filters{
    background: $color-sidebar;
    color:white;
    &.open{
      // position: absolute;
      // top:0;
      // bottom: 0;
      // left:0;
      // right: 0;
      // z-index: $z-over-content;
      overflow-y: auto;
      height: 100%;
    }
  }
  &__tools{
    padding: $spacing-small;
    padding-right: 0;
  }
  &__calendar{
    flex:1;
    padding: $spacing-small;
    overflow-y: hidden;
  }
}

.c-page-calendar-filter-button{
  position: absolute;
  top: 0;
  left: -2px;
  background-color: $color-sidebar;
  font-size: $text-font-size-large;
  .fa{
    line-height: 26px;
  }
  &:hover{
    background-color: darken($color-sidebar, $color-tint-dark);
  }
}