.bs-input-wa-date{
  position: relative;
  display: flex;
  flex-direction: row;
}
.bs-input-wa-date>div{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.bs-input-wa-date>div>div:nth-child(1){
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid;
  padding-left: 19px;
}
.bs-input-wa-date>div>div:nth-child(1)>input{
  font-size: 1rem;
  width: 120px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
}
.bs-input-wa-date>div>div:nth-child(2){
  z-index: 1;
  background-color: white;
  position: absolute;
  top: 100%;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.2)
}