.c-heading-huge{
  font-size: 24px;
}
.c-heading-large{
  font-size: $text-font-size-large,
}

.c-heading-medium{
  font-size: $text-font-size-medium;
  //font-weight:$font-weight-semi-bold;
}

.c-heading-small{
  font-size: $text-font-size-medium;
  font-weight:$font-weight-medium;
}
.c-heading-tiny{
  font-size: $text-font-size-small;
  font-weight:$font-weight-medium;
}