.c-smart-home{
  // position: absolute;
  // top:0;
  // right:0;
  // bottom:0;
  // left:0;
  overflow-y: scroll;
  &__content{
    padding: $spacing-medium;
    flex:1;
  }
  &__item{
    height:$size-x-large;
    position: relative;
  }
  &__weather{
    height: 150px;
  }
}