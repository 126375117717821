.o-button-group-selector{
  border:1px solid $border-color;
  //display: flex;
  display: inline-flex;
  border-radius: $border-radius-large;

  &__item{
    position: relative;
    transition: all .3s ease-out;
    //display: inline-block;
    font-size: $text-font-size-small;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;

    padding: 0 12px;
    line-height: 2rem;
    border-right:1px solid $border-color;
    cursor: pointer;
    color: darken(white,30%);
    &:last-child{
      border-right: none;
      border-radius: $border-radius;
    }
    &:first-child{
      border-top-left-radius: $border-radius-large;
      border-bottom-left-radius: $border-radius-large;
    }
    &:hover{
      background: darken(white,3%);
    }
    &:hover,
    &.active{
      color:$color-black;
      //border:1px solid $border-color;
      //border-right:2px solid $border-color;
      //border:none
    }
    &.active{
      font-weight: $font-weight-semi-bold;
    }
    input{
      /*
      position: absolute;
      left: 0;
      top:0;
      right:0;
      visibility: hidden;
      */
    }
  }
  &--block{
    display: block;
    flex-wrap: nowrap;
    //position: relative;
    //align-items: stretch;
    //display: block;
    .o-button-group-selector{
      &__item{
        display: inline-block;
      }
    }
    .fa{
      line-height: inherit;
    }
  }
}
