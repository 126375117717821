.c-button {
  @include button;
}

.c-button__invisible {
  border: none;
  &:focus {
    border: none;
    outline: 0;
  }
}

a.c-button{
  &:hover{
    color:white;
  }
}

.c-button--close {
  @include button-color(transparent, inherit);
  position: absolute;
  right: $spacing-small;
  top:0;
  font-size: 1.5rem;
  @include mq($from: desktop) {
    right: $spacing-medium;

  }
  @include mq($from: wide) {
    right: $spacing-large;
    font-size: $button-close-font-size;
  }

  padding: 0;
  outline: 0;

  font-weight: $button-close-font-weight;
  line-height: 1;
  z-index: $modal-z-index + 1;
  color:$color-white;
}

.c-button--block {
  display: inline-block;
  width: 100%;
}

.c-button--rounded {
  border-radius: $button-rounded-border-radius;
}

.c-button--brand {
  @include button-color($button-brand-background-color, $button-brand-color);
}

.c-button--info {
  @include button-color($button-info-background-color, $button-info-color);
}

.c-button--warning {
  @include button-color($button-warning-background-color, $button-warning-color);
}

.c-button--success {
  @include button-color($button-success-background-color, $button-success-color);
}

.c-button--error {
  @include button-color($button-error-background-color, $button-error-color);
}

.c-button--ghost {
  @include button-ghost;
}

.c-button--ghost-brand {
  @include button-ghost($button-ghost-brand-color, $button-ghost-brand-hover-color);
}

.c-button--ghost-info {
  @include button-ghost($button-ghost-info-color, $button-ghost-info-hover-color);
}

.c-button--ghost-warning {
  @include button-ghost($button-ghost-warning-color, $button-ghost-warning-hover-color);
}

.c-button--ghost-success {
  @include button-ghost($button-ghost-success-color, $button-ghost-success-hover-color);
}

.c-button--ghost-error {
  @include button-ghost($button-ghost-error-color, $button-ghost-error-hover-color);
}

.c-button--ghost-action {
  @include button-ghost($button-ghost-action-color, $button-ghost-action-hover-color);
  border-color:$border-color;
}

.c-button--ghost-white {
  @include button-ghost(white, rgba(255,255,255,.3));
  background-color: white;


  &:not(:disabled) {
    &:hover {
      background-color: rgba(255,255,255,.3);
      color: white;
    }
  }
}

.c-button__icon-left {
  padding-right: $button-icon-left-padding;
}

.c-button__icon-right {
  padding-left: $button-icon-right-padding;
}
