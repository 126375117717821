.u-pull-right{
  float: right;
}
.u-pull-left{
  float: left;
}
.u-afp,
.u-absolute-fill-parent{
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right:0;
}

.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

@each $name in $vertical-directions {
  .u-vertical-align-#{$name} {
    vertical-align: #{$name} !important;
  }
}