.c-link-block{
  cursor:pointer;
  background:white;
  border:1px solid $border-color;
  display:block;
  text-align:center;
  padding:$spacing-medium;
  color:$color-grey-nevada;
  transition:all .3s ease-out;
  &__title{
    margin-top:$spacing-tiny;
  }
  &:hover{
    background:darken($color-white,5%);
    color:darken($color-grey-nevada,5%);
  }
}