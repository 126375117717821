.c-issue-infos{
  font-size: $text-font-size-small;
  text-align: right;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  &__item{

  }
}