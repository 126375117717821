.u-display-block{
  display: block;
}

.u-display-inline-block{
  display: inline-block;
}

.u-display-none{
  display: none;
}

.u-show-for-print {
  display: none;
}

.u-show-hover{
  display: none;
  &:hover{
    display: initial;
  }
}

.u-show-hover-container__item{
  display:none;
}

.u-show-hover-container:hover .u-show-hover-container__item {
  display: initial;
}


@media print {
  .u-hide-for-print {
    display: none;
  }
  .u-show-for-print {
    display: initial;
  }
}

.u-zindex-400 {
  z-index: 400 !important;
}
.u-zindex-300 {
  z-index: 300 !important;
}

.u-zindex-1000 {
  z-index: 1000 !important;
}

.u-fill-height {
  height: 100%;
}

.u-overflow-visible {
  overflow: visible;
}
.u-overflow-scroll {
  overflow: scroll !important;
  height: 100%;
}