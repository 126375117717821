.c-address {
  font-style: $address-font-style;
  font-size: $text-font-size-small;
  color:map_get($colors,green);
  font-family: $text-font-family-content;
  font-weight: $font-weight-regular;

  /* BOTH of the following are required for text-overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__heading{
    font-size: $text-font-size-medium;
    margin-right: $spacing-tiny;
    vertical-align: middle;
    text-overflow: ellipsis;
  }
  &__secondary{
    font-size: $text-font-size-small;
    vertical-align: middle;
  }
  &>*{
    display: inline-block;
    vertical-align: middle;
  }
}

.c-address--short{
  .c-address__heading{
    font-size: $text-font-size-small;
  }
}
.c-address--full{
  white-space: normal;
  overflow: auto;
  text-overflow: ellipsis;
  &>*{
    display:block;
    margin:0;
  }
}