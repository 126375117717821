.c-issue-micro{
  background:white;
  padding:$spacing-tiny;
  overflow: hidden;
  //margin:$spacing-tiny;
  height:100%;
  //margin:$spacing-tiny;
  border: 1px solid #f2eeeb;
  vertical-align: middle;
  min-height:$size-xxx-small;
  position: relative;
  display: flex;
  align-items: center;
  &__state{
    line-height: 0px;
    position: absolute;
    right:0;
    top:0;
  }
  /*
  &.open{
    border-color:$color-state-open;
  }
  &.resolved{
    border-color:$color-state-resolved;
  }
  &.transferred{
    border-color:$color-state-transferred;
  }
  &.in_progress{
    border-color:$color-state-in_progress;
  }
  &.planned{
    border-color:$color-state-planned;
  }
  &.rejected {
    border-color: $color-state-rejected;
  }
  &.deleted {
    border-color: $color-state-deleted;
  }
  */
}