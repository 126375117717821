.c-text-xxx-large{
  font-size : 28px; 
}

.c-text-xx-large{
  font-size : 20px; 
}

.c-text-x-large{
  font-size : 18px;
}

.c-text-large{
  font-size : $text-font-size-large;
}
.c-text-medium{
  font-size : $text-font-size-medium;
}
.c-text-small{
  font-size : $text-font-size-small;
}
.c-text-tiny{
  font-size : $text-font-size-xsmall;
}
.c-text-light{
  font-size : $text-font-size-small;
  font-style:italic;
}

.c-text-address-large{
  font-size : $text-font-size-medium;
  font-weight: normal;
  color: rgb(92,119,92);
}