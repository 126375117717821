.modal-backdrop, .modal-backdrop.fade.in {
  opacity: 0.3 !important;
}
.modal-header{
  display: flex;
  flex-direction: row-reverse;
}
.modal-title{
  flex-grow: 1;
}
.bs-header-modal{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.bs-header-modal>*:first-child{
  flex-grow: 1;
}
.bs-modal-97w{
  max-width: 97%;
}