.r-ss-wrap {
  margin: 0;
  & .r-ss-trigger {
    display: inline-block;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    padding: 0 $spacing-tiny $spacing-tiny;
    width: 100%;
    line-height: 14px;
    font-size: $text-font-size-medium;
    &.r-ss-open {
      border-bottom: none;
      padding: 0;
    }
  }

  & .r-ss-trigger .carat {
    border: 5px solid $color-default;
    bottom: -1px !important;
    border-color: $color-default transparent transparent transparent !important;
    margin-right: 4px;
  }

  & .r-ss-dropdown {
    margin-top: $spacing-tiny;
    border-radius: $border-radius;
    border-top: 1px solid $border-color;
    max-height: inherit;
    & .r-ss-dropdown-options li {
      padding: $spacing-tiny $spacing-tiny;
      background-color: white;
      font-size: 12px;
      font-weight: 600;
      text-transform: none;
      color: #5e676c;
      cursor: pointer;
      &:hover {
        color: #5e666c !important;
        text-decoration: none !important;
        background-color: #f9f7f5 !important;
        border: 1px solid rgba(51, 51, 51, 0.1);
        border-top: none;
      }
    }
  }

  & .r-ss-search-inner input {
    font-size: $text-font-size-small;
    border: none;
  }
  
  &.hide-value{
    & .r-ss-trigger.r-ss-open.r-ss-placeholder{
      padding: 0;
      border: none;
    }
    & .r-ss-trigger.r-ss-open.r-ss-placeholder .carat{
      display: none;
    }
    & .r-ss-dropdown {
      border-radius:$border-radius;
    }
  }
}

.r-ss-dropdown .r-ss-dropdown-option:focus, .r-ss-dropdown .r-ss-dropdown-option::selection {
  color: #5e666c !important;
  text-decoration: none !important;
  background-color: #f9f7f5 !important;
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-top: none;
}

.r-ss-wrap .r-ss-dropdown .r-ss-dropdown-options li {
  font-size: 0.9em;
  padding: 8px;
}

/*
.r-ss-trigger {
  padding: 0;
  line-height: 19px;
  border: none;
  color: #5e676c;
}
.r-ss-trigger .carat, .r-ss-trigger .carat.down, .r-ss-trigger .carat.up {
  border: 3px solid transparent;
  border-color: #5e676c transparent transparent transparent;
}
*/

#add-issue-form .r-ss-wrap .r-ss-trigger .carat {
  bottom: 4px !important;
}