.c-issues-button-group{
  border-radius: $border-radius;
  text-align: center;
  color:#f9f7f5;
  &__btn{
    text-align: center;
    padding: $spacing-small;
    font-size: $text-font-size-medium;
    &.active{
      border-bottom: 1px solid #fff;
      //color:$color-black;
      color:#fff;
    }
  }
  &--navbar{
    text-align: left;
    border-bottom: none;
    .c-issues-button-group__btn{
      display: inline-block;
      padding: $spacing-tiny;
      &.active{
        color:$color-black;
        border-bottom: 1px solid $color-black;
      }
    }

  }
}