.__react_component_tooltip {
    padding: 0 !important;
}

.__react_component_tooltip.show {
    opacity: 1 !important;
}

.__react_component_tooltip.type-light {
    padding: 30px !important;
    background-color: initial !important;
}
.__react_component_tooltip.place-right:after {
    left: 23px !important;
}
.__react_component_tooltip.place-left:after {
    right: 23px !important;
}
.__react_component_tooltip.place-bottom:after {
    top: 24px !important;
}
.__react_component_tooltip.place-top:after {
    bottom: 24px !important;
}