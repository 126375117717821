.u-text-align{
  &-center{
    text-align: center;
  }
  &-left{
    text-align: left;
  }
  &-right{
    text-align: right;
  }
}