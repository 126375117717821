//
// Legacy
//

@import '../../../../node_modules/font-awesome/css/font-awesome.css';
@import '../../../../node_modules/flatpickr/dist/flatpickr.min.css';
//@import '../../../../node_modules/react-select/dist/react-select.css';
@import '../../../../node_modules/animate.css/animate.css';
@import '../../../../node_modules/react-super-select/lib/react-super-select.css';
// @import '../../../../node_modules/react-table/react-table.css';
// @import '../../../../node_modules/mapbox-gl/src/css/mapbox-gl.css';

@import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import '../../../../node_modules/antd/lib/select/style/index.css';
//@import '../../../../node_modules/antd/lib/tree-select/style/index.css';

@import './lib/bootstrap-2/bootstrap.min.css';
//@import './lib/bootstrap-2/responsive.min.css';


@import './lib/icomoon/icomoon.css';
//@import '../../../../node_modules/react-date-picker/index.css';
@import 'semantic-ui-css/semantic.min.css';
@import "./new/_import.css";

@import './betterstreet';
@import './style.css';
@import './animations.css';


//-@import '../../../../node_modules/react-perf-tool/lib/styles.css';


//SETTINGS
@import './settings/settings.config';
@import "../../../../node_modules/inuitcss/settings/settings.core";
@import './settings/settings.global';

//TOOLS
@import "../../../../node_modules/inuitcss/tools/tools.clearfix";
@import "../../../../node_modules/sass-mq/mq";
@import "./tools/buttons";
@import "./tools/padding";
@import "./tools/arrows";

//GENERIC
//@import "../../../../node_modules/blaze/scss/generics.global";
@import './generics/generics.global';

//OBJECTS
@import "../../../../node_modules/inuitcss/objects/objects.media";
@import "../../../../node_modules/inuitcss/objects/objects.layout";
@import "../../../../node_modules/inuitcss/objects/objects.flag";
@import "../../../../node_modules/inuitcss/objects/objects.wrapper";
@import "objects/objects.wrapper";
@import "objects/objects.dropdown";
@import "objects/objects.flex-layout";


@import "../../../../node_modules/blaze/scss/objects.containers";
@import "../../../../node_modules/blaze/scss/objects.grid";
@import "../../../../node_modules/blaze/scss/objects.grid.responsive";
@import "../../../../node_modules/blaze/scss/objects.images";
//@import "../../../../node_modules/blaze/scss/objects.panels";
@import "../../../../node_modules/blaze/scss/objects.forms";
//@import "../../../../node_modules/blaze/scss/objects.modals";
@import "../../../../node_modules/blaze/scss/objects.drawers";

@import "objects/objects.modals";
@import "objects/objects.panels";
//@import "objects/objects.grid";
@import "objects/objects.layout";
@import "objects/objects.button-group-selector";

//COMPONENTS
//@import "../../../../node_modules/blaze/scss/components.typography";
@import "../../../../node_modules/blaze/scss/components.badges";
@import "../../../../node_modules/blaze/scss/components.headings";
@import "../../../../node_modules/blaze/scss/components.tables";
//@import "../../../../node_modules/blaze/scss/components.cards";
@import "../../../../node_modules/blaze/scss/components.links";
@import "../../../../node_modules/blaze/scss/components.lists";
@import "../../../../node_modules/blaze/scss/components.breadcrumbs";
@import "../../../../node_modules/blaze/scss/components.trees";
@import "../../../../node_modules/blaze/scss/components.tabs";
@import "../../../../node_modules/blaze/scss/components.inputs";
@import "../../../../node_modules/blaze/scss/components.input-groups";
@import "../../../../node_modules/blaze/scss/components.hints";
@import "../../../../node_modules/blaze/scss/components.toggles";
@import "../../../../node_modules/blaze/scss/components.tags";
@import "../../../../node_modules/blaze/scss/components.ranges";
//@import "../../../../node_modules/blaze/scss/components.overlays";
@import "../../../../node_modules/blaze/scss/components.bubbles";
@import "../../../../node_modules/blaze/scss/components.tooltips";
@import "../../../../node_modules/blaze/scss/components.alerts";
@import "../../../../node_modules/blaze/scss/components.progress";
//@import "../../../../node_modules/blaze/scss/components.avatars";

@import './components/overlay-view';
@import './components/overlays';
@import './components/buttons';
@import './components/cards';
@import './components/navs';
@import './components/notifications';
//@import './components/calendars';
//@import './components/pagination';
@import './components/headings';
@import './components/typography';
@import './components/text';
@import './components/address';
@import './components/field-textarea';
@import './components/select-editable';
@import './components/dropdown-menu';
@import './components/avatars';
@import './components/hashtags';

@import './components/link-block';

@import './components/button-group-selector-priority';

@import './components/app';
@import './components/site-nav';
@import './components/navbar';
@import './components/brand';

@import './components/issue';
@import './components/issue-micro';
@import './components/issue-state';
@import './components/issue-title';
@import './components/issue-tag';
@import './components/issue-location';
@import './components/issue-id';
@import './components/issue-description';
@import './components/issue-infos';
@import './components/issue-deadline';
@import './components/issue-dates';
@import './components/issue-country';
@import './components/issue-valorization';

@import './components/issue-list';
@import './components/issue-form';

@import './components/issue-detail';
@import './components/issue-detail-description';
@import './components/issue-detail-title';
@import './components/issue-detail-media';
@import './components/issue-detail-field';
@import './components/issue-detail-field-date';
@import './components/issue-detail-public-message';

@import './components/action-link';

@import './components/calendar';
@import './components/calendar-tools';
@import './components/calendar-header';

@import './components/issue-history';
@import './components/issue-history-day';
@import './components/activity-log';
@import './components/issue-log';
@import './components/issue-log-comment';
@import './components/weather';
@import './components/panel';
@import './components/date';
@import './components/more-link';
@import './components/badge';
@import './components/tabs';
@import './components/confirmation';

//Stats components
@import './components/stats-card';

//Console components
@import './components/console';


//pages
@import './components/logged';
@import './components/issues-button-group';
@import './components/issues';
@import './components/issues-map';
@import './components/issues-navbar';
@import './components/issues-alerts';
@import './components/issues-filters';
@import './components/issues-filter';
@import './components/issues-filter-head';
@import './components/issues-list';
@import './components/issues-toolbar';
@import './components/issues-loading-counter';
@import './components/smart-home';
@import './components/page-calendar';
@import './components/popover-issue';
@import './components/picture-modal';
@import './components/anonymous';

//Utilities
@import "../../../../node_modules/inuitcss/utilities/utilities.widths";

@import "../../../../node_modules/blaze/scss/utilities.alignment";
@import "../../../../node_modules/blaze/scss/utilities.boxing";
@import "../../../../node_modules/blaze/scss/utilities.elevation";
@import "./utilities/utilities.spacings";
@import "./utilities/utilities.colors";
@import "./utilities/utilities.text-alignment";
@import "./utilities/utilities.display";
@import "./utilities/utilities.position";
@import "./utilities/utilities.text";
@import "./utilities/utilities.interaction";
@import "./utilities/utilities.border";
@import "./utilities/utilities.sizes";

//Trumps
@import "./trumps/trumps.inputs";
@import "./trumps/trumps.react-tooltip";
@import "./trumps/_trumps-flatpickr";
@import "./trumps/_react-tags";
@import "./trumps/_react-table";
@import "./trumps/_bootstrap-modal";
@import "./trumps/_react-super-select";

@import "./antibootstrap.css";

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
body>.modal-backdrop.show:not(:last-child),
body>.modal.show:not(:last-child){
  display: none !important;
}
.modal-body{
  overflow-y: auto;
}

