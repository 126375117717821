.c-issues-map{
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
}

.c-text-over-map{
  position: absolute;
  top: 10px;
  right: 50px;
  background: rgba(255, 255, 255, 0.8);
  padding: $spacing-xtiny $spacing-small;
  z-index: 10;
  //border: 1px solid $border-color;
  color: $color-green;
}


.c-map--control {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  font-size: 12px;
  border-radius: 2px;
  overflow: hidden;
  padding: 5px 8px;
  cursor: pointer;
  float: left;
  background: rgb(249, 249, 249);
  font-weight: 600;
  position: relative;
  top: 9px;
  left: 20px;
}

.c-map--control__adapt-list {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  font-size: 12px;
  border-radius: 2px;
  overflow: hidden;
  padding: 5px 8px;
  cursor: pointer;
  float: left;
  background: #e84c25;
  font-weight: 600;
  position: absolute;
  top: 10px;
  left: 45%;
  z-index: 100;
  color: white;
  &:hover {
    color: white;
  }
}