.c-action-link{
  display: inline-block;
  cursor: pointer;
  position: relative;
  &__value{
    color:$color-black;
    position: relative;
    display: inline-block;
    font-weight: $font-weight-medium;
    &:hover:after{
      content:'';
      position: absolute;
      bottom: 0;
      width:100%;
      left:0;
      border-bottom: 1px solid $color-black;
    }

  }
  //Limited profile
  &.disabled{
    cursor: auto;
    .c-action-link__value{
      &:hover:after{
        border-bottom: 0;
      }
    }
  }
  &__confirmation{
    display: inline-block;
    padding: 0 $spacing-tiny;
  }
  &__indicators{
    position: absolute;
    right:0;
    top:50%;
    transform: translate(0,-50%);
    color:$color-default;
  }
  &__indicator{
    &:last-child{
      display: none;
      font-size: 14px;
    }
  }
  &--empty{
    font-style: italic;
    .c-action-link__value{
      color: darken(white,30%);
      font-weight: $font-weight-regular;
    }
  }
  &--muted{
    .c-action-link__value{
      font-weight: $font-weight-regular;
    }
  }
  &:hover{
    .c-action-link__indicator{
      &:first-child{
        display: none;
      }
      &:last-child{
        display: inline-block;
      }
    }
  }
}