.c-pictures-modal{
  display:-ms-grid;
  display:grid;

  -ms-grid-columns: $size-medium 1fr;
  grid-template-columns: $size-medium 1fr;
  -ms-grid-rows: min-content $size-x-large;
  grid-template-rows: min-content $size-x-large;

  grid-template-areas: 'list actions' 'list images';

  &__list{
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: list;
    position: relative;
  }
  &__actions{
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: actions;
  }
  &__images{
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-area: images;
    position: relative;
  }
}

.c-issue-pictures-modal-gallery,
.c-issue-pictures-modal-list{
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.c-issue-pictures-modal-list{
  overflow-y: scroll;

  &__item{
    margin:$spacing-tiny;
    &.active{
      img{
        border-color:$color-brand;
      }
    }
  }
  &__image{

  }
}

.c-issue-pictures-modal-gallery{
  overflow-y: hidden;
  &__container{
    height: 100%;
    position: relative;
  }
  &__chevron{
    background: none;
    border: none;
    padding: $spacing-small;
  }
}

.c-issue-pictures-modal-picture{
  position: absolute;
  bottom: 0;
  left:0;
  right:0;
  &__image{
    height: 100%;
    width: auto;
    max-width: 100%;
  }
  &__body{
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    color:black;
    padding: $spacing-tiny;
    background: rgba(255,255,255,.75);
  }
}

.c-issue-pictures-modal-list{
  &__item{
    display: block;
    position: relative;
  }
  &__check{
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.5);
    color:$color-brand;
  }
}

