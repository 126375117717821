.c-issue-detail-public-message{
  padding: $spacing-medium 0;
  &__comment{
    padding: $spacing-small 0;
    border-bottom: 1px solid $border-color;
    &:last-child{
      border: none;
    }
  }
  &__message{
    background: darken(white,5%);
    border-left: 1px solid $border-color;
    margin:0;
    padding:$spacing-small;
    margin-right:$spacing-small;

  }
}