.c-issues-filters{
  transition: all .3s;
  &--collapsed{
    background: $color-sidebar;
    width: 100%;
    height: 100%;
    padding-top: $spacing-small;
    cursor: pointer;
    text-align: center;
  }
  &__field{
    &__action{
      display: none;
    }
  }
  &__field{
    &:hover{
      .c-issues-filters__field__action{
        display: inline;
      }
    }
  }
}

.c-issues--list .c-issues-filters.c-issues-filters--collapsed {
  width: 3rem;
}