@media screen{
  .o-panel-container {
    // position: relative;
  }

  .o-panel {
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .o-panel--nav-top {
    top: $panel-nav-top;
  }

  .o-panel--nav-bottom {
    bottom: $panel-nav-bottom;
  }

  .c-card__body .o-panel {
    padding: $card-item-padding;
  }
}
