//
// Colors
//

@each $name, $value in $base-colors {
  .u-color-#{$name} {
    color: $value !important;
  }

  .u-bg-#{$name} {
    background-color: $value !important;
  }
}

.u-bg-none {
  background: none !important;
}

.bs-supplyType-formModal {
  height: 36px;
  padding: 0 0 0 0.5em;
}

.u-opacity-light {
  opacity: 0.3 !important;
}