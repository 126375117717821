.bs-field-wa {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-field-wa-label {
  color: #2c3e50;
  font-size: 0.9rem;
  width: 19%;
  flex: 0 0 auto;
}
.o-layout__item.u-1of2-wide .bs-field-wa-label{
  width: 33.33333%;
}
.bs-field-wa-input {
  flex: 1 1 0;
}
.bs-input-wa-nullable{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-input-wa-nullable-button{
  background-color: #fa7251;
  opacity: 0.9;
  color: white;
  padding: 5px 10px;
  line-height: 1;
  margin-left: 10px;
}
.bs-input-wa-nullable-button:hover{
  cursor: pointer;
}