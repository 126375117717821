.c-issue-valorization {
  margin: $spacing-small 0;
  &-summary{
    &__item{
      display: inline-block;
      padding: $spacing-tiny 0;
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      overflow: hidden;
      color: white;
      &--UserPro{
        background-color: $color-orange;
      }
      &--Patrimony{
        background-color: $color-blue;
      }
      &--Supply{
        background-color: $color-green;
      }
      &--ExternalService{
        background-color: $color-purple;
      }
    }
  }
}