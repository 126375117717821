$inuit-fractions: 1 2 3 4 5 12!default;

//
/// Screen Sizes
//

$mq-breakpoints: (
        mobile:  320px,
        tablet:  740px,
        desktop: 980px,
        wide:    1300px,
        large:    1500px
);

$screen-limit-xsmall: 20rem;
$screen-limit-small: 30rem;
$screen-limit-medium: 48rem;
$screen-limit-large: 64rem;
$screen-limit-xlarge: 78rem;
$screen-limit-super: 116rem;

$screen-adjustment: .01;

$screen-limits: (
        xsmall: $screen-limit-xsmall,
        small: $screen-limit-small,
        medium: $screen-limit-medium,
        large: $screen-limit-large,
        xlarge: $screen-limit-xlarge,
        super: $screen-limit-super
);

//
// Spacing
//
/*
$spacing-huge: 3rem;
$spacing-super: $spacing-huge;
$spacing-xlarge: 2rem;
$spacing-large: 1.5rem;
$spacing-medium: 1rem;
$spacing-small: .5rem;
$spacing-xsmall: .25rem;
$spacing-tiny: .125rem;
*/
$spacing-huge: $inuit-global-spacing-unit-huge;
$spacing-super: $inuit-global-spacing-unit-huge;
$spacing-xlarge: 2rem;
$spacing-large: $inuit-global-spacing-unit-large;
$spacing-medium: $inuit-global-spacing-unit;
$spacing-small: $inuit-global-spacing-unit-small;
$spacing-xsmall: .25rem;
$spacing-tiny: $inuit-global-spacing-unit-tiny;
$spacing-xtiny: 2px;


$relative-spacing-super: 3em !default;
$relative-spacing-xlarge: 2em !default;
$relative-spacing-large: 1.5em !default;
$relative-spacing-medium: 1em !default;
$relative-spacing-small: .5em !default;
$relative-spacing-xsmall: .25em !default;
$relative-spacing-tiny: .125em !default;

//
// Sizes
//

$size-x-tiny : 1rem;
$size-tiny : 1.5rem;
$size-xxx-small : 3rem;
$size-xx-small : 6rem;
$size-x-small : 10rem;
$size-small : 15rem;
$size-medium : 20rem;
$size-large : 25rem;
$size-x-large : 40rem;
$size-xx-large : 60rem;

//
// Grid
//
$grid-gutter: 1rem;
$grid-widths: (
        5: 5 / 100,
        10: 1 / 10,
        15: 15 / 100,
        20: 1 / 5,
        25: 1 / 4,
        30: 3 / 10,
        33: 1 / 3,
        35: 35 / 100,
        40: 2 / 5,
        45: 45 / 100,
        50: 1 / 2,
        55: 55 / 100,
        60: 3 / 5,
        65: 65 / 100,
        66: 2 / 3,
        70: 7 / 10,
        75: 3 / 4,
        80: 4 / 5,
        85: 85 / 100,
        90: 9 / 10,
        95: 95 / 100,
        100: 1
);



//
// Colors
//

//Legacy colors

$color-state-open: #f77448;
$color-state-resolved: #89cc1f;
$color-state-transferred: #e78fa5;
$color-state-in_progress: #6b98b5;
$color-state-planned: #5dc9e6;
$color-state-rejected: #aaaa5a;
$color-state-deleted: #a09696;
$color-state-merged: #e01a6c;

$color-state-ongoing: #396988;
$color-state-to_do: #f77448;
$color-state-finished: #1fcc36;

$color-grey-pampas-light: #f9f7f5;
$color-grey-pampas-dark: #F2EEEB;
$color-grey-gallery: #efefef;
$color-grey-alto: #dbdbdb;
$color-grey-silver: #bdbdbd;
$color-grey-gray: #8b8b8b;
$color-grey-boulder: #7c7c7c;
$color-grey-swiss-coffee: #DDD9D7;
$color-grey-cloud: #cecbc8;
$color-grey-nevada: #5e666c;

$color-calendar-assign: darken($color-grey-gallery, 5%);

$color-background : #F2EEEb;
$color-global-background: #ececec;
$color-grey-light : #efefef;
$color-grey-default : #dbdbdb;
$color-grey-dark : #bdbdbd;
$color-grey-darker : #8b8b8b;
$color-grey-super-dark : #787878;
$color-grey : #f9f7f5;
$color-grey-blue: #eaeaea;
$color-grey-table-header: #f5f5f5;

$color-header-subnav : $color-grey-pampas-light;
$color-header-subnav-border : $color-grey-pampas-dark;
$color-page : $color-grey-pampas-dark;
$color-page-alt : $color-grey-pampas-light;

//Blaze colors

$color-tint: 9;
$color-tint-dark: $color-tint;
$color-tint-darker: $color-tint * 2;
$color-tint-light: $color-tint;
$color-tint-lighter: $color-tint * 1.5;

$color-black: #2c3e50;
$color-grey: #aaa;
$color-white: #FFF;
$color-beige: #F2F2EA;
$color-red: #c0392b;
$color-pink: #E91E63;
$color-purple: #9C27B0;
$color-blue: #2980b9;
$color-green: #27ae60;
$color-cyan: #00BCD4;
$color-yellow: #FFEB3B;
$color-orange: #FF9800;
$color-brown: #795548;

$color-brand: #E84C24;
$color-info: $color-blue;
$color-warning: $color-orange;
$color-success: $color-green;
$color-error: $color-red;
$color-button-link: #acaaa8;

$color-darker: darken($color-grey, $color-tint-darker);
$color-dark: darken($color-grey, $color-tint-dark);
$color-default: $color-grey;
$color-disabled: darken($color-grey, $color-tint-dark);
$color-quiet: lighten($color-grey, $color-tint-light);
$color-muted: lighten($color-grey, $color-tint-lighter);

$color-sidebar: #867185;
$color-sidebar-dark: darken(#867185,5%);

$colors: (
        black: $color-black,
        grey: $color-grey,
        white: $color-white,
        beige: $color-beige,
        red: $color-red,
        pink: $color-pink,
        purple: $color-purple,
        blue: $color-blue,
        green: $color-green,
        cyan: $color-cyan,
        yellow: $color-yellow,
        orange: $color-brand,
        brown: $color-brown,
        brand: $color-brand,
        info: $color-info,
        warning: $color-warning,
        success: $color-success,
        error: $color-error,
        default: $color-default,
        disabled: $color-disabled,
        quiet: $color-quiet,
        gray-light: $color-grey-light,
        grey-dark: darken($color-grey, $color-tint-dark),
        red-dark: darken($color-red, $color-tint-dark),
        pink-dark: darken($color-pink, $color-tint-dark),
        purple-dark: darken($color-purple, $color-tint-dark),
        blue-dark: darken($color-blue, $color-tint-dark),
        green-dark: darken($color-green, $color-tint-dark),
        cyan-dark: darken($color-cyan, $color-tint-dark),
        yellow-dark: darken($color-yellow, $color-tint-dark),
        orange-dark: darken($color-orange, $color-tint-dark),
        brown-dark: darken($color-brown, $color-tint-dark),
        grey-darker: darken($color-grey, $color-tint-darker),
        red-darker: darken($color-red, $color-tint-darker),
        grey-light: lighten($color-grey, $color-tint-light),
        red-light: lighten($color-red, $color-tint-light),
        pink-light: lighten($color-pink, $color-tint-light),
        purple-light: lighten($color-purple, $color-tint-light),
        blue-light: lighten($color-blue, $color-tint-light),
        green-light: lighten($color-green, $color-tint-light),
        cyan-light: lighten($color-cyan, $color-tint-light),
        yellow-light: lighten($color-yellow, $color-tint-light),
        orange-light: lighten($color-orange, $color-tint-light),
        brown-light: lighten($color-brown, $color-tint-light),
        grey-lighter: lighten($color-grey, $color-tint-lighter),
        red-lighter: lighten($color-red, $color-tint-lighter),
        pink-lighter: lighten($color-pink, $color-tint-lighter),
        purple-lighter: lighten($color-purple, $color-tint-lighter),
        blue-lighter: lighten($color-blue, $color-tint-lighter),
        green-lighter: lighten($color-green, $color-tint-lighter),
        cyan-lighter: lighten($color-cyan, $color-tint-lighter),
        yellow-lighter: lighten($color-yellow, $color-tint-lighter),
        orange-lighter: lighten($color-orange, $color-tint-lighter),
        brown-lighter: lighten($color-brown, $color-tint-lighter),
        pink-darker: darken($color-pink, $color-tint-darker),
        purple-darker: darken($color-purple, $color-tint-darker),
        blue-darker: darken($color-blue, $color-tint-darker),
        green-darker: darken($color-green, $color-tint-darker),
        cyan-darker: darken($color-cyan, $color-tint-darker),
        yellow-darker: darken($color-yellow, $color-tint-darker),
        orange-darker: darken($color-orange, $color-tint-darker),
        brown-darker: darken($color-brown, $color-tint-darker),
        brand-dark: darken($color-brand, $color-tint-dark),
        brand-darker: darken($color-brand, $color-tint-darker),
        brand-light: lighten($color-brand, $color-tint-light),
        brand-lighter: lighten($color-brand, $color-tint-lighter),
        info-dark: darken($color-info, $color-tint-dark),
        info-darker: darken($color-info, $color-tint-darker),
        info-light: lighten($color-info, $color-tint-light),
        info-lighter: lighten($color-info, $color-tint-lighter),
        warning-dark: darken($color-warning, $color-tint-dark),
        warning-darker: darken($color-warning, $color-tint-darker),
        warning-light: lighten($color-warning, $color-tint-light),
        warning-lighter: lighten($color-warning, $color-tint-lighter),
        success-dark: darken($color-success, $color-tint-dark),
        success-darker: darken($color-success, $color-tint-darker),
        success-light: lighten($color-success, $color-tint-light),
        success-lighter: lighten($color-success, $color-tint-lighter),
        error-dark: darken($color-error, $color-tint-dark),
        error-darker: darken($color-error, $color-tint-darker),
        error-light: lighten($color-error, $color-tint-light),
        error-lighter: lighten($color-error, $color-tint-lighter),
        header-subnav: $color-header-subnav,
        page-bg: $color-background,
        //State colors
        open: $color-state-open,
        resolved: $color-state-resolved,
        transferred: $color-state-transferred,
        in_progress: $color-state-in_progress,
        planned: $color-state-planned,
        rejected: $color-state-rejected,
        deleted: $color-state-deleted,
        to_do: $color-state-to_do,
        ongoing: $color-state-ongoing,
        finished: $color-state-finished,
        merged: $color-state-merged
);
$base-colors: $colors;

//
// Borders
//
$border-width: 1px;
$border-style: solid;
$border-color: $color-grey-cloud;

//
// Border radius
//
$border-radius-large: 6px;
$border-radius: 3px;
$border-radius-small: 2px;
$border-radius-rounded: 30rem;

//
// Box shadows
//
$box-shadow: 0 0 1px rgba(map-get($colors, black), .6);
$box-shadow-high: 0 0 1px rgba(map-get($colors, black), .6), 0 5px 10px -3px rgba(map-get($colors, black), .4);
$box-shadow-higher: 0 0 1px rgba(map-get($colors, black), .6), 0 10px 25px -4px rgba(map-get($colors, black), .4);
$box-shadow-highest: 0 0 1px rgba(map-get($colors, black), .6), 0 20px 55px -8px rgba(map-get($colors, black), .4);

//
// Focus styles
//
$border-color-focus: map-get($colors, brand);
$box-shadow-focus: inset 0 0 0 2px lighten($border-color-focus, $color-tint);

//
// Z Indexes
//
$z-over-content: 100;
$z-over-control: 200;
$z-over-page: 300;
$z-over-screen: 400;
$z-over-everything: 500;

//
// Typography
//

$text-font-family-content: 'Open sans', sans-serif;
//$text-font-family-headline: 'Roboto', sans-serif;
//$font-family-headline: 'Arvo', serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$text-font-family: $text-font-family-content;
$text-font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
$text-font-family-mono: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;

$text-font-size-huge: 2.9rem;
$text-font-size-super: 2rem;
$text-font-size-xlarge: 1.5rem;
$text-font-size-large: 1.2rem;
$text-font-size-medium: 1rem;
$text-font-size-small: .9rem;
$text-font-size-xsmall: .83rem;
$text-font-size-xxsmall: .74rem;
$text-font-size-tiny: .6rem;

$text-font-weight: $font-weight-regular;
$text-font-weight-heavy: $font-weight-semi-bold;

$text-line-height: 1.55;

$text-color: map-get($colors, black);
$highlight-padding: $spacing-xsmall $spacing-xsmall $spacing-tiny;
$highlight-margin: 0 -$spacing-tiny;
$highlight-color: map-get($colors, black);
$highlight-background-color: map-get($colors, yellow);
$help-border-bottom: $border-width dashed map-get($colors, grey-dark);
$paragraph-padding: $spacing-small 0;
$paragraph-margin: 0;
$paragraph-font-style: normal;
$code-color: $text-color;
$code-background-color: map-get($colors, grey-lighter);
$code-font-family: $text-font-family-mono;
$code-font-weight: $text-font-weight;
$code-multiline-padding: $spacing-small $spacing-medium;
$code-multiline-border-radius: $border-radius;
$keyboard-color: map-get($colors, white);
$keyboard-background-color: map-get($colors, grey-dark);
$keyboard-border-width: 2px;
$keyboard-border-style: $border-style;
$keyboard-border-color: map-get($colors, grey-darker);
$keyboard-border: $keyboard-border-width $keyboard-border-style $keyboard-border-color;
$keyboard-border-radius: $border-radius;
$quotation-padding: $spacing-medium $spacing-large;
$quotation-margin: 0;
$quotation-color: map-get($colors, quiet);
$quotation-font-size: $text-font-size-large;
$quotation-font-family: $text-font-family-serif;
$quotation-border-width: 5px;
$quotation-border-style: $border-style;
$quotation-border-color: map-get($colors, default);
$quotation-brand-border-color: map-get($colors, brand);
$quotation-info-border-color: map-get($colors, info);
$quotation-warning-border-color: map-get($colors, warning);
$quotation-success-border-color: map-get($colors, success);
$quotation-error-border-color: map-get($colors, error);
$quotation-footer-color: map-get($colors, quiet);
$quotation-footer-font-style: italic;

//
// Nav
//
$site-nav-border-color: #ded4cc;
$site-nav-border: 1px solid $site-nav-border-color;

//
// Addresses
//
$address-font-style: normal;
$address-heading-font-weight: $text-font-weight-heavy;

//
// Alerts
//
$alerts-width: 250px;
$alerts-background-color: transparent;
$alerts-margin: $spacing-medium;
$alerts-z-index: $z-over-page;
$alert-margin: 0 0 $spacing-medium 0;
$alert-padding: $spacing-medium $spacing-super $spacing-medium $spacing-medium;
$alert-background-color: map-get($colors, default);
$alert-brand-background-color: map-get($colors, brand);
$alert-info-background-color: map-get($colors, info);
$alert-warning-background-color: map-get($colors, warning);
$alert-error-background-color: map-get($colors, error);
$alert-success-background-color: map-get($colors, success);
$alert-color: map-get($colors, white);
$alert-border-radius: $border-radius;

//
// Avatar
//
$avatar-margin: 0;
$avatar-background-color:  map-get($colors, grey-darker);
$avatar-color: $color-white;
$avatar-img-border-radius: $border-radius-rounded;
$avatar-inner-img-size: 50%;
$avatar-xsmall-size: 1em;
$avatar-small-size: 2em;
$avatar-medium-size: 3em;
$avatar-large-size: 4em;
$avatar-xlarge-size: 5em;
$avatar-super-size: 6em;

//
// Badges
//
$badge-padding: $spacing-xsmall $spacing-small;
$badge-margin: 0;
$badge-background-color: map-get($colors, default);
$badge-color: map-get($colors, white);
$badge-line-height: 1.2;
$badge-brand-background-color: map-get($colors, brand);
$badge-brand-color: map-get($colors, white);
$badge-info-background-color: map-get($colors, info);
$badge-info-color: map-get($colors, white);
$badge-warning-background-color: map-get($colors, warning);
$badge-warning-color: map-get($colors, white);
$badge-success-background-color: map-get($colors, success);
$badge-success-color: map-get($colors, white);
$badge-error-background-color: map-get($colors, error);
$badge-error-color: map-get($colors, white);
$badge-font-size: .8rem;
$badge-font-weight: $text-font-weight;
$badge-border-width: 1px;
$badge-border-style: $border-style;
$badge-border-color: transparent;
$badge-border: $badge-border-width $badge-border-style $badge-border-color;
$badge-border-radius: $border-radius;
$badge-border-radius-rounded: $border-radius-rounded;
$badge-ghost-background-color: transparent;
$badge-ghost-brand-background-color: transparent;
$badge-ghost-info-background-color: transparent;
$badge-ghost-warning-background-color: transparent;
$badge-ghost-success-background-color: transparent;
$badge-ghost-error-background-color: transparent;
$badge-ghost-color: map-get($colors, default);
$badge-ghost-brand-color: map-get($colors, brand);
$badge-ghost-info-color: map-get($colors, info);
$badge-ghost-warning-color: map-get($colors, warning);
$badge-ghost-success-color: map-get($colors, success);
$badge-ghost-error-color: map-get($colors, error);

//
// Breadcrumbs
//
$breadcrumb-margin: 0;
$breadcrumb-padding: 0;
$breadcrumb-crumb-padding: 0;
$breadcrumb-crumb-separator-content: "/";
$breadcrumb-crumb-separator-font-size: $text-font-size-small;
$breadcrumb-crumb-separator-padding: 0 $spacing-small;
$breadcrumb-crumb-separator-color: map-get($colors, default);

//
// Bubbles
//
$bubble-padding: $spacing-medium;
$bubble-background-color: map-get($colors, black);
$bubble-color: map-get($colors, white);
$bubble-text-align: center;
$bubble-border-radius: $border-radius;
$bubble-arrow-width: 10px;
//
// Buttons
//
$button-padding: $relative-spacing-small;
$button-margin: 0;
$button-color: map-get($colors, white);
$button-background-color: map-get($colors, default);
$button-brand-color: map-get($colors, white);
$button-brand-background-color: map-get($colors, brand);
$button-info-color: map-get($colors, white);
$button-info-background-color: map-get($colors, info);
$button-warning-color: map-get($colors, white);
$button-warning-background-color: map-get($colors, warning);
$button-success-color: map-get($colors, white);
$button-success-background-color: map-get($colors, success);
$button-error-color: map-get($colors, white);
$button-error-background-color: map-get($colors, error);
$button-disabled-opacity: .5;
$button-font-family: inherit;
$button-font-size: 1rem;
$button-line-height: normal;
$button-text-align: center;
$button-white-space: nowrap;
$button-border-width: $border-width;
$button-border-style: $border-style;
$button-border-color: transparent;
$button-border: $button-border-width $button-border-style $button-border-color;
$button-border-radius: $border-radius * 2;
$button-focus-border-color: $border-color-focus;
$button-focus-box-shadow: $box-shadow-focus;
$button-ghost-color: $border-color;
$button-ghost-hover-color: map-get($colors, white);
$button-ghost-brand-color: map-get($colors, brand);
$button-ghost-brand-hover-color: map-get($colors, white);
$button-ghost-info-color: map-get($colors, info);
$button-ghost-info-hover-color: map-get($colors, white);
$button-ghost-warning-color: map-get($colors, warning);
$button-ghost-warning-hover-color: map-get($colors, white);
$button-ghost-success-color: map-get($colors, success);
$button-ghost-success-hover-color: map-get($colors, white);
$button-ghost-error-color: map-get($colors, error);
$button-ghost-error-hover-color: map-get($colors, white);
$button-ghost-border-width: $button-border-width;
$button-ghost-border-style: $button-border-style;

$button-ghost-action-color: map_get($colors,black);
$button-ghost-action-hover-color: map_get($colors,white);

$button-rounded-border-radius: $border-radius-rounded;
$button-close-font-weight: $text-font-weight-heavy;
$button-close-font-size: 3rem;
$button-icon-left-padding: $relative-spacing-small;
$button-icon-right-padding: $relative-spacing-small;
$button-super-font-size: $text-font-size-super;
$button-xlarge-font-size: $text-font-size-xlarge;
$button-large-font-size: $text-font-size-large;
$button-medium-font-size: $text-font-size-medium;
$button-small-font-size: $text-font-size-small;
$button-xsmall-font-size: $text-font-size-xsmall;

//
// Calendars
//
$calendar-padding: $spacing-xsmall;
$calendar-max-width: 400px;
$calendar-background-color: map-get($colors, white);
$calendar-color: map-get($colors, black);
$calendar-text-align: center;
$calendar-border-width: $border-width;
$calendar-border-style: $border-style;
$calendar-border-color: $border-color;
$calendar-border: $calendar-border-width $calendar-border-style $calendar-border-color;
$calendar-border-radius: $border-radius;
$calendar-control-padding: $spacing-medium $spacing-small;
$calendar-control-background-color: map-get($colors, white);
$calendar-control-color: map-get($colors, default);
$calendar-control-date-in-month-color: map-get($colors, black);
$calendar-control-font-size: $text-font-size-medium;
$calendar-control-border-width: $border-width;
$calendar-control-border-style: $border-style;
$calendar-control-border-color: $border-color;
$calendar-control-border: $calendar-control-border-width $calendar-control-border-style $calendar-control-border-color;
$calendar-control-border-hover: $calendar-control-border;
$calendar-control-border-radius: $border-radius;
$calendar-control-selected-background-color: map-get($colors, brand);
$calendar-control-selected-color: map-get($colors, white);
$calendar-control-selected-border-color: map-get($colors, brand);
$calendar-header-padding: $spacing-small 0;
$calendar-day-font-weight: $text-font-weight-heavy;
$calendar-day-padding: $spacing-small 0;
$calendar-today-border-color: map-get($colors, grey-light);

//
// Cards
//
$card-margin: $spacing-small 0 0 0;
$card-width: 100%;
$card-max-height: 280px;
$card-background-color: map-get($colors, white);
$card-z-index: $z-over-content;
$card-box-shadow: $box-shadow;
$card-border-radius: $border-radius;

$card-image-padding: $spacing-medium 0 0;
$card-header-padding: $spacing-medium $spacing-medium 0;
$card-body-padding: $spacing-medium;
$card-footer-padding: $spacing-medium;
$card-footer-block-padding: 0;

$card-item-padding: $spacing-small;
$card-item-border-width: $border-width;
$card-item-border-style: $border-style;
$card-item-border-color: rgba(map-get($colors, grey-light), .5);
$card-item-border-bottom: $card-item-border-width $card-item-border-style $card-item-border-color;

$card-item-hover-background-color: map-get($colors, grey-lighter);
$card-item-hover-color: map-get($colors, grey-lighter);

$card-item-active-background-color: $card-item-border-color;
$card-item-active-font-weight: $text-font-weight-heavy;

$card-item-disabled-opacity: .6;

$card-item-divider-background-color: map-get($colors, grey-dark);
$card-item-divider-color: map-get($colors, white);
$card-item-divider-font-weight: $text-font-weight-heavy;

$card-item-brand-background-color: map-get($colors, brand);
$card-item-info-background-color: map-get($colors, info);
$card-item-warning-background-color: map-get($colors, warning);
$card-item-success-background-color: map-get($colors, success);
$card-item-error-background-color: map-get($colors, error);

$card-item-brand-color: map-get($colors, white);
$card-item-info-color: map-get($colors, white);
$card-item-warning-color: map-get($colors, white);
$card-item-success-color: map-get($colors, white);
$card-item-error-color: map-get($colors, white);

$card-item-brand-hover-background-color: map-get($colors, brand-light);
$card-item-info-hover-background-color: map-get($colors, info-light);
$card-item-warning-hover-background-color: map-get($colors, warning-light);
$card-item-success-hover-background-color: map-get($colors, success-light);
$card-item-error-hover-background-color: map-get($colors, error-light);

$card-item-brand-active-background-color: map-get($colors, brand-dark);
$card-item-info-active-background-color: map-get($colors, info-dark);
$card-item-warning-active-background-color: map-get($colors, warning-dark);
$card-item-success-active-background-color: map-get($colors, success-dark);
$card-item-error-active-background-color: map-get($colors, error-dark);

$card-accordion-chevron-border: 2px solid;
$card-accordion-chevron-size: .75rem;

//
// Drawers
//
$drawer-width-horizontal: 80%;
$drawer-width-vertical: 260px;
$drawer-background-color: map-get($colors, white);
$drawer-color: map-get($colors, black);
$drawer-border-radius: $border-radius;
$drawer-z-index: $z-over-everything;
$drawer-heading-padding: $spacing-medium;
$drawer-body-padding: $spacing-small;
$drawer-footer-padding: $spacing-small;
$drawer-footer-block-padding: 0;
$drawer-footer-block-button-border-radius: 0;

//
// Headings
//
$heading-margin: 0;
$heading-padding: $spacing-medium 0 $spacing-small;
$heading-font-weight: $text-font-weight;
$heading-subheading-padding: 0;
$heading-subheading-opacity: .6;
$heading-subheading-font-size: $text-font-size-medium * .8;

//
// Hints
//
$hint-padding: 0 $spacing-small;
$hint-color: map-get($colors, grey-darker);
$hint-font-size: $text-font-size-medium;
$hint-success-color: map-get($colors, success);
$hint-error-color: map-get($colors, error);

//
// Inputs
//
$label-padding: $spacing-medium 0;
$label-field-margin: $spacing-small 0 0 0;
$field-padding: .5em;
$field-margin: 0;
$field-color: inherit;
$field-background-color: map-get($colors, white);
$field-font-size: $text-font-size-medium;
$field-font-size-super: $text-font-size-super;
$field-font-size-xlarge: $text-font-size-xlarge;
$field-font-size-large: $text-font-size-large;
$field-font-size-medium: $text-font-size-medium;
$field-font-size-small: $text-font-size-small;
$field-font-size-xsmall: $text-font-size-xsmall;
$field-font-weight: $text-font-weight;
$field-border-width: $border-width;
$field-border-style: $border-style;
$field-border-color: $border-color;
$field-border: $field-border-width $field-border-style $field-border-color;
$field-border-radius: $border-radius;
$field-focus-border-color: $border-color-focus;
$field-focus-box-shadow: $box-shadow-focus;
$field-disabled-background-color: map-get($colors, grey-lighter);
$field-disabled-border-color: $border-color;
$field-disabled-color: map-get($colors, disabled);
$field-error-border-color: map-get($colors, error);
$field-error-color: map-get($colors, error);
$field-success-border-color: map-get($colors, success);
$field-success-color: $field-color;
$checkbox-margin: $spacing-tiny;
$checkbox-font-size: $text-font-size-medium;
$checkbox-background-color: transparent;
$checkbox-border: 0;
$fieldset-padding: 0;
$fieldset-margin: $spacing-small 0;
$fieldset-width: 100%;
$fieldset-border: 0;
$legend-padding: $spacing-xsmall 0;
$form-element-padding: $spacing-medium 0;
$form-element-label-padding: 0 0 $spacing-small 0;

//
// Links
//
$link-color: map-get($colors, blue-dark);
$link-brand-color: map-get($colors, brand);
$link-info-color: map-get($colors, info);
$link-warning-color: map-get($colors, warning);
$link-success-color: map-get($colors, success);
$link-error-color: map-get($colors, error);
$link-text-decoration: none;
$link-hover-text-decoration: underline;

//
// Lists
//
$list-margin: 0;
$list-padding: 0 0 0 $spacing-medium;
$list-unstyled-padding: 0;
$list-unstyled-list-style: none;
$list-nested-padding: $list-padding;
$list-item-padding: 0;
$list-item-unstyled-list-style: none;
$list-ordered-item-padding: 0 $spacing-small 0 0;
$list-inline-padding: 0;
$list-inline-item-padding-right: $spacing-medium;
$list-inline-item-bullet-content: "\2022";
$list-inline-item-bullet-padding: 0 $spacing-small 0 0;

//
// Medias
//
$media-image-width: $avatar-medium-size;
$media-body-margin-left: $spacing-small;

//
// Modals
//
$modal-width: 80%;
$modal-background-color: map-get($colors, white);
$modal-border-width: 0;
$modal-border-style: $border-style;
$modal-border-color: $border-color;
$modal-border: $modal-border-width $modal-border-style $modal-border-color;
$modal-border-radius: $border-radius;
$modal-z-index: $z-over-everything;
$modal-header-text-align: center;
$modal-body-padding: $spacing-medium;
$modal-footer-padding: $spacing-medium;
$modal-footer-block-padding: $spacing-large 0 0 0;
$modal-footer-block-button-border-radius: 0;
$modal-ghost-background-color: transparent;
$modal-ghost-color: map-get($colors, white);
$modal-ghost-heading-color: map-get($colors, white);

//
// Navs
//
$nav-height: 3.5rem;
$nav-width: 100%;
$nav-padding: 0;
$nav-margin: 0;
$nav-background-color: map-get($colors, white);
$nav-color: map-get($colors, default);
$nav-z-index: $z-over-page;
$nav-light-background-color: map-get($colors, header-subnav);
$nav-light-color: map-get($colors, grey-darker);
$nav-light-border-color: $color-header-subnav-border;

$nav-item-padding: 1em;
$nav-item-letter-spacing: 1px;
$nav-item-hover-background-color: map-get($colors, grey-darker);
$nav-item-hover-color: map-get($colors, white);
$nav-item-brand-hover-background-color: map-get($colors, brand);
$nav-item-brand-hover-color: map-get($colors, white);
$nav-item-info-hover-background-color: map-get($colors, info);
$nav-item-info-hover-color: map-get($colors, white);
$nav-item-warning-hover-background-color: map-get($colors, warning);
$nav-item-warning-hover-color: map-get($colors, white);
$nav-item-success-hover-background-color: map-get($colors, success);
$nav-item-success-hover-color: map-get($colors, white);
$nav-item-error-hover-background-color: map-get($colors, error);
$nav-item-error-hover-color: map-get($colors, white);
$nav-item-active-background-color: map-get($colors, grey-darker);
$nav-item-active-color: map-get($colors, white);
$nav-item-brand-active-background-color: map-get($colors, brand);
$nav-item-brand-active-color: map-get($colors, white);
$nav-item-info-active-background-color: map-get($colors, info);
$nav-item-info-active-color: map-get($colors, white);
$nav-item-warning-active-background-color: map-get($colors, warning);
$nav-item-warning-active-color: map-get($colors, white);
$nav-item-success-active-background-color: map-get($colors, success);
$nav-item-success-active-color: map-get($colors, white);
$nav-item-error-active-background-color: map-get($colors, error);
$nav-item-error-active-color: map-get($colors, white);

//
// Overlays
//
$overlay-background-color: #000;
//$overlay-background-color: transparentize(black,10%);
$overlay-z-index: $z-over-screen;
$overlay-transparent-background-color: transparent;

//
// Pagination
//
$pagination-padding: $spacing-medium;
$pagination-width: 100%;
$pagination-font-size: $text-font-size-small;
$pagination-control-color: map-get($colors, brand);
$pagination-control-border-radius: $border-radius-rounded;
$pagination-page-color: map-get($colors, brand);
$pagination-page-current-background-color: map-get($colors, brand);
$pagination-page-current-color: map-get($colors, white);
$pagination-ellipsis-padding: 0 $spacing-medium;

//
// Panels
//

$panel-nav-top: ($nav-item-padding * 2) + ($text-line-height * 1em);
$panel-nav-bottom: $panel-nav-top;

//
// Progress
//
$progress-background-color: map-get($colors, grey-lighter);
$progress-color: $color-white;
$progress-border: 0;
$progress-border-radius: $border-radius;
$progress-rounded-border-radius: $border-radius-rounded;
$progress-bar-background-color: $color-default;
$progress-bar-text-align: center;

//
// Ranges
//
$range-width: 100%;
$range-height: 10px;
$range-padding: $spacing-small 0;
$range-background-color: map-get($colors, default);
$range-brand-background-color: map-get($colors, brand);
$range-info-background-color: map-get($colors, info);
$range-warning-background-color: map-get($colors, warning);
$range-success-background-color: map-get($colors, success);
$range-error-background-color: map-get($colors, error);
$range-border-width: $border-width;
$range-border-style: $border-style;
$range-border-color: transparent;
$range-border: 0;
$range-border-radius: $border-radius-rounded;
$range-box-shadow: none;
$range-disabled-background-color: map-get($colors, grey-lighter);
$range-thumb-height: 20px;
$range-thumb-width: 20px;
$range-thumb-margin: -5px 0 0 0;
$range-thumb-background-color: map-get($colors, white);
$range-thumb-border-width: $border-width;
$range-thumb-border-style: $border-style;
$range-thumb-border-color: transparent;
$range-thumb-border: 0;
$range-thumb-border-radius: $border-radius-rounded;
$range-thumb-box-shadow: 0 1px 4px -1px map-get($colors, black);
$range-thumb-focus-border-color: $border-color-focus;
$range-thumb-focus-box-shadow: $box-shadow-focus;

//
// Tables
//
$table-width: 100%;
$table-margin: 0;
$table-padding: 0;
$table-border-width: $border-width;
$table-border-style: $border-style;
$table-border-color: $border-color;
$table-border: 0;
$table-caption-padding: $spacing-small 0;
$table-caption-color: map-get($colors, quiet);
$table-caption-max-width: 100%;
$table-caption-font-size: $text-font-size-small;
$table-caption-text-align: left;
$table-cell-padding: $spacing-small;
$table-cell-text-align: left;
$table-heading-background-color: map-get($colors, grey-light);
$table-heading-color: initial;
$table-heading-border-width: $border-width;
$table-heading-border-style: $border-style;
$table-heading-border-color: map-get($colors, grey);
$table-heading-border: $table-heading-border-width $table-heading-border-style $table-heading-border-color;
$table-heading-font-size: $text-font-size-medium * 1.05;
$table-heading-font-weight: $text-font-weight-heavy;
$table-heading-striped-color: initial;
$table-heading-striped-background-color: map-get($colors, white);
$table-row-striped-color: initial;
$table-row-striped-background-color: map-get($colors, grey-lighter);
$table-row-clickable-color: $link-color;
$table-row-clickable-background-color: initial;
$table-row-disabled-color: map-get($colors, disabled);
$table-row-disabled-background-color: initial;
$table-condensed-font-size: $text-font-size-small;
$table-condensed-cell-padding: $spacing-xsmall;

//
// Tabs
//
$tab-headings-text-align: center;
$tab-heading-padding: $spacing-medium;
$tab-heading-margin: 0;
$tab-heading-box-shadow-color: map-get($colors, grey-lighter);
$tab-heading-active-box-shadow-color: map-get($colors, default);
$tab-heading-brand-active-box-shadow-color: map-get($colors, brand);
$tab-heading-info-active-box-shadow-color: map-get($colors, info);
$tab-heading-warning-active-box-shadow-color: map-get($colors, warning);
$tab-heading-success-active-box-shadow-color: map-get($colors, success);
$tab-heading-error-active-box-shadow-color: map-get($colors, error);
$tab-heading-disabled-color: map-get($colors, disabled);
$tab-heading-disabled-background-color: initial;
$tab-padding: $spacing-medium;

//
// Tags
//
$tags-width: 100%;
$tags-text-align: left;
$tag-padding: $spacing-small $spacing-large $spacing-small $spacing-small;
$tag-margin: $spacing-tiny;
$tag-close-color: map-get($colors, grey-lighter);
$tag-close-font-weight: $text-font-weight-heavy;
$tags-container-width: 70%;
$tags-field-container-margin: $spacing-tiny;
$tags-field-container-width: 30%;

//
// Toggles
//
$toggle-margin: 0 .5rem;
$toggle-track-background-color: map-get($colors, default);
$toggle-track-brand-background-color: map-get($colors, brand);
$toggle-track-info-background-color: map-get($colors, info);
$toggle-track-warning-background-color: map-get($colors, warning);
$toggle-track-success-background-color: map-get($colors, success);
$toggle-track-error-background-color: map-get($colors, error);
$toggle-track-border-radius: $border-radius-rounded;
$toggle-track-unchecked-background-color: map-get($colors, grey-lighter);
$toggle-track-disabled-background-color: map-get($colors, grey-lighter);
$toggle-handle-background-color: map-get($colors, white);
$toggle-handle-border-radius: $border-radius-rounded;
$toggle-handle-box-shadow: 0 1px 4px -1px map-get($colors, black);
$toggle-handle-disabled-background-color: map-get($colors, grey-lighter);

//
// Tooltips
//
$tooltip-z-index: $z-over-page;
$tooltip-arrow-width: .6rem;
$tooltip-body-padding: $spacing-small $spacing-medium;
$tooltip-body-background-color: map-get($colors, black);
$tooltip-body-color: map-get($colors, white);
$tooltip-line-height: 1.45;
$tooltip-body-border-width: 1px;
$tooltip-body-border-style: solid;
$tooltip-body-border-color: $tooltip-body-background-color;
$tooltip-body-border-radius: $border-radius;

//
// Trees
//
$tree-padding: 0;
$tree-margin: 0;
$tree-nested-padding: 0 0 0 $spacing-medium;
$tree-item-padding: 0;
$tree-item-indicator-padding: 0 $spacing-small 0 0;
$tree-item-indicator-color: map-get($colors, grey-light);
$tree-item-expandable-indicator-color: map-get($colors, grey);
$tree-item-expanded-indicator-color: map-get($colors, grey-darker);


//
// Vertical Align
//
$vertical-directions: "top" "bottom" "middle" "text-top";