.bs-input-wa-date input,
.bs-input-wa-hour input {
  border-radius: 0px !important;
  height: auto !important;
  border: none !important;
  background: none !important;
  font-weight: normal !important;
  margin: 0 !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #2c3e50 !important;
  font-weight: 500 !important;
}

.DayPicker-Day{
  line-height: 1 !important;
}