.u-border {
  border: 1px solid $border-color;
}

$border-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
  '-horizontal': '-left' '-right',
  '-vertical': '-top' '-bottom',
) !default;

$border-sizes: (
  null: 1px,
  '-medium': 1px,
  '-large': 2px,
  '-none': 0
) !default;

.u-border-radius {
  border-radius: $border-radius;
}


@each $direction-namespace, $direction-rules in $border-directions {
  @each $size-namespace, $size in $border-sizes {
    .u-border#{$direction-namespace}#{$size-namespace} {
      @each $direction in $direction-rules {
        border#{$direction}: $size solid $border-color !important;
      }
      @each $spacing-namespace, $spacing-breakpoint in $spacing-breakpoints{
        &--#{$spacing-namespace}{
          @include mq($from: $spacing-namespace) {
            @each $direction in $direction-rules {
              border#{$direction}: $size solid $border-color !important;
            }
          }
        }
      }
    }
  }
}