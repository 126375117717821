.c-issue-list{
  &__header{
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  &__content{
    //height:$size-large;
    overflow-y: auto;
  }
  &__date{
    text-align: center;
    padding: $spacing-tiny 0;
  }
  &__no-result{
    text-align: center;
    &>*{
      display: block;
    }
  }
}
.c-issue-list.is-loading,
.c-issue-list.is-empty{
  .c-issue-list{
    &__content{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}