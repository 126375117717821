.c-issues-filter-head{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $spacing-tiny 0;
  border-bottom: 1px solid $color-default;
  &__title{
    flex:1;
  }
  &__content{
    &>*{
      padding-left:$spacing-tiny;
    }
  }
}


.c-calendar-assign__label .c-issues-filter-head__caret{
  color: #5e676c;
}