.c-activity-logs{
    overflow-y: scroll;
    padding: $spacing-small;
    height: 100%;
}


.c-activity-log{
    &__card{
        //border-bottom: $border-width $border-style $border-color;
        //background: darken($color-white,5%);
        padding: $spacing-small;
    }
}