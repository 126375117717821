.c-hashtag {
    margin-right: $spacing-xtiny;
    display: inline-block;
    //background-color: $color-grey;
    //color: $color-white;
    font-size: $text-font-size-small;
    &__action {
        display: none;
    }
    padding: $spacing-tiny 0;
    &:hover, &--force-edit{
        .c-hashtag__action {
            display: inline-block;
        }
        border-radius: $border-radius;
        border: 1px solid $border-color;
        padding: 0 $spacing-tiny;
        margin-top: $spacing-xtiny;
    }
    &__big{
        font-size: $text-font-size-medium;
        padding: $spacing-medium $spacing-small;
    }
}

.c-issue .c-hashtag {
    padding: 0;
    cursor: inherit;
    color: $link-color;
    &:hover{
        border: none;
        margin-top: 0;
    }
}