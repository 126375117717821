.c-issue-location{
  position: relative;
  &__address{
    position: absolute;
    top: 0;
    text-align: right;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    font-size: $text-font-size-medium;
    padding-right: $spacing-tiny;
    @media print{
      background: white !important;
    }
  }
}