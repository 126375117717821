.c-header{
  background: white;

  &__wrapper{
    z-index: $z-over-page;
    position: relative;
    //@extend .o-wrapper;
    @include mq($from: tablet) {
      display: flex;
    }
    @include mq($until: tablet) {
      padding-right: $inuit-global-spacing-unit-small;
      padding-left:  $inuit-global-spacing-unit-small;
    }
  }
  &__nav{
    display: none;
    z-index: $z-over-content;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: white;
    @include mq($until: tablet) {
      position: fixed;
    }
    @include mq($from: tablet) {
      float: right;
      flex:1;
      display: flex;
      justify-content: space-between;
    }
  }
  &.active{
    .c-header__nav{
      display: block;
    }
  }
  &__brand{

    @include mq($from: tablet) {
      float: left;
      width: $size-large;
      border-right:1px solid $color-default;
    }
  }
}




.c-site-nav{
  margin:0;
  padding-top: $inuit-global-spacing-unit-large;
  position: relative;
  &--left{
    order:1;
  }
  &--right{
    order:2;
  }
  @include mq($from: tablet) {
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
  }
  &__item{
    border-bottom: $site-nav-border;
    @include mq($from: tablet) {
      float:left;
      border-bottom: 0;
    }
    position:relative;
    background-color: white;
    &:hover{
      background-color: transparentize($color-brand,.95);
    }
    &:hover:after{
      content:'';
      position: absolute;
      height:3px;
      left:0;
      bottom: 0;
      width: 100%;
      border-bottom: 3px solid $color-brand;
    }
  }
  &__label{
    display: none;
    @include mq($from: desktop) {
      display: block;
    }
  }
  &__icon{
    color:$color-brand;
    @include mq($until: desktop) {
      padding: 0 !important;
    }
  }

  &__toggle,
  &__close{
    position: absolute;
    right:$inuit-global-spacing-unit-small;
    top:1rem;
    color: #222222;
    @include mq($from: tablet) {
      display: none;
    }
  }
}

.c-site-nav-link{
  height:3rem;
  color: #999;
  font-size: $text-font-size-small;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .5s;
  padding: $spacing-tiny $spacing-medium;
  display:flex;
  align-items: center;
  @include mq($from: tablet) {
    padding: $inuit-global-spacing-unit-small $spacing-medium ;
  }

  &--icon-only,
  &--icon{
    span{
      @include mq($until: desktop) {
        display: none;
      }
      //display: inline-block;
      //margin-right: $inuit-global-spacing-unit-small;
    }
  }
  &--icon{
    i{margin-left: $spacing-tiny}
  }
  &--icon-only{
    span{
      @include mq($from: tablet) {
        display: none;
      }
    }
  }
  &--active{
      background-color: transparentize($color-brand,.95);
    &:after{
      content:'';
      position: absolute;
      height:3px;
      left:0;
      bottom: 0;
      width: 100%;
      border-bottom: 3px solid $color-brand;
    }
  }
}

.c-site-nav-dropdown{
  position: relative;

  &__container{

    @include mq($from: tablet) {
      z-index: $z-over-content - 1;
      background: white;
      width: 200%;
      border: $site-nav-border;
      border-top: none;
      box-shadow: $box-shadow-higher;
    }
  }
  @include mq($until: tablet) {
    .c-icon{
      display: none;
    }
  }
  &__item{
    font-size: $text-font-size-small;
    display: block;
    vertical-align: middle;
    padding : $spacing-small;
    color:$color-default;
    text-decoration: none;
    @include mq($from: tablet) {
      border-top: $site-nav-border;
    }
    &:last-child{
      border-bottom: none;
    }
    &:hover{
      background:darken(#fff,5%)
    }
  }
  &:hover{
    .c-site-nav-dropdown__container{
      display: block;
    }
  }
  &--user{
    .c-site-nav-dropdown__container{
      width: 150%;
      left:inherit;
      right:0;
      text-align: right;
    }
  }
  &--help{
    .c-site-nav-dropdown__container{
      width: 300%;
      left:inherit;
      right:0;

    }
  }

  &__notification{
    width: 450px;
    //max-height: 800px;
    //overflow-y: scroll;
    //padding-left: 12px;
  }
}