.c-stats-card {
    background-color: $color-white;
    text-align: center;
    padding: $spacing-small 0;
    .c-stats-card__header {
        margin: $spacing-tiny $spacing-medium;
        border-bottom: 1px solid $color-grey-silver;
        font-size: $text-font-size-large;
        padding-bottom: $spacing-tiny;
    }
    .c-stats-card__content {
        padding: $spacing-medium 0;
        font-size: $text-font-size-huge;
        font-weight: bold;
    }
}
/*
.c-stats {
    & .u-7of12, .u-5of12, .u-6of12 {
        text-align: left;
        width: 100% !important;
        padding: 20px 120px !important;
        & .svg-container {
            width: 300px !important;
        }
    }
}*/