.c-issue-description{
  color:black;
  font-size: $text-font-size-medium;
  font-weight: $font-weight-medium;
  min-height: 1.2rem;
  /* BOTH of the following are required for text-overflow */
  white-space: nowrap;
  overflow: hidden;
  //
  text-overflow: ellipsis;

  &--short{
    font-size: $text-font-size-small;
    p{
      margin: 0;
    }
  }
  &--large{
    font-size: $text-font-size-small;
    max-height:2.5rem;
    white-space: normal;
    p{
      margin: 0;
    }
  }
  &--full{
    overflow: auto;
    white-space: normal;
    &>*{
      display: block;
    }
  }
  &--wrap{
    white-space: normal;
  }
}
.c-issue-description>div:nth-child(2){
  display: flex;
  flex-direction: row;
  font-weight: bold;
}
.c-issue-description>div:nth-child(2)>div:nth-child(2){
  flex-grow: 1;
}