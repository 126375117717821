.c-issues-navbar{
  text-align: center;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  grid-column-gap: $spacing-medium;
  grid-row-gap: $spacing-medium;
  &__search{
    grid-column: 2;
    grid-row: 1;
  }
  &__nav{
    grid-column: 1;
    grid-row: 1;
  }
}