.c-issues-alerts {
  position: absolute;
  top: 12px;
  right: 50px;
  z-index: 1000;
  &__alert{
    padding: $spacing-small;
    margin-bottom: $spacing-tiny;
    background: rgba(243, 227, 86, 0.9);
    cursor: pointer;
    & h6 {
      margin: $spacing-xtiny 0;
    }
    & a {
      font-weight: bold;
      color: $text-color;
    }
  }
}