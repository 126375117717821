.c-issue-log{
  &__content{
    border-left:1px solid $color-default;
    padding-left: $spacing-small;
    margin: $spacing-small 0;
  }
  &__comment{
    background : $color-muted;
    padding: $spacing-small;
  }
  &__header{
    &>a{
      display: block;
      &>*{
        display: inline-block;
      }
    }
  }
  &--comment{
    .c-issue-log__content{
      background: white;
      padding: $spacing-small;
      border:none;
    }
  }
}