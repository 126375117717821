.c-issue-state{
  &--open {
    .c-issue-state__rectangle{
    fill: $color-state-open;
    }
  }
  &--resolved {
    .c-issue-state__rectangle{
    fill: $color-state-resolved;
    }
  }
  &--transferred {
    .c-issue-state__rectangle{
    fill: $color-state-transferred;
    }
  }
  &--in_progress {
    .c-issue-state__rectangle{
    fill: $color-state-in_progress;
    }
  }
  &--planned {
    .c-issue-state__rectangle{
    fill: $color-state-planned;
    }
  }
  &--rejected {
    .c-issue-state__rectangle{
    fill: $color-state-rejected;
    }
  }
  &--deleted {
    .c-issue-state__rectangle{
    fill: $color-state-deleted;
    }
  }
  &--ongoing {
    .c-issue-state__rectangle{
    fill: $color-state-ongoing;
    }
  }
  &--to_do {
    .c-issue-state__rectangle{
    fill: $color-state-to_do;
    }
  }
  &--finished {
    .c-issue-state__rectangle{
    fill: $color-state-finished;
    }
  }
  &--merged {
    .c-issue-state__rectangle{
    fill: $color-state-merged;
    }
  }
}

.c-issue-starter-state-form textarea {
  min-height: 120px;
}

.c-issue-starter-state-form input {
  background-color: $color-white;
}