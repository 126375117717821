.c-confirmation-container{
  position: relative;
  transition: all .3s;
  display: inline-flex;
  align-items: center;
  .c-confirmation-container__component{
    //padding-right:$spacing-medium;
    flex:1;
  }
  .c-confirmation-container__confirmation{
    position: absolute;
    top:50%;
    left:- $spacing-medium;
    transform: translate(0,50%);
  }
}
.c-confirmation{
  font-size: $text-font-size-small;
  flex-shrink: 0;
  padding: $spacing-tiny;
}