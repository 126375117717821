.c-select-editable {
  cursor: pointer;

  &--disabled {
    cursor: default;
  }
}

.select-editable{
  font-weight: $font-weight-medium;
}