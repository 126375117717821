/*Animate.css*/
.animated.pulse {
    z-index: 100 !important;
}

/*Calendar*/
.anim-issue-draggable {
    transition: all .3s ease-out !important;
}

.anim-issue-dragged {
  transform: scale(1.1);
  z-index: 100 !important;
}

.anim-calendar-item,  .calendar .anim-calendar-item .details {
    transition: all .3s ease-out !important;
}

#calendar .issue-filter-block.calendar.anim-calendar-item-drop-over .details {
    box-shadow: inset 0px 3px 0px 0px #66b1c5;
}

.anim-calendar-item-drop-over {
    box-shadow: inset 0 0 10px #66b1c5;
}