.c-issue-title{
  font-size: $text-font-size-small;
  text-transform: uppercase;
  font-family: $text-font-family-content;
  font-weight: $font-weight-regular;
  margin:0;
  display: inline-flex;
  align-items: center;
  &__tag{
    overflow: hidden;
  }
  &>*{
    display: inline-block;
    margin-right: $spacing-tiny;
  }
  &--short{
    font-size: $text-font-size-small;
  }
  &__deadline{
    position: absolute;
    right:0;
    top:$spacing-tiny;
  }
  &__id{
    font-size: $text-font-size-medium;
  }
}